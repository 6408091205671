import actions from './actions'

const staffInfo = {
  state: {
    statusCreate: false,
    staffInfo: {
      id: null,
      name: '',
      email: '',
      profileImgUrl: '',
      roleId: null,
      facilityId: null,
      crmRep: true,
      facilityName: '',
      roleName: '',
      disabled: false
    }

  },

  getters: {
    getStatusCreateStaff (state) {
      return state.statusCreate
    }
  },

  mutations: {
    setStaffInfo (state, payload) {
      state.staffInfo = payload.staffInfo
    },

    statusCreate (state, payload) {
      state.statusCreate = !state.statusCreate
    }
  },
  actions
}

export default staffInfo
