export default {
  messages: {
    successfulNewCreation: 'Successful new creation',
    newCreationFailed: 'New creation failed',
    successfulUpdate: 'Successful update',
    deletedSuccessfully: 'Deleted successfully',
    duplicateDates: 'Duplicate dates !!'
  },
  auth: {
    login: 'Login',
    username: 'Username',
    password: 'Password',
    email: 'Email',
    confirmation: 'Confirmation',
    code: 'Code',
    forgotPassword: 'Forgot password?',
    confirmationResetPassword: 'Confirmation of resetting password',
    resettingPassword: 'Resetting password'
  },
  sidebar: {
    calendar: 'Calendar',
    booking: 'Booking',
    feesPoints: 'Fees / Points / Plans',
    invitationUseTickets: 'Invitation / Use Tickets',
    externalCooperation: 'External Cooperation',
    report: 'Report',
    facility: 'Facility',
    printingForm: 'Printing Form',
    membersPage: 'Members Page',
    users: 'Users',
    logout: 'Logout',
    reservation: 'Reservation',
    guestRoomDailyReport: 'Guest Room Daily Report',
    frameSetting: 'Frame setting',
    formReport: 'Form report',
    webPageManagement: 'Web Page Management'
  },
  routeTitles: {
    dashboard: 'Dashboard',
    calendar: {
      calendar: 'Calendar',
      booking: 'Booking',
      bookingCalendar: 'Booking Calendar',
      inOutManagement: 'In-Out Management',
      roomAssignment: 'Room Assignment',
      roomAllocationCalendar: 'Room Allocation Calendar',
      keys: 'Keys',
      keyCalendar: 'Key calendar',
      carPark: 'Car Park',
      carParkCalendar: 'Car Park Calendar',
      exhibitsForHire: 'Exhibits For Hire',
      rentalCalendar: 'Rental Calendar',
      frameSettings: 'Frame Settings'
    },
    booking: {
      booking: 'Booking',
      bookingList: 'Booking List',
      cancellationWait: 'Cancellation Wait',
      rci: 'RCI',
      guests: 'Guests'
    },
    feesPoints: {
      feesPoints: 'Fees / Points / Plans',
      plans: 'Plans',
      feesAndPoints: 'Fees and points',
      CSVUpdate: 'CSV Update'
    },
    invitationUseTickets: {
      invitationUseTickets: 'Invitation / Use Tickets',
      list: 'List'
    },
    externalCooperation: {
      externalCooperation: 'External cooperation',
      roomTypes: 'Room Types',
      overallSettings: 'Overall Settings'
    },
    formReport: {
      formReport: 'Form report',
      forIncomeReport: 'For income report',
      salesManagementTable: 'Sales management table',
      otherCSV: 'Other CSV',
      monthlyLodgingTaxSchedule: 'Monthly lodging tax schedule'
    },
    facility: {
      facility: 'Facility',
      facilitySettings: 'Facility settings',
      rentalList: 'Rental list',
      productsForSale: 'Products for sale',
      bookingRemarkByContract: 'Booking remarks by facility contract'
    },
    printingForm: {
      printingForm: 'Printing form',
      form: 'Form',
      taxSchedule: 'Accommodation Tax Monthly Schedule'
    },
    membersPage: {
      membersPage: 'Members Page',
      informationRequest: 'Information Request',
      bookVisit: 'Book a Visit',
      onlineConsultation: 'Online Consultation',
      notices: 'Notices',
      experienceAccommodation: 'Experience Accommodation',
      facilitySettings: 'Facility Settings',
      webPageManagement: 'Web page management (common to CRM)'
    },
    users: {
      users: 'Users',
      information: 'Information',
      userList: 'User List',
      userRights: 'User Rights',
      usersCrmCommon: 'Users (CRM common)'
    },
    reservation: {
      reservation: 'reservation',
      reservationList: 'Reservation List',
      sendUsageCertificate: 'Send usage certificate',
      cancellationApplied: 'Cancellation applied'
    },
    guestRoomDailyReport: {
      guestRoomDailyReport: 'Holiday / specific day setting',
      guestRoomPanel: 'Guest Room Panel',
      dailyReport: 'Daily Report',
      dailyReportList: 'Daily Report List',
      holidaySetting: 'Holiday Setting'
    },
    frameSetting: {
      frameSettingCalendar: 'Frame setting calendar',
      systemOuterLimitSetting: 'Frame outer limit setting'
    },
    pageNotFound: 'Page not found',
    invitationsAndCoupons: 'Invitations and coupons'
  },
  common: {
    all: 'All',
    inDevelopment: 'In development',
    title: 'Title',
    search: 'Search',
    status: 'Status',
    contract: 'Contract',
    facility: 'facility',
    roomType: 'Room type',
    room: 'Room',
    roomNumber: 'Room Number',
    checkIn: 'Check in',
    checkOut: 'Check out',
    numOfDays: 'Number of days',
    representative: 'Representative',
    adult: 'Adult',
    children: 'Children',
    email: 'Email',
    cases: 'Cases',
    showing: 'Showing',
    noResults: 'No results',
    toDay: 'To Day',
    kindName: 'Kind name',
    fileDownload: 'File download',
    notIn: 'Not in',
    notOut: 'Not Out',
    unpaid: 'Unpaid',
    member: 'Member',
    bedSharing: 'Bed Sharing',
    large: 'Large',
    small: 'Small',
    loan: 'Loan',
    block: 'Block',
    confirmed: 'Confirmed',
    none: 'None',
    memberName: 'Member name',
    group: 'Group',
    remark: 'Remark',
    city: 'City',
    country: 'Country',
    amountOfMoney: 'Amount of money',
    numberOfSheets: 'Number of sheets',
    close: 'Close',
    save: 'Save',
    notices: 'Noties',
    dateOfOccurrence: 'Date of occurrence',
    unitPrice: 'Unit Price',
    startOfDeadline: 'Start Of Deadline',
    endOfDeadline: 'End Of Deadline',
    subjectName: 'Subject Name',
    unit: 'Unit',
    points: 'Points',
    quantity: 'Quantity',
    order: 'Order',
    VAT: 'VAT',
    salesTaxRate: '消費税率',
    subtotal: 'Subtotal',
    total: 'Total',
    night: 'Night',
    cash: 'Cash',
    settlementMethod: 'Settlement Method',
    cardType: 'Card type',
    unionPay: 'UnionPay',
    membershipRegisteredCard: 'Membership registered card',
    beforeChange: 'Before change',
    proviso: 'Proviso',
    provisoCut: 'Proviso',
    fullName: 'Full Name',
    page: 'Page',
    target: 'Target',
    totalUse: 'Total use',
    checked: 'Checked',
    remarksContentSearch: 'Remarks content search',
    numOfPersons: 'Number of persons',
    unaccompanied: 'Unaccompanied',
    accompanied: 'Accompanied',
    occupation: 'Occupation',
    age: 'Age',
    nationality: 'Nationality',
    payableDate: 'Payable date',
    reservation: 'Reservation',
    time: 'Time',
    question: 'Question',
    questionOrder: 'Question',
    questionContent: 'Question content',
    freeText: 'Free text',
    option: 'Option',
    requiredAnswer: 'Required answer',
    optionalAnswer: 'Optional answer',
    author: 'Author',
    checkInDate: 'Check-in date',
    start: 'Start',
    end: 'End',
    noSet: 'No set',
    set: 'Set',
    discount: 'Discount',
    yen: 'Yen',
    overNight: 'Over night',
    untilNight: 'Until night',
    period: 'Period',
    months: 'Months',
    item: 'Item',
    startPeriod: 'Start period',
    endPeriod: 'End period',
    date: 'Date',
    number: 'Number',
    credit: 'Credit',
    point: 'Point',
    subItem: 'Sub-item',
    day: 'Day',
    icon: 'Icon',
    image: 'Image',
    noSmoking: 'No smoking',
    smoking: 'Smoking',
    roomTypeTitle: 'Room type title',
    paymentUnit: 'Payment unit',
    totalAmount: 'Total amount',
    stay: 'Stay'
  },
  buttons: {
    retainAndAdd: 'Retain and add',
    registerLoanItems: 'Register additional items for loan',
    registerNewParkingLot: 'Register new parking lot',
    add: 'Add',
    login: 'Login',
    createNewFacility: 'Create a new facility',
    eachRoomSettings: 'Each room settings',
    createNewRoomType: 'Create a new room type',
    createNewRoom: 'Create a new room',
    edit: 'Edit',
    delete: 'Delete',
    saveAndUpdate: 'Save and update',
    saveAndClose: 'Save and close',
    cancel: 'Cancel',
    saveAndCreate: 'Save and create',
    request: 'Request',
    change: 'Change',
    back: 'Back',
    newRoomReservation: 'New room reservation',
    approval: 'Approval',
    remand: 'Remand',
    close: 'Close',
    save: 'Save',
    beforeDailyUpdate: 'Before daily update',
    afterDailyUpdate: 'After daily update',
    openCertificates: 'Open usage certificate / confirmation certificate',
    openTheFacilityFloorPlan: 'Open the facility floor plan',
    openMemberInformation: 'Open member information',
    reservationEdit: 'Reservation edit',
    statementDisplay: 'Statement display',
    changeToCancel: 'Change to cancel',
    print: 'Print',
    splitReceipt: 'Split receipt',
    issuanceRegistration: 'Issuance registration',
    memberAddressLabelPrinting: 'Member address label printing',
    printThecertificateOfUse: 'Print the certificate of use',
    returnTheSplit: 'Return the split',
    createANewPlan: 'Create a new plan',
    register: 'Register',
    detail: 'Detail',
    createNewInvitation: 'Create new invitations and user tickets',
    deleteQuestion: 'Delete question',
    addAQuestion: 'Add a question',
    deletePlan: 'Delete plan',
    done: 'Done',
    startSetting: 'Start setting',
    changeAll: 'Change all',
    apply: 'Apply',
    addFacility: 'Add a facility',
    printing: 'print',
    exportAsCSV: 'Export as CSV',
    CSVOutputDaily: 'CSV output for daily report creation',
    CSVOutputLodging: 'CSV output of lodging total',
    CSVOutputCancel: 'CSV output of cancellation total',
    CSVOutputNotice: 'CSV output of vacancy notice total',
    CSVOutputUsage: 'CSV output of loaned items usage rate',
    registerNewUser: 'Register new user',
    reissuePassword: 'Reissue password',
    next: 'Next',
    roomTypeSetting: 'Room type setting'
  },
  texts: {
    stock: 'Stock',
    numberOfWaitingList: 'Number of waiting list',
    vacancyNotification: 'Vacancy notification'
  },
  rules: {
    data: {
      code: 'Code',
      name: 'Name',
      type: 'Type',
      carHeight: 'Car height',
      loanItem: 'Loan item',
      totalQuantity: 'Total quantity',
      email: 'Email',
      password: 'Password',
      bedShareRule: 'Rules for sleeping together'
    },
    required: '{title} is required',
    maximumNCharacter: 'Must be maximum {value} character',
    minimumNCharacter: 'Must be minimum {value} character',
    minimumN: 'Must be minimum {value}',
    moreThanN: 'Must be more than {value}',
    invalid: '{title} is not valid',
    maximumNumberInt32: 'Must be less than or equal to 2147483647',
    numberInvalid: 'Number invalid',
    noData: 'No data',
    isRequired: 'Is required !!',
    isInvalid: 'Invalid !!',
    createBookingTooManyGuests: 'Create booking too many guests',
    includeNCharacters: 'Must include {value} characters',
    emailIsInvalid: 'E-mail is invalid.'
  },
  toast: {
    checkEmail: 'The sign in code was sent to your email, please check it',
    welcomePMS: 'Welcome to PMS',
    passwordChanged: 'Password was changed.'
  },
  reports: {
    dailyUpdate: 'Daily update',
    moneyTotal: 'Total fee',
    check: 'check',
    amountOfMoney: 'Amount of money',
    chooseMultiplePics: 'Upload file (multiple)',
    firstConfirmer: 'Primary confirmer',
    secondConfirmer: 'Secondary confirmer',
    kindName: 'Kind name',
    dailyReportDate: 'Daily report date',
    facility: 'Facility',
    createdDate: 'Created date',
    editApproval: 'Edit approval',
    status: 'Status',
    cashCheck: 'Cash check',
    cashRegister: 'Cash register excess / deficiency',
    adjustmentAmount: 'Adjustment amount',
    reason: 'Reason',
    dailySalesReport: 'Daily sales report by denomination of directly managed facilities',
    onTheDay: 'On the day',
    primaryConfirmer: 'Primary confirmer',
    secondaryConfirmer: 'Secondary confirmer',
    dailyCheck: 'Daily check'
  },
  reservation: {
    reservationID: 'Reservation ID',
    reservationSource: 'Reservation source',
    reservationDate: 'Reservation date',
    showSubsequentReservations: 'Show subsequent reservations',
    reservationDetailsEdit: 'Reservation details edit',
    editDetailedStatement: 'Edit detailed statement',
    memberInformation: 'Member information',
    userCertificate: 'User certificate',
    facilityInformation: 'Facility information',
    pointDocument: 'Point balance certificate document',
    leaflets: 'Leaflets',
    typeOfMailingHope: 'Type of mailing hope',
    searchAllReservations: 'Search all reservations'
  },
  contractDetail: {
    basicInformation: 'Basic inforfmation',
    billingStatement: 'Billing statement',
    billingStatementFormal: 'Billing statement',
    guestDetails: 'Guest Details',
    rentalListing: 'Rental Listing',
    parkingPlace: 'Parking place',
    operationChangeHistory: 'Operation change history',
    canceled: 'Canceled',
    fixRoomAllocation: 'Fix room allocation',
    remarkNote: 'Remarks to be displayed on the reservation bar',
    accommodationRemarks: 'Accommodation remarks',
    usageContract: 'Usage contract',
    stayingPlan: 'Staying plan',
    individualCorporationClassification: 'Individual corporation classification',
    reservationSourceSite: 'Reservation source site',
    newReservationsAdded: 'New reservations added to this reservation group',
    toothbrushReady: 'Toothbrush ready',
    requestForMailingOfUsageCertificate: 'Request for mailing of usage certificate',
    pointBalanceCertificateDocumentMailingRequest: 'Point balance certificate document mailing request',
    yukataPreparationHope: 'Yukata preparation hope',
    requestToMailFacilityInformationMaterials: 'Request to mail facility information materials',
    hopeToMailLeaflets: 'Hope to mail leaflets, etc',
    accommodationRepresentative: 'Accommodation representative',
    groupMasterBillingStatement: 'Group master billing statement',
    fullCash: 'Full cash',
    fullCard: 'Full card',
    multipleOthers: 'Multiple others',
    numberOfReturnPoints: 'Number of return points',
    subItemSelection: 'Sub-item selection',
    namesOfOtherProductsForSale: 'Names of other products for sale',
    selectCancellationOnTheDay: 'Select cancellation on the day',
    cancellationRate: 'Cancellation rate',
    firstDay: 'First day',
    settingCancellationPolicy: 'Setting cancellation policy',
    invoiceEdit: 'Invoice edit',
    registrationOptions: 'Issuance registration / registration card payment',
    consumptionTax: 'consumption tax',
    bathTax: 'Bath tax',
    accommodationTax: 'Accommodation tax',
    costOfEquipment: 'Cost of equipment',
    amountOfReceipt: 'Amount of receipt',
    noteReceipt: 'The above amount has been duly received',
    companyNameText: 'Tokyu Vacations Karuizawa',
    addressText: '1016-652 Karuizawa, Karuizawa-cho, Kitasaku-gun, Nagano 389-0102 Japan',
    addFromCalendar: 'Add from the calendar',
    selectFacility: 'Select a facility',
    night: ' night',
    day: ' day',
    nonSleepChild: 'Child (non-sleeping)',
    sleepChild: 'Child (sleeping)',
    lodgingRepresentativeYomigana: 'Accommodation representative Yomigana',
    telephoneNum: 'Telephone number',
    emailAddress: 'Email address',
    selectCountry: 'Select a country',
    postalCode: 'Postal code',
    address: 'Address',
    addParkingReservation: 'Add parking reservation',
    actualDayOfStay: 'Actual day of stay',
    selectTheDateOfStay: 'Select the date of stay',
    dateOfBathing: 'Date of bathing (Hakone only)',
    guestInfomation: 'Guest information',
    nameOrMember: 'Name/Member',
    passportOrResidenceImage: 'Passport or residence status image (foreign nationals only)',
    uploadFile: 'Upload file',
    numberOfReceiptsIssued: 'Number of receipts issued',
    remainingMoney: 'Remaining money',
    membershipNumber: 'Membership number',
    yourName: 'Full name',
    mr: 'Mr',
    subscriberMembershipInformation: 'Subscriber membership information',
    periodOfStay: 'Period of stay',
    usageFees: 'Usage fees',
    exchangeRate: 'Exchange rate',
    lodgingPoints: 'Lodging points',
    exchangeSource: 'Exchange source',
    exchangeDestination: 'Exchange destination',
    dayPlanCertificate: 'Day plan usage certificate',
    flexPointCertificate: 'Flex point usage certificate',
    primeWeekCertificate: 'Prime week confirmation certificate',
    sharingPointCertificate: 'Sharing point usage certificate',
    tokyuCertificate: 'Tokyu Vacations Exchange Confirmation Certificate'
  },
  holidaySetting: {
    year: 'Year',
    showPreviousYear: 'Holidays previous year'
  },
  roomAssignment: {
    period: 'Period'
  },
  calendar: {
    period: 'Period',
    parkingCars: {
      addParkingReservation: 'Add parking reservation',
      parkingPlace: 'Parking lot',
      carHeight: 'Vehicle height',
      parkingLot: 'Parking lot',
      carNumber: 'Number',
      carColor: 'Color',
      status: 'Status'
    },
    rental: {
      addRentalListing: 'Add rental listing',
      addRentalListingPopup: 'Rental listing added',
      rentalListing: 'Rental listing',
      unitPrice: 'Lending unit price'
    }
  },
  booking: {
    dateTime: 'Date and time',
    user: 'User',
    setting: 'Settings',
    oldValue: 'Old value',
    newValue: 'New value',
    deleteReservation: 'Delete this reservation',
    cancelDate: 'Cancellation Date',
    totalCancelAmount: 'Total Cancellation Amount',
    totalCancelPoint: 'Cancellation Total Points'
  },
  modalReservation: {
    title: 'Create a new reservation',
    btnReservation: 'Reservation',
    btnReservationWithoutID: 'Reservation Without ID',
    btnReservationBlock: 'Block creation',
    btnReservationFacility: 'Point cooperation facility',
    btnReservationList: 'No customer ID / other reservations'
  },
  reservationCalendar: {
    title: 'Please enter the frame, usage contract and number of people',
    select: 'Select',
    checkIn: 'Check In',
    facility: 'Facility',
    typeRoom: 'Room type'
  },
  feesPoints: {
    plans: {
      planID: 'Plan ID',
      enabledOrDisabled: 'Enabled / Disabled',
      published: 'Published',
      planName: 'Name of plan',
      remainingPublication: 'Remaining Publication',
      possiblePeriodStart: 'Possible Period Start',
      endOfPossiblePeriod: 'End of possible Period',
      priceSetting: 'Price setting',
      consecutiveNightDiscount: 'Consecutive night discount',
      cancelSetting: 'Cancel setting',
      planStatus: 'Enable/Disable Plan',
      planSubtitle: 'Plan subtitle',
      contractToCreateAPlan: 'Contract to create a plan',
      facilitiesAndRoomTypes: 'Facilities and room types',
      planImage: 'Plan image',
      planPrStatement: 'Plan statement',
      uploadPlanImages: 'Upload plan images (up to 2 images)',
      upToTwoImages: 'Up to 2 images !!',
      planNotes: 'Plan notes',
      planFeeOrConditionSetting: 'Plan fee / condition setting',
      priceSettingMethod: 'Price setting method',
      priceField: 'Price (1 stay per room, 1 stay per person)',
      pricePlanCSV: 'Plan price CSV(per room, per night, per person, per night)',
      paymentMethod: 'Payment method',
      settlementOfAccounts: 'Settlement of accounts',
      periodOfStay: 'Period of stay',
      memberPagePostingPeriod: 'Member page posting period',
      sellOffSetting: 'Sell-off setting',
      roomRateDiscount: 'Room rate discount',
      limitedNumOfPeople: 'Limited number of people',
      limitedNumOfNights: 'Limited number of nights',
      roomRateCancellationPolicy: 'Room rate cancellation policy',
      uploadPlanPriceCSV: 'Upload plan price CSV file (per room per night, per person per night)',
      bothAvailable: 'Both available',
      localPaymentOnly: 'Local payment only',
      registeredCardPaymentOnly: 'Registered card payment only',
      dayBefore: 'Day before',
      reservationTimeNote: 'Reservations can be made until 5:00 p.m.',
      fromTheDateOfBooking: 'From the date of booking',
      displayedUpUntilYesterday: 'Displayed up until yesterday',
      maxNumOfUsersPerDay: 'Maximum number of users per day',
      maxNumOfRoomsPerDay: 'Maximum number of rooms per day',
      roomRateDiscountNote: 'Apply discount for 9th night after 9th night',
      minNumOfUsers: 'Minimum number of users',
      maxNumOfUsers: 'Maximum number of users',
      noContact: 'No contact'
    },
    CSV: {
      CSVRegistration: 'CSV registration (charge master only)',
      uploadaCSVFile: 'Upload a CSV file',
      CSVUpdateSchedulel: 'CSV update schedule registration (charge master only)',
      CSVUpdate: 'CSV update',
      newCSV: 'New CSV update schedule registration',
      scheduledUpdateDate: 'Scheduled update date',
      registrant: 'Registrant',
      registrationDate: 'Registration date',
      cancelUpdate: 'Cancel update',
      nonProductCharges: 'Non product charges',
      nonProductChargeName: 'Non product charges name',
      CSVUpload: 'CSV upload',
      feePointCSVUpload: 'Fee point CSV upload (fee master only)',
      updateDate: 'Update date',
      CSVUploadNoFees: 'CSV upload of non-product fees'
    },
    calendar: {
      selectAll: 'Select all',
      cancelAll: 'Cancel all'
    }
  },
  invitationsAndCoupons: {
    backToInvitationList: 'Back to Invitation Ticket and Usage Ticket List',
    dateOfIssue: 'Date of Issue',
    invitationTicketNo: 'Invitation Ticket/User Ticket No.',
    ticketIssueNum: 'Number of tickets issued',
    expirationDate: 'Expiration date',
    reservationStartDate: 'Reservation start date',
    deadlineForUse: 'Deadline for use',
    reservationPeriod: 'Reservation period',
    sheetsUsedNum: 'Number of sheets used',
    available: 'Available',
    authenticationNum: 'Number for authentication',
    customerName: 'Customer Name',
    enabledOrDisable: 'Enabled / Disabled',
    applicationDate: 'Application Date',
    exportToCSV: 'Output selected items to CSV',
    copyAndCreate: 'Copy and create a new one',
    createNewInvitationTitle: 'Issuance of new invitation/use tickets',
    reservedPath: 'Reserved path',
    dayOfWeek: 'Day of the week',
    exclusionDay: 'Excluded days',
    userOrNot: 'Use or not',
    nightwear: 'Nightwear',
    toothbrush: 'Toothbrushes',
    shaving: 'Shaving',
    itemAvailableRent: 'Items available for rent',
    amountUsed: 'Amount used',
    free: 'Free',
    fixedFree: 'Fixed charge',
    taxInclude: '(Tax included)',
    cancelPolicy: 'Cancellation Policy',
    amountRightReserved: 'Amount of rights set',
    fixedAmountRightSetting: 'Fixed amount for rights setting',
    numSheetUser: 'Number of copies used',
    numSheetAvailable: 'Number of sheets available',
    lastUpdatedBy: 'Last Updated by',
    callCenter: 'Call Center',
    sales: 'Sales',
    note: '※ If the invitation ticket or usage ticket has not been used, you can delete it by clicking "Delete" below.',
    invitationNo: 'Ticket/Invitation No.',
    numSheetIssued: 'Number of sheets issued',
    ticketNo: 'Ticket No.',
    representativeName: 'Name of representative',
    ticketDetail: 'Ticket Details',
    exportTicketNumToCSV: 'Export ticket number to CSV',
    editInvitation: 'Edit invitation/use tickets',
    corporateTicketBookingCreation: 'Corporate ticket booking creation'
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    holidays: 'Holidays'
  },
  frameSetting: {
    calendar: {
      periodFrameRatio: 'Period frame ratio'
    },
    systemOuter: {
      systemOuterFrame: 'System outer frame'
    }
  },
  formReport: {
    dayPlanRoomIncome: 'Day plan room income',
    rentalRoomIncome: 'Rental room income',
    usageFeeIncome: 'Usage Fee income',
    miscellaneousIncome: 'Miscellaneous income',
    miscellaneousIncomeNotTaxable: 'Miscellaneous income (Not taxable) \'Cancellation fee\'',
    outputTax: 'Output tax',
    lodgingTax: 'Lodging tax',
    comparisonPeriodStart: 'Comparison period start',
    comparisonPeriodEnd: 'Comparison period end',
    utilizationRateComparisons: 'Utilization rate comparison',
    contractNumber: 'Contract number',
    roomNumber: 'Room number',
    numOfUsers: 'Number of users',
    salesPeriodDeposit: 'Sales period deposit',
    contrastPeriodDeposit: 'Contrast period deposit',
    accountsReceivableUncheckedOut: 'Account receivable unchecked out',
    salesAmount: 'Sales amount',
    consumptionTax: 'Consumption tax',
    taxIncludedSales: 'Tax-included sales',
    numOfEvents: 'Number of events',
    comparisonSalesAmount: 'Comparison sales amount',
    interpersonalconsumptionTax: 'Interpersonal consumption tax',
    salesIncludingTax: 'Sales including tax',
    numberOfComparisons: 'Number of comparisons'
  },
  users: {
    showDisabledUsers: 'Show disabled users',
    lastLogin: 'Last login',
    menuPermissions: 'Menu permissions',
    facilityAuthority: 'Facility authority',
    editUserInformation: 'Edit user information',
    authorizationSettings: 'Authorization settings',
    loginID: 'Login ID',
    userPermission: 'Username Permission',
    viewOnly: 'View only',
    modified: 'Modified',
    registerNewUser: 'Register new user'
  },
  webPageManage: {
    infoRequest: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      document: '資料請求内容',
      remark: '備考',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    infoRequestDetail: {
      back: '資料請求一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'アンケート',
      interest: '興味のある施設',
      learn: '東急バケーションズを知った経緯'
    },
    booking: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
      representativeUnregistered: 'Representative unregistered'
    },
    bookingDetail: {
      back: 'ご来店予約一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容'
    },
    consultation: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    consultationDetail: {
      back: 'オンライン相談一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容'
    },
    experience: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望',
      secondHope: '第二希望',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
      salesRepresentative: '営業担当者',
      numOfTimes: '回数'
    },
    experienceDetail: {
      back: '体験宿泊申込一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: '体験宿泊希望日・施設',
      firstHope: '第一希望',
      secondHope: '第二希望',
      content: 'ご相談内容'
    },
    notice: {
      search: '検索',
      add: '新規お知らせ',
      status: 'ステータス',
      date: '受信日',
      creator: '作成者',
      category: 'カテゴリー',
      title: 'タイトル',
      content: '内容',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成'
    },
    noticeDetail: {
      back: 'お知らせ一覧に戻る',
      date: '作成日：',
      title: 'タイトル',
      checkbox: '重要',
      text: '本文',
      delete: '削除',
      update: '保存',
      push: '掲載'
    },
    facility: {
      HPFacilityInformationScreen: 'HP facility information screen',
      memberPageFacilitySetting: 'Member page facility setting',
      displayFacilityName: 'Display facility name',
      facilityNameAbbreviation: 'Facility name (abbreviation)',
      latitude: 'Latitude',
      longitude: 'Longitude',
      addressAbbreviation: 'Address abbreviation',
      descriptionText: 'Explanatory text',
      remarkSubject: 'Remark subject',
      otherRemarks: 'Other remarks',
      searchScreenDescription: 'Search screen description',
      searchTags: 'Search tags',
      mainImage: 'Main image',
      remarkRoomType: 'Remarks when booking by room type',
      otherMemos: 'Other memos (member page hidden)',
      otherNotes: 'Other notes'
    },
    routeName: {
      info: 'WEBページ管理_資料請求',
      infoDetail: 'WEBページ管理_資料請求_今津康平',
      booking: 'WEBページ管理_来店予約',
      bookingDetail: 'WEBページ管理_来店予約_今津康平',
      consultation: 'WEBページ管理_オンライン相談',
      consultationDetail: 'WEBページ管理_オンライン相談_今津康平',
      notice: 'WEBページ管理_お知らせ',
      noticeDetail: 'WEBページ管理_お知らせ_新型コロナウイルス感染',
      experience: 'WEBページ管理_体験宿泊',
      experienceDetail: 'WEBページ管理_体験宿泊_今津康平',
      customer: '東急太郎',
      facilitySettings: 'WEBページ管理_施設設定'
    }
  },
  facility: {
    settings: {
      facilityList: 'Facility list',
      propertyID: 'Property ID',
      propertyName: 'Property name',
      facilityEditing: 'Facility (rental exhibition / parking lot) editing',
      facilityType: 'Facility type',
      facilityTypeNote: 'For point affiliated facility types.',
      facilityTypeNoteDetail: 'There is no inventory management, member page, accounting calculation, rental listing, or parking lot function.',
      facilityNameManagement: 'Facility name (for management)',
      managementScreenDisplayOrder: 'Management screen display order',
      delFacility: 'Delete this facility',
      deleteNotice: 'If you do not have a reservation for this facility, you can delete it by clicking the button below.',
      facilityFloorPlanPDF: 'Facility floor plan PDF',
      facilityIDForRakutsu: 'Facility ID for Rakutsu cooperation',
      askAgeOfBedSharedChildren: 'Ask age of bed shared children',
      receiptDisplayFax: 'Receipt display fax',
      receiptDisplayTel: 'TEL for display tel',
      receiptDisplayAddress: 'Receipt display address',
      forReceiptDisplay: 'For receipt display 〒 (half-width)',
      rentalUnitPriceTaxInclude: 'Rental unit price (tax included)',
      rentalUnitPriceTaxExclude: 'Rental unit price (tax excluded)',
      goGame: 'Go',
      shogi: 'Shogi',
      othello: 'Othello',
      playStation: 'Play station',
      mahjong: 'Mahjong',
      lifeGame: 'Life',
      vehicleHeightLimit: 'Vehicle height limit',
      waitingList: 'Waiting list',
      parkingLotRegistration: 'Parking lot additional registration',
      roomTypeInformation: 'Room type information',
      deleteThisRoomType: 'Delete this room type',
      deleteRoomTypeNotice: 'If you do not have a reservation for this room type, you can delete it from the button below',
      roomTypeNameManagement: 'Room type name (for management)',
      managementScreenColor: 'Management screen color',
      maxNumOfPeople: 'Maximum number of people',
      numOfCoSleeping: 'Number of co-sleeping',
      roomTypeIDCollaboration: 'Room type ID for Raku-Tsuko collaboration',
      roomNameManagement: 'Room name (for management)',
      deleteThisRoom: 'Delete this room',
      deleteRoomNotice: 'If you don\'t have a reservation for this room, you can delete it from the button below',
      createNewRoomType: 'Create new room type',
      numberOfRoomsCreated: 'Number of rooms created',
      roomNameInvalid: 'The room name is invalid.'
    },
    productsForSale: {
      listOfItemForSale: 'List of items for sale',
      saleItemID: 'Sale Item ID',
      saleItemName: 'Product name',
      saleUnitPrice: 'Sales unit price (excluding tax)',
      taxRate: 'Tax rate',
      chargeOnStatement: 'Charge on statement',
      createNewSaleItem: 'Create new sales item',
      changePriceAccommodation: 'Change the price in the accommodation details',
      itemIsRequired: 'Item is required'
    }
  }
}
