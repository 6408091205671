import Vue from 'vue'
import OutsideClick from './OutsideClick'

Vue.directive('focus', {
  // When the bound element is inserted into the DOM…
  inserted: function (el, binding) {
    if (binding.value) {
      // Focus the element
      el.focus()
    }
  }
})

Vue.directive('outside-click', OutsideClick)
