export const ROOM_LIST = `
query($roomTypeId: Int!) {
  roomList (roomTypeId: $roomTypeId) {
    id name
  }
}
`
export const BOOKING = `
query($id: Int!) {
  booking(id: $id) {
    id 
    bookingTypeId 
    priorityBooking 
    computed {
      status 
    }
    blockRoom 
    roomFixed 
    bookingBarNote 
    contract {
      id
    }
    bookingCertificateUrl 
    checkInDate
    checkOutDate 
    room {
      id 
      name 
      roomType {
        id 
        name 
        facility {
          id 
          name 
        }      
    }    
    }
    contract {
      
      mwPwRoomType {
        facility {
          name 
        }
      }
      mwRank 
      mwPwWeekNumber 
      
      client {
        name
        isCompany 
      }    
    }    
    salesChannel 
    
    toothbrush 
    yukata
    mailBookingCertificate 
    mailFacilityGuide 
    mailPointCertificate 
    mailAds 
    remarks 
    representative { 
      name 
      kana 
      tel 
      email 
      address1 
      nationality 
      passportPhoto 
      passportNumber 
    }
    bookingGroup {
      id 
      mwRights { 
        year 
      }
      createdSubMember { 
        name
      }
      createdClient {name}
      otaBookingId
      bookings {
        id  
        computed{
          status 
        }
        representative {
          name 
        } 
        room {
          name
          roomType {
            name 
          } 
        }   
      }   
    }
  }
}
`
export const BOOKING_BASIC_UPDATE = `
mutation(
  $bookingId: Int!
  $roomId: Int!
  $bookingBarNote: String
  $roomFixed: Boolean!
  ) {
  bookingBasicUpdate(basicUpdateInput:{
    bookingId: $bookingId
    roomId: $roomId
    bookingBarNote: $bookingBarNote
    roomFixed: $roomFixed
  }) {
    __typename
  }
}
`
