import { DISABLE_BOOKING_TICKET, CREATE_BOOKING_TICKETS, UPDATE_BOOKING_TICKET_TYPE, BOOKING_TICKET_TYPE } from '@/api/graphql/invitation-ticket/invitation-ticket'

import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import { CREATE_BOOKING_V4 } from '@/api/graphql/guestRoomDailyReport/create-booking.js'
import gql from 'graphql-tag'

export default {
  async disableBookingTicket ({ commit, rootState }, {
    ticketId,
    disabled
  }) {
    const variables = {
      ticketId,
      disabled
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DISABLE_BOOKING_TICKET}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DISABLE_BOOKING_TICKET, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async createBookingTickets ({ state, commit, rootState }) {
    const variables = {
      name: state.invitationTicket.name,
      numberOfTickets: parseInt(state.invitationTicket.numberOfTickets),
      roomTypeIds: state.invitationTicket.roomTypeIds,
      availableFromDate: state.invitationTicket.availableFromDate,
      availableToDate: state.invitationTicket.availableToDate,
      bookCallCenter: state.invitationTicket.bookCallCenter,
      bookSalesRep: state.invitationTicket.bookSalesRep,
      monday: state.invitationTicket.monday,
      tuesday: state.invitationTicket.tuesday,
      wednesday: state.invitationTicket.wednesday,
      thursday: state.invitationTicket.thursday,
      friday: state.invitationTicket.friday,
      saturday: state.invitationTicket.saturday,
      sunday: state.invitationTicket.sunday,
      holiday: state.invitationTicket.holiday,
      exceptDates: state.invitationTicket.exceptDates,
      nightwear: state.invitationTicket.nightwear,
      toothbrush: state.invitationTicket.toothbrush,
      razor: state.invitationTicket.razor,
      rentalItem: state.invitationTicket.rentalItem,
      accommodationPrice: parseInt(state.invitationTicket.accommodationPrice),
      cancelDeadline: parseInt(state.invitationTicket.cancelDeadline),
      bookingFromDate: state.invitationTicket.bookingFromDate,
      bookingFromDays: state.invitationTicket.bookingFromDays,
      bookingFromMonths: state.invitationTicket.bookingFromMonths,
      assetValue: parseInt(state.invitationTicket.assetValue)
    }
    commit('setLoadingOverlayShow', { root: true })

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_TICKETS}`,
        variables: variables
      }).then(data => {
        commit('setStatusCreateSuccess', true)
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_TICKETS, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      }).finally(() => {
        commit('setLoadingOverlayHide', { root: true })
      })
    })
  },

  async updateBookingTicketType ({ state, commit, rootState }) {
    const variables = {
      id: state.invitationTicket.idTicket,
      notes: state.invitationTicket.notes
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_BOOKING_TICKET_TYPE}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_BOOKING_TICKET_TYPE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async actionGetBookingTicketType ({ state, commit, rootState }, bookingTicketId) {
    const variables = {
      id: bookingTicketId
    }
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${BOOKING_TICKET_TYPE}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then(data => {
        resolve(data.data.bookingTicketType)
      }).catch(error => {
        setCopyErrorTextCommit(commit, BOOKING_TICKET_TYPE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async actionCreateBookingV4 ({ state, commit, rootState }, finishData) {
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_V4}`,
        variables: {
          ...finishData,
          representativeEmail: finishData.representativeEmail || null,
          representativePostalCode: finishData.representativePostalCode || null
        }
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        commit('setLoadingOverlayHide', { root: true })
        resolve(data.data.createBookingV4)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_V4, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
