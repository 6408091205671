import actions from './actions'

const holidays = {
  state: {
    currentYear: new Date().getFullYear(),
    startYear: new Date().getFullYear(),
    endYear: new Date().getFullYear(),
    holidays: []
  },
  mutations: {
    setHolidaysState (state, arr) {
      state.holidays = arr
    },
    setMoreHolidays (state, arr) {
      state.holidays = state.holidays.concat(arr)
    },
    setHolidayStartYear (state, val) {
      state.startYear = val
    },
    setHolidaysEndYear (state, val) {
      state.endYear = val
    },
    setCurrentYear (state, val) {
      state.currentYear = val
    }
  },
  actions
}

export default holidays
