const app = {
  state: {
    isLoading: false,
    isSideBarOpen: false
  },
  mutations: {
    setIsLoading (state, val) {
      state.isLoading = val
    },
    showCallCenterDialog (state, val) {
      state.callcenterDialog = val !== false
    },
    setIsSideBarOpen (state, open) {
      state.isSideBarOpen = open
    }
  },
  getters: {
    isSideBarOpen: state => state.isSideBarOpen
  }
}

export default app
