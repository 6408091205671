import { apolloClient } from '@/plugins/apollo'
import {
  BOOKING_GUEST_UPDATE
} from '@/api/graphql/bookingDetail/booking-guest'
import router from '@/router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async bookingGuestUpdate ({ commit, state, rootState }) {
    const variables = {
      bookingId: parseInt(router.currentRoute.query.id),
      guestWithSchedule: state.bookingGuest.guestSchedules?.map(row => {
        const { passportPhotoUrl: _remove, ...rest } = row
        return rest
      }),
      repSchedules: state.bookingGuest.repSchedules
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${BOOKING_GUEST_UPDATE}`,
        variables: variables
      }).then(data => {
        commit('setStatusReLoad', true)
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        commit('setStatusReLoad', true)
        setCopyErrorTextCommit(commit, BOOKING_GUEST_UPDATE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
