import gql from 'graphql-tag'

export const BOOKING = gql`
query ($id: Int!) {
    booking (id: $id){
      room {
        roomType {
          facility {
            id
          }      
        }    
      }
      checkInDate
      checkOutDate
      rentalItems {
        id
        unitPrice 
        quantity
        rentalPeriod
        fromDate
        toDate 
        rentalItem {
          id
          rentalItemDef {
            name
          }
        }
      }
    }
  }
`
export const ADD_RENTAL_ITEM_RESERVATION = `
mutation ($bookingId: Int!, $rentalItems: [AddReservationRentalItemInput!]!) {
  addRentalItemReservation(
    bookingId: $bookingId
    rentalItems: $rentalItems
  ) {
    __typename
  }
}
`

export const RENTAL_ITEM_LIST = gql`
query($facilityId: Int!) {
  rentalItemList(facilityId: $facilityId) {
    quantity
    id
    rentalItemDef{
      name
    }
  }
}
`

export const UPDATE_RESERVATION_RENTAL_ITEM_INPUT = `
mutation ($data: UpdateReservationRentalItemInput!) {
  updateRentalItemReservation(data: $data) {
    __typename
  }
}
`
