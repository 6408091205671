<template>
  <div>
    <v-navigation-drawer
      width="220"
      v-model="drawer"
      :permanent="navigationDrawerState"
      :expand-on-hover="isExpanded"
      dark
      fixed
      class="nav-custom"
    >
      <template v-slot:prepend>
        <v-list-item class="px-2 logo-item">
          <v-list-item-avatar class="logo-min">
            <v-img src="@/assets/images/favicon_pms.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <v-list
        nav
        dense
      >
        <v-list-item
          v-for="menuItem in mainMenuItemsSidebar"
          :key="menuItem.title"
          link
          :to="menuItem.route"
        >
          <v-list-item-icon :class="{ 'sidebar-svg-icon': menuItem.smaller }">
            <v-icon @click="setStatusCalendar()" v-if="menuItem.iconMdi">{{ menuItem.iconMdi }}</v-icon>
            <v-img v-if="menuItem.iconSvg" :src="menuItem.iconSvg" contain></v-img>
          </v-list-item-icon>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list
          nav
          dense
        >
          <v-list-item
            v-for="item in lowerMenuItemsSidebar"
            :key="item.title"
            link
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.iconMdi }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item link :href="`${urlCrm}/web-manage/info`" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("sidebar.webPageManagement") }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("sidebar.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-toolbar color="white" class="hidden-sm-and-up" width="100%">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SidebarLayout',
  data () {
    return {
      urlCrm: process.env.VUE_APP_URL_CRM,
      isExpanded: false,
      lowerMenuItems: [],
      lowerMenuItemsSidebar: [],
      mainMenuItems: [],
      mainMenuItemsSidebar: [],
      arrMenuPath: ['guest-room-daily-report', 'calendar', 'reservation', 'fees-points-plans', 'invitations-and-coupons', 'invitations-and-coupons-list', 'frame-setting', 'form-report', 'facility', 'users'],
      mainMenuItemsTmp: [
        {
          iconMdi: 'mdi-apps',
          title: this.$t('sidebar.guestRoomDailyReport'),
          route: '/guest-room-daily-report',
          pathRoot: 'guest-room-daily-report',
          order: 1
        },
        {
          iconMdi: 'mdi-calendar-month',
          title: this.$t('sidebar.calendar'),
          route: '/calendar',
          pathRoot: 'calendar',
          order: 2
        },
        {
          iconMdi: 'mdi-format-list-bulleted',
          title: this.$t('sidebar.reservation'),
          route: '/reservation',
          pathRoot: 'reservation',
          order: 3
        },
        {
          iconMdi: 'mdi-currency-cny',
          title: this.$t('sidebar.feesPoints'),
          route: '/fees-points-plans',
          pathRoot: 'fees-points-plans',
          order: 4
        },
        {
          iconSvg: require('../assets/images/sidebar-icons/invitationUseTickets.svg'),
          title: this.$t('sidebar.invitationUseTickets'),
          route: '/invitations-and-coupons',
          smaller: true,
          pathRoot: 'invitations-and-coupons',
          order: 5
        },
        {
          iconMdi: 'mdi-crop',
          title: this.$t('sidebar.frameSetting'),
          route: '/frame-setting',
          pathRoot: 'frame-setting',
          order: 6
        },
        {
          iconMdi: 'mdi-file-document-outline',
          title: this.$t('sidebar.formReport'),
          route: '/form-report',
          pathRoot: 'form-report',
          order: 7
        },
        {
          iconMdi: 'mdi-hospital-building',
          title: this.$t('sidebar.facility'),
          route: '/facility',
          pathRoot: 'facility',
          order: 8
        }
      ],
      lowerMenuItemsTmp: [
        {
          iconMdi: 'mdi-account-circle',
          title: this.$t('sidebar.users'),
          route: '/users',
          pathRoot: 'users',
          order: 2
        }
      ]
    }
  },
  emits: {
    'is-sidebar-open': null
  },
  mounted () {
    this.permissionUserSideBar()
    this.isExpanded = this.navigationDrawerState
  },
  watch: {
    navigationDrawerState (state) {
      this.drawer = state
      this.isExpanded = state
    },
    isExpanded (value) {
      this.$emit('is-sidebar-open', value)
    }
  },
  computed: {
    ...mapGetters(['getRoleUser', 'getPermissionUser']),
    navigationDrawerState () {
      return ['sm', 'md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    drawer: {
      get () {
        return this.$store.isSideBarOpen
      },
      set (v) {
        this.$store.commit('setIsSideBarOpen', v)
      }
    }
  },
  methods: {
    setStatusCalendar () {
      localStorage.setItem('statusSetCalenDar', JSON.stringify(false))
    },

    logout () {
      this.$store.dispatch('logOut')
      this.$router.push({ path: '/sign-in' })
    },
    permissionUserSideBar () {
      this.lowerMenuItems = []
      this.mainMenuItems = []
      this.lowerMenuItemsSidebar = []
      this.mainMenuItemsSidebar = []
      if (this.getRoleUser) {
        this.mainMenuItems = this.mainMenuItemsTmp
        this.lowerMenuItems = this.lowerMenuItemsTmp
      } else {
        if (Object.keys(this.getPermissionUser).length !== 0) {
          for (const [key] of Object.entries(this.getPermissionUser)) {
            const arrPathPerUser = key.split('/')
            const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
            if (!checkArrPath && this.arrMenuPath.includes(arrPathPerUser[1])) {
              if (arrPathPerUser[1] !== 'users') {
                let menuCurrent = this.mainMenuItemsTmp.filter(({ pathRoot }) => pathRoot === arrPathPerUser[1])
                if (arrPathPerUser[1] === 'invitations-and-coupons-list') {
                  menuCurrent = this.mainMenuItemsTmp.filter(({ pathRoot }) => pathRoot === 'invitations-and-coupons')
                }
                if (menuCurrent.length > 0) {
                  this.mainMenuItems.push(menuCurrent[0])
                }
              } else {
                const menuCurrentLow = this.lowerMenuItemsTmp.filter(({ pathRoot }) => pathRoot === arrPathPerUser[1])
                if (menuCurrentLow.length > 0) {
                  this.lowerMenuItems.push(menuCurrentLow[0])
                }
              }
            }
          }
        }
      }
      this.mainMenuItems = [...new Set(this.mainMenuItems)]
      this.mainMenuItems.sort((a, b) => a.order - b.order)
      this.lowerMenuItems = [...new Set(this.lowerMenuItems)]
      this.lowerMenuItems.sort((a, b) => a.order - b.order)
      this.lowerMenuItemsSidebar = this.lowerMenuItems
      this.mainMenuItemsSidebar = this.mainMenuItems
    }
  }
}
</script>

<style scoped lang="scss">
  .nav-custom {
    z-index: 200;
  }
  .v-navigation-drawer.v-navigation-drawer--mini-variant {

  }

  .v-navigation-drawer {
    background-color: #2173d9!important;

    .logo-min {
      display: block;
    }

    .v-list-item__icon {
      margin-right: 15px!important;
    }
  }

  .v-list-item {

    &.v-list-item__icon {
      background-size: contain;
      width: 24px;
      margin-right: 12px!important;
    }
  }
</style>
