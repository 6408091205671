export default {
  ACCOMMODATION_POINT_MUST_BE_GREATER_THAN_OR_EQUAL_TO_0: '宿泊ポイントを0以上の整数で指定して下さい',
  ACCOMMODATION_PRICE_CSV_IMPORT_INVALID_FILE_FORMAT: '価格インポートCSVのフォーマットが不正です',
  ACCOMMODATION_PRICE_CSV_IMPORT_INVALID_ROOM_TYPE: '不正な施設・部屋タイプのIDが含まれています',
  ACCOMMODATION_PRICE_CSV_PARSE_FAILED: '宿泊料金CSVの読み込みに失敗しました。',
  ACCOMMODATION_PRICE_IMPORT_ALREADY_SCHEDULED: '既にスケジュールされている部屋タイプ・日付の組み合わせが含まれています',
  ACCOMMODATION_PRICE_IMPORT_NOT_FOUND: '存在しない取り込みファイルです',
  ACCOMMODATION_PRICE_IS_ALREADY_CANCELLED: '既にキャンセルされている読み込みです',
  ACCOMMODATION_PRICE_IS_ALREADY_IMPORTED: '既に取り込まれているためキャンセルできません',
  ACCOMMODATION_PRICE_NOT_ENOUGH: '宿泊料が未設定のため価格を決定できません',
  AFTER_TRANSACTION_POINTS_SHOULD_BE_MORE_THAN_0: '保有TVPが不足しているため指定の操作を実行できません。所持ポイントは{{current}}Ptです',
  ALLOWED_ONLY_FOR_FUTURE_BOOKINGS: '当日の予約は編集することができません',
  ANNUAL_CONTRACT_POINT_NOT_FOUND: '追加購入の年度に有効な契約ポイントが存在しないため処理を完了できません。',
  AVAILABLE_DAY_OF_WEEK_IS_FALSE: '指定のチケットでは利用できない曜日が含まれます',
  AVAILABLE_HOLIDAY_IS_FALSE_AND_EXISTS: '指定のチケットは宿泊利用ができません',
  BLOCK_PARKING_INVALID_PARKING_NUMBER: '駐車場ブロック枠数が不正です',
  BLOCK_RENTAL_ITEM_RESERVATION_INVALID_QUANTITY: '数量の指定が不正です',
  BOOKING_ACCOMMODATION_FEE_NOT_SET: '価格テーブルが未設定のため価格が計算できません',
  BOOKING_ADDITIONAL_CHARGE_NOT_FOUND: '存在しない請求項目です',
  BOOKING_ALREADY_CANCELLED: '既にキャンセルされた予約です',
  BOOKING_CAN_NOT_BE_CANCELLED: 'OTA予約はOTA上でのみキャンセルすることができます',
  BOOKING_CANCEL_DUPLICATED_STAY_DATE: 'キャンセル日に重複があります',
  BOOKING_CANCEL_STAY_DATE_OUT_OF_RANGE: 'キャンセル日が宿泊期間外です',
  BOOKING_CANNOT_READ: '当該予約の作成者でないため閲覧することができません',
  BOOKING_CHARGE_ALREADY_INVOICED: '既に請求書に含まれているため更新できません',
  BOOKING_CHARGE_EXTERNALLY_PAID: 'システム外で支払われた請求項目が選択されています',
  BOOKING_CHARGE_NOT_JPY: 'ポイントの請求項目は更新できません',
  CANNOT_CHARGE_ALL_CHECKED_OUT: '全ての予約がチェックアウト済みのため請求明細の変更はできません',
  CANNOT_CHECK_OUT_UNINVOICED_CHARGE: '未請求の請求項目が本予約若しくは予約グループ内の他の予約に残っているためチェックアウトはできません',
  BOOKING_DAYS_INPUT_IS_REQUIRED: '宿泊日の指定がありません',
  BOOKING_DELETE_NOT_CANCELLED: 'キャンセルされた予約のみ削除ができます',
  BOOKING_DOES_NOT_BELONG_TO_CURRENT_CLIENT: '指定の予約を編集する権限がありません',
  BOOKING_EXTENSION_CHECK_PRICE_NOT_AVAILABLE: '指定の予約は延泊ができません',
  BOOKING_EXTENSION_FIRST_DATE_IS_INVALID: '不正な延泊の宿泊日指定です',
  BOOKING_MOVE_CHECK_PRICE_NOT_AVAILABLE: 'PMSからのチェックイン・アウト日の変更ができない商品です。CRMから変更をお願いします。',
  MOVE_NOT_SUPPORTED: 'PMSからのチェックイン・アウト日の変更ができない商品です。CRMから変更をお願いします。',
  BOOKING_FROM_DATE_IS_NOT_AFTER_STAY_DATE: '指定のチケットでは利用できない日程です',
  BOOKING_GROUP_DOES_NOT_BELONG_TO_CURRENT_CLIENT: '指定の予約を編集する権限がありません',
  BOOKING_GROUP_IN_BOOKING_NOT_FOUND: '指定の予約グループに１件も予約が含まれていません',
  BOOKING_GROUP_NOT_FOUND: '存在しない予約グループです',
  BOOKING_INVALID_INVENTORY_TYPE: 'ポイント提携予約ではメンバー公開枠のみ指定できます',
  BOOKING_INVOICE_NOT_FOUND: '存在しない請求書です',
  BOOKING_NOT_FOUND: '存在しない予約です',
  BOOKING_NOT_FOUND_FOR_RENTAL_ITEM_RESERVATION: '存在しない予約です',
  BOOKING_NOT_TICKET_TYPE: 'チケット予約ではありません。',
  BOOKING_PLAN_CHARGE_NOT_ENOUGH_ERROR: 'プランの特殊除外日を含む日程のため利用できません',
  BOOKING_PLAN_CSV_PARSE_FAILED: 'プラン価格CSVの読み込みに失敗しました。',
  BOOKING_PLAN_MAX_DAYS_COUNT_ERROR: 'プランの最大宿泊日数を超えるため利用できません',
  BOOKING_PLAN_MAX_GUESTS_COUNT_ERROR: 'プランの最大利用人数を超えるため利用できません',
  BOOKING_PLAN_MAX_GUESTS_LIMIT_ERROR: 'プランの最大利用人数を超えたため予約を受け付けておりません',
  BOOKING_PLAN_MIN_DAYS_COUNT_ERROR: 'プランの最低宿泊日数に満たないため利用できません',
  BOOKING_PLAN_MIN_GUESTS_COUNT_ERROR: 'プランの最低利用人数に満たないため利用できません',
  BOOKING_PLAN_NOT_AVAILABLE_FOR_SELECTED_DAYS: '指定のプランは指定された宿泊日で使用できません',
  BOOKING_PLAN_NOT_FOUND: '存在しないプランです',
  BOOKING_PLAN_ROOM_COUNT_LIMIT_ERROR: 'プランの最大利用部屋数を超えたため予約を受け付けておりません',
  BOOKING_PLAN_STOP_SALE_DATE_ERROR: '販売が終了したプランです',
  BOOKING_PLAN_USED: '現在利用されているプランのため削除できません',
  BOOKING_TICKET_IS_ALREADY_USED: '既に利用されたチケットです',
  BOOKING_TICKET_NOT_AVAILABLE: '無効化されているチケットです',
  BOOKING_TICKET_NOT_FOUND: '存在しないチケットです',
  BOOKING_TICKET_TYPE_NOT_FOUND: '存在しないチケット種別です',
  MAX_NUMBER_OF_TICKET_TO_CREATE: '作成可能なチケットの最大数を超えています',
  BOOKING_TYPE_PLAN_TARGET_TYPE_NOT_MATCH: '指定のプランは指定の契約で利用することはできません',
  BOOKING_WAIT_LIST_CANCEL_ALREADY_NOTIFIED: '既に通知されたキャンセル待ちのため削除できません',
  BOOKING_WAIT_LIST_CREATE_DUPLICATED: '既に同様のキャンセル待ちが登録されています',
  BUSINESS_TYPE_NOT_FOUND: '存在しない業種です',
  CALCULATE_ACCOMMODATION_PRICE_NOT_DEFINED: '価格テーブルが未設定のため価格が計算できません',
  CAMPAIGN_APPLY_RESPONSE_NOT_FOUND: '存在しない活動履歴です',
  CAMPAIGN_NOT_FOUND: '存在しない広告施策です',
  CAN_NOT_CANCEL_OUTDATED_BOOKING_CHECK_IN: 'チェックイン日以降のキャンセルは施設へ電話連絡下さい',
  CANCEL_WAIT_LIST_NOT_FOUND: '存在しないキャンセル待ちです',
  CHECK_IN_DATE_NOT_BETWEEN_AVAILABLE_DATES: '指定のチケットでは利用できない日程です',
  CLIENT_ACTION_HISTORY_NOT_FOUND: '存在しない活動履歴です',
  CLIENT_ANNUAL_FEE_NOT_FOUND: '存在しない年会費です',
  CLIENT_BOOKING_DATA_MISMATCH: '予約グループに含まれる全ての予約の情報を入力する必要があります',
  CLIENT_BOOKING_INSUFFICIENT_PERMISSIONS: '指定の予約を編集する権限がありません',
  CLIENT_BOOKING_TEMPORARY_EXPIRED: '仮予約の期限が切れたため予約を確定できません',
  CLIENT_CHARGE_NOT_FOUND: '存在しない顧客請求です',
  CLIENT_CHARGE_TYPE_NOT_FOUND: '存在しない顧客請求タイプです',
  CLIENT_COMMENT_NOT_FOUND: '存在しないコメントです',
  CLIENT_COMMENT_NOT_OWNED_BY_STAFF: '指定のコメントを編集する権限がありません',
  CLIENT_CONTACT_NOT_FOUND: '存在しない連絡先です',
  CLIENT_CREATE_BOOKING_GROUP_BOOKING_FOR_PAST_DATE: '宿泊日は未来の日付を指定して下さい',
  CLIENT_CREATE_BOOKING_GROUP_BOOKING_TOO_EARLY: '予約受付期間外です',
  CLIENT_CREATE_BOOKING_GROUP_NEED_ONE_ADULT: '少なくとも1部屋1名大人の宿泊者が必要です',
  CLIENT_CREATE_BOOKING_GROUP_NO_ROOMS: '部屋数を指定して下さい',
  CLIENT_CREATE_BOOKING_GROUP_SAME_DAY_BOOKING: '当日の予約はWebからコールセンターへご連絡下さい',
  CLIENT_CREATE_BOOKING_GROUP_TOO_MANY_GUESTS: '部屋タイプの最大客数を超えています',
  CLIENT_CREATE_BOOKING_GROUP_UNSUPPORTED_FACILITY: '指定の部屋タイプは予約を受け付けておりません',
  OVERLAPPING_BOOKINGS_NOT_ALLOWED: '既に同日に別施設の予約があります。日程の重なる複数施設の予約は出来ません。',
  CLIENT_HAS_NEITHER_GMO_NOR_TOP: '登録カード・TOPカード共に未登録の顧客です',
  CLIENT_LOGIN_NO_VALID_CONTRACT: '契約が存在しないか満了を迎えているためログインできません。',
  CLIENT_LOGIN_NOT_ALLOWED_VS_FOR_SUB_MEMBERS: 'バケーションスタイルWebには会員様のみログインが行えます',
  CLIENT_NOT_DELETE_DUPLICATED_TARGET_CONTRACT: '既に契約が存在するため重複処理を実行できません',
  CLIENT_NOT_DELETE_DUPLICATED_TARGET_TYPE: 'VS資料請求顧客のみ重複処理が実行できます',
  CLIENT_NOT_FOUND: '存在しない顧客です',
  CONFIRM_PIC_ASSIGNMENTS_EMPTY: '担当割り振り結果を指定して下さい',
  CONTENT_TYPE_NOT_SUPPORTED: '指定のファイル形式はアップロードできません',
  CONTRACT_ADD_SIGNUP_BONUS_MORE_THAN_3: '最大3つまで契約特典を追加できます',
  CONTRACT_ALREADY_SUSPENDED: '既に停止済みの契約です',
  CONTRACT_ALREADY_UNSUSPENDED: '指定の契約は停止状態ではありません',
  CONTRACT_ATTACHMENT_NOT_FOUND: '存在しない契約関連ファイルです',
  CONTRACT_NOT_ALLOW_FOR_POINT_EXCHANGE: 'ポイント交換で使用できない契約です',
  CONTRACT_NOT_ALLOWED_FOR_TICKET_BOOKING: 'チケット予約では契約を指定することはできません',
  CONTRACT_NOT_FOUND: '存在しない契約です',
  CONTRACT_NOT_MW: '指定の契約はMWではありません',
  CONTRACT_NOT_PW: '指定の契約はPWではありません',
  CONTRACT_NOT_VM: '指定の契約はVMではありません',
  CONTRACT_POINT_NOT_FOUND: '存在しない契約ポイントです',
  CONTRACT_POINTS_NOT_ENOUGH: 'ポイント不足のため指定の操作を行うことができません',
  CONTRACT_POINTS_NOT_FOUND: '処理の対象となる契約ポイントが存在しません',
  CONTRACT_TRANSFER_ALREADY_EXPIRED: '既に満了している契約です',
  CONTRACT_TRANSFER_ALREADY_TERMINATED: '既に解約された契約です',
  CONTRACT_TRANSFER_ALREADY_TRANSFERRED: '既に譲渡・相続された契約です',
  CONTRACT_TRANSFER_UNCANCELLED_BOOKING_EXISTS: '未キャンセルの予約が存在するため指定の操作を行うことができません',
  CONTRACT_TYPE_NEITHER_SP_NOR_FP: 'シェアリングポイント・フレックスポイントの契約のみ行える処理です',
  CREATE_BOOKING_BOOKING_TYPE_NOT_FOUND: '存在しない予約タイプです',
  CREATE_BOOKING_CLIENT_NOT_FOUND: '存在しない顧客です',
  CREATE_BOOKING_CONTRACT_CLIENT_AND_CREATED_CLIENT_NOT_MATCH: '不正な予約作成者顧客IDです',
  CREATE_BOOKING_CONTRACT_NOT_SUPPORTING_POINT_BOOKING: 'ポイント提携予約に利用できない契約が指定されています',
  CREATE_BOOKING_INVALID_CLIENT_ID: '不正な代表者顧客IDです',
  CREATE_BOOKING_INVALID_CREATED_SUB_MEMBER_ID: '不正な予約作成者付属会員IDです',
  CREATE_BOOKING_INVALID_SUB_MEMBER_ID: '不正な代表者付属会員IDです',
  CREATE_BOOKING_NO_BOOKING_IN_THE_GROUP: '予約グループに予約が存在しません',
  CREATE_BOOKING_TOO_MANY_GUESTS: '部屋タイプの最大客数を超えています',
  CREATE_BOOKING_TRIAL_WITHOUT_CLIENT_ID: '体験宿泊は顧客IDを指定する必要があります',
  CREATE_BOOKING_UNSUPPORTED_BOOKING_TYPE: '指定の予約タイプで予約を作成することはできません',
  CREATE_BOOKING_UNSUPPORTED_CONTRACT: '新規予約を作成できない契約タイプです',
  CREATE_VM_BOOKINGS_INVALID_BOOKINGS_PRICE: '権利額が未設定の日付が存在します',
  CREATE_VM_BOOKINGS_INVALID_BOOKINGS_YEARS: 'バケーションマスターの年数と予約数が一致しません',
  CREATE_VM_TENTATIVE_BOOKINGS_INVALID_BOOKINGS_YEARS: 'バケーションマスター仮押さえの年数と予約数が一致しません',
  CREDIT_CARD_NOT_FOUND: 'クレジットカードが未登録です',
  CSV_CHARGE_TYPE_IS_NOT_SAME_AS_BOOKING_PLAN_CHARGE_TYPE: '不正な価格設定ファイルフォーマットです',
  CSV_HEADER_IS_EMPTY: 'CSVのヘッダ行が存在しません',
  CSV_HEADER_VALIDATION_FAILED: 'CSVのヘッダ行の形式が不正です',
  DAYS_OF_WEEK_MORE_THAN_7: '曜日の指定が不正です',
  DAYS_VM_BOOKING_DATES_NOT_EQUAL: '枠属性未設定の宿泊日が存在します',
  DELETE_ANNUAL_FEE_ALREADY_PROCESSED: '既に処理済みのため削除できません',
  DELETE_CONTRACT_NOT_TENTATIVE: '仮契約以外の契約は削除できません',
  DELETE_SIGNUP_BONUS_APPLICATION_ALREADY_HANDLED: '既に対応済みのため削除できません',
  DEPARTMENT_NOT_FOUND: '存在しない部署です',
  DIFFERENCE_DAYS_BOOKING_FROM_DAYS_ERROR: '指定のチケットの予約可能時期外です',
  DUPLICATED_STAFF_GROUP_NAME: '既に存在するグループ名です',
  EMAIL_ALREADY_REGISTERED_FOR_CLIENT_LOGIN: '既に利用されているメールアドレスです',
  EMAIL_TEMPLATE_NOT_FOUND: '存在しないメールテンプレートです',
  EMAIL_VERIFICATION_ALREADY_USED: '既に使用されたトークンです',
  EMAIL_VERIFICATION_INVALID_TOKEN: 'トークンが不正です',
  EMAIL_VERIFICATION_NOT_FOUND: '無効なトークンです',
  EMAIL_VERIFICATION_TOKEN_EXPIRED: 'メール認証の有効期限が切れています。再度お試し下さい',
  END_DATE_LESS_THAN_START_DATE: '終了日より開始日が後になっています',
  EXTENSION_NOT_SUPPORTED: '指定の予約は延泊ができません',
  FACILITY_DAILY_REPORT_ALREADY_HQ_CONFIRMED: '既に承認済みです',
  FACILITY_DAILY_REPORT_ALREADY_SUBMITTED: '既に提出済みの日報です',
  FACILITY_DAILY_REPORT_NOT_FOUND: '存在しない日報です',
  FACILITY_DAILY_REPORT_TYPE_IS_NOT_CNV: '日報の形式がコンバージョン施設用ではありません',
  FACILITY_DAILY_REPORT_TYPE_IS_NOT_TVS: '日報の形式が直営施設用ではありません',
  FACILITY_HOTEL_TAX_AMOUNT_NOT_FOUND: '存在しない宿泊税料金です',
  FACILITY_HOTEL_TAX_NOT_FOUND: '存在しない宿泊税設定です',
  FACILITY_ID_DOES_NOT_BELONG_TO_BOOKING: '指定の駐車場は本予約では利用できません',
  FACILITY_ID_MISMATCH: '指定の施設情報の参照権限がありません',
  FACILITY_ID_NOT_SAME: '不正な施設IDです',
  FACILITY_IS_NOT_TVS: '直営施設ではないため直営施設形式の日報は提出できません',
  FACILITY_NOT_FOUND: '存在しない施設です',
  FACILITY_PARKING_HEIGHT_REQUIRED: '車高制限があるため車高を入力する必要があります',
  FACILITY_PARKING_NOT_FOUND: '存在しない駐車場です',
  FACILITY_PARKING_RESERVATION_NOT_FOUND: '存在しないかキャンセル済みの駐車場予約です',
  FACILITY_PARKING_RESERVATION_TOO_LATE: '駐車場のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  FACILITY_PARKING_WAITLIST_NOT_FOUND: '存在しない駐車場キャンセル待ちです',
  FACILITY_REPORT_NOT_ALLOWED_DATE: '現在選択できない日付です',
  FACILITY_TYPE_IS_NOT_CNV: 'コンバージョン施設ではないためコンバージョン施設形式の日報は提出できません',
  FACILITY_TYPE_PT_PARTIAL_CANCEL_NOT_ALLOWED: 'ポイント提携施設予約は部分キャンセルができません',
  FACILITY_WAIT_LIST_CREATE_DUPLICATED: '既にキャンセル待ちは設定済みです',
  FILE_IS_EMPTY: 'ファイルが空です',
  FILE_MAX_SIZE_EXCEEDED: '最大ファイルサイズを超えるためアップロードできません',
  FILE_UPLOAD_RULE_NOT_FOUND: 'アップロードタイプが不正です',
  FIRST_BOOKING_NOT_FOUND: '分割元の予約が存在しないかキャンセルされています',
  FIRST_DATA_SHOULD_BE_REPRESENTATIVE: '代表者は大人である必要があります',
  FROM_DATE_IS_NOT_IN_BOOKING_DATE_RANGE: '駐車場キャンセル待ちは宿泊日の範囲でのみ行えます',
  GET_DAILY_REPORT_FACILITY_ID_REQUIRED: '日報ID若しくは施設IDを指定して下さい',
  IN_BOOKING_PLAN_EXCEPTION_RANGE_ERROR: 'プランの除外日を含む日程のため利用できません',
  INVALID_BOOKING_CONTRACT: '宿泊日以前に終了する契約のため予約を行うことができません',
  INVALID_CLIENT_ON_BOOKING: '存在しない予約です',
  INVALID_CLIENT_REFRESH_TOKEN: '無効なリフレッシュトークンです',
  INVALID_CONTRACT_ID_FOR_AVAILABLE_FACILITY_LIST: '新規予約に利用できない契約です',
  INVALID_DATE_YEAR_MONTH: '年月の値が不正です',
  INVALID_EMAIL: 'メールアドレスが不正です',
  INVALID_MEDIA_TYPE: '不正な広告媒体です',
  INVALID_PASSWORD: '存在しないユーザです',
  INVALID_STAFF_ASSIGNMENT: '不正な社員IDが含まれています',
  INVALID_STAFF_REFRESH_TOKEN: '不正なリフレッシュトークンです',
  INVALID_TICKET_BOOKING_DATES: 'チケット予約の状態が不正です',
  INVALID_TICKET_BOOKING_PENALTY_RATE: 'チケット予約のキャンセルリクエスト形式が不正です',
  INVALID_TIME: '時刻の形式が不正です',
  INVALID_VERIFICATION_TOKEN: 'トークンが不正です',
  INVALID_VS_PROMO_CODE: '不正な特典コードの形式です',
  INVENTORY_TYPE_NOT_FOUND: '存在しない枠属性です',
  INVOICE_ALREADY_PAID_CHARGE: '既に請求書が作成済みの請求項目が選択されています',
  INVOICE_DELETE_ALREADY_FINALIZED: '既に支払登録された請求書は削除できません',
  INVOICE_EXTERNALLY_CHARGED: 'システム外で支払われた請求項目が選択されています',
  INVOICE_INVALID_BOOKING_GROUP_CHARGE_COMBINATION: '不正な請求項目が選択されています',
  INVOICE_INVALID_PAYMENT_METHOD_COMBINATION: '不正な支払い方法の組み合わせです',
  INVOICE_MULTIPLE_REGISTERED_CARD_PAYMENTS: '登録カード払いは1つのみ登録が可能です',
  INVOICE_NO_CHARGE_SPECIFIED: '最低1つの請求項目を指定して下さい',
  INVOICE_NO_PAYMENT_SPECIFIED: '最低1つの支払い情報が必要です',
  INVOICE_NON_JPY_CHARGE: '日本円の請求項目のみ請求書に含めることができます',
  INVOICE_NOT_FOUND: '存在しない請求書です',
  INVOICE_PAYMENT_SUM_NOT_MATCH: '指定の支払い方法の合計額と選択された請求項目の合計額が一致しません',
  INVOICE_PDF_FILE_IS_NOT_SET: '請求書PDFが未作成状態です',
  INVOICE_REGISTERED_CARD_FOR_NON_CLIENT: '紐づく会員が存在しないため登録カード払いは使用できません',
  INVOICE_TVP_FOR_NON_CLIENT: '紐づく会員が存在しないためTVP払いは使用できません',
  INVOICE_UPDATE_ALREADY_FINALIZED: '既に支払登録された請求書は更新できません',
  MANUAL_CLIENT_CHARGE_CREATE_NO_GMO_CARD_REGISTERED: '登録カードが存在しない顧客です',
  MANUAL_CLIENT_CHARGE_CREATE_NO_TOP_CARD_REGISTERED: 'TOPカードが未登録の顧客です',
  MEMBER_USER_EXIST_EMAIL: '既に利用されているメールアドレスです',
  MEMBER_USER_NOT_FOUND: '顧客のメールアドレスが未登録状態です',
  MW_AFTER_DEPOSIT_DEADLINE: '交換期限を過ぎでいるため交換予約を行うことができません',
  MW_ALREADY_DEPOSITED: '既にデポジットされている権利です',
  MW_DEPOSIT_EXPIRE_DATE_NOT_FOUND: 'マイウィークカレンダー設定不足のためデポジット失効日が決定できません。',
  MW_INVALID_DEPOSIT_REQUEST_FIRST_OR_LATTER: 'デポジット対象を指定して下さい',
  MW_INVALID_SPLIT: 'マイウィーク予約分割のチェックイン日が不正です',
  MW_NEW_BOOKING_RANGE: '元権利の前後1年間の範囲でのみ交換予約が可能です',
  MW_NO_DEPOSITED_RIGHT: '交換に利用できるデポジット済みの権利がありません',
  MW_NO_UPGRADE: '契約マイウィークランク以上の施設のため交換することができません',
  MW_OPERATION_RIGHT_ALREADY_EXCHANGED: '既に交換に利用されているため処理を完了できません',
  MW_OPERATION_RIGHT_ALREADY_REVOKED: '既に無効化されている権利です',
  MW_OPERATION_RIGHT_EXPIRED: '期限切れのため処理を完了できません',
  MW_OPERATION_RIGHT_NOT_DEPOSITED_YET: 'デポジット状態の権利のみ操作を行うことができます',
  MW_OPERATION_RIGHT_NOT_FOUND: '存在しないマイウィーク権利です',
  MW_SPLIT_ALREADY_CHECKED_IN: '既にチェックインされた予約は分割できません',
  MW_SPLIT_RIGHT_CANNOT_BE_DEPOSITED_AT_ONCE: '前後半で分割されている場合、それぞれ順番にデポジット操作を行って下さい',
  MW_WEEK_CALENDAR_NOT_FOUND: 'マイウィークカレンダーが未設定の施設のため交換を行うことができません。',
  MY_CONTRACT_NOT_FOUND: '存在しない契約です',
  NAME_INVALID_LENGTH: 'グループ名は最低2文字必要です',
  NON_NOTIFIED_WAITLIST_EXCEEDS_ALLOWED_NUMBERS: '設定可能な最大数を超えるため駐車場キャンセル待ちは追加できません',
  NON_PRODUCT_CSV_IMPORT_INVALID_FILE_FORMAT: '商品外料金CSVの形式が不正です',
  NON_PRODUCT_CSV_IMPORT_INVALID_ROOM_TYPE: '不正な部屋タイプIDが含まれています',
  NON_PRODUCT_CSV_PARSE_FAILED: '商品外料金CSVの読み込みに失敗しました。',
  NON_PRODUCT_NOT_FOUND: '存在しない商品外品目です',
  NON_VS_AUTO_RENEWAL_NOT_ALLOWED: 'バケーションスタイル以外の契約では自動更新は設定できません',
  NOT_A_CNV_FACILITY_REPORT: 'コンバージョン施設日報ではありません',
  NOT_A_TVS_FACILITY_REPORT: '直営施設日報ではありません',
  NOT_FOUND_WEB_NOTIFICATION: '存在しないWeb通知です',
  NOT_IN_BOOKING_PLAN_ACCEPTANCE_DATE_RANGE_ERROR: 'プランの予約受付期間外のため利用できません',
  NOT_IN_BOOKING_PLAN_AVAILABLE_RANGE_ERROR: 'プランの利用日以外の日程を含むため利用できません',
  NOT_IN_BOOKING_PLAN_THROUGH_DATE_RANGE_ERROR: 'プランの予約受付日数以前のため利用できません',
  NOT_IN_BOOKING_PLAN_WEEKDAYS_RANGE_ERROR: 'プランが利用できない曜日を含む日程のため利用できません',
  NUMBER_OF_GUESTS_SHOULD_BE_MORE_THAN_0: '最低1人の宿泊客を指定して下さい',
  OTHER_PUBLIC_DOC_NOT_FOUND: '存在しないファイルです',
  OUT_OF_INVENTORY_STOCK: '指定の部屋タイプ・日程で在庫がありません。',
  OUT_OF_RANGE_POINTS: 'ポイント不足のため処理を完了できません',
  PARKING_DATE_NOT_IN_BOOKING: '日付は宿泊期間の範囲のみ設定可能です',
  PARKING_HEIGHT_BIGGER_THAN_MAX_HEIGHT: '車高制限以上の高さのため駐車場を利用できません',
  PARKING_RESERVATION_BIGGER_THAN_FACILITY_PARKING: '駐車場に空きが無いため予約を完了できませんでした',
  PARKING_RESERVATION_UPDATE_DATE_OUT_OF_STAY_RANGE: '不正な駐車場予約日付範囲です',
  PASSWORD_VERIFICATION_FAILED: 'パスワード認証に失敗しました',
  PAYMENT_METHOD_NOT_FOUND: '存在しない支払い方法です',
  PENALTY_RATES_INVALID_FORMAT: 'キャンセル日は宿泊日の最後の日から順番に指定して下さい',
  PENALTY_RATES_NOT_SPECIFIED: 'キャンセル率が指定されていません',
  PERSON_RELATIONSHIP_NOT_FOUND: '存在しない属柄です',
  POINT_BOOKING_FACILITY_NOT_PT: '指定された部屋タイプはポイント提携施設ではないため予約を作成できません',
  POINT_EXCHANGE_NOT_FOUND: '存在しないポイント交換です',
  POINT_EXCHANGE_PRODUCT_NOT_FOUND: '存在しないポイント交換商品です',
  POINTED_BOOKING_CHARGE_HAS_NO_CONTRACT: '予約に契約が紐付かないため更新できません',
  POINTED_BOOKING_CHARGE_INVALID_CONTRACT_TYPE: '予約に紐づく契約はポイント商品でないため更新できません',
  PRE_CHECKIN_MY_BOOKING_INVALID_GUEST_IDS: '宿泊者IDが不正です',
  PRICE_CALCULATE_SPECIFY_CONTRACT_OR_BOOKING_TYPE: '宿泊料計算のため利用契約を指定して下さい',
  PT_FACILITY_IS_NOT_FOR_REPOT: 'ポイント提携施設のため日報を作成できません',
  RECEIPT_NOT_FOUND: '存在しない領収書です',
  REGISTER_FIRST_ACCOUNT_ALREADY_EXISTS: '既にメールアドレス登録が完了しておりますので、そちらを利用してログインを行って下さい',
  REGISTER_FIRST_ACCOUNT_CLIENT_MAIN_CONTACT_NOT_FOUND: '入力内容に誤りがあります。内容をお確かめの上再度お試し下さい',
  REGISTER_FIRST_ACCOUNT_CLIENT_NOT_FOUND: '入力内容に誤りがあります。内容をお確かめの上再度お試し下さい',
  REGISTER_FIRST_ACCOUNT_TOKEN_EXPIRED: 'メールアドレス認証の有効期限を超えたため実行できません',
  RENTAL_ITEM_DATE_NOT_IN_BOOKING: '宿泊期間外の貸出期間を設定することはできません',
  RENTAL_ITEM_DEF_NOT_FOUND: '存在しない貸出品です',
  RENTAL_ITEM_NOT_FOUND: '存在しない貸出品です',
  RENTAL_ITEM_RESERVATION_BIGGER_THAN_RENTAL_ITEM_QUANTITY: '貸出品の在庫不足のため予約を行うことができません',
  RENTAL_ITEM_RESERVATION_NOT_FOUND: '存在しない若しくはキャンセル済みの貸出品予約です',
  RENTAL_ITEM_RESERVATION_TOO_LATE: '貸出品のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  REQUESTED_DATE_IS_NOT_CONSECUTIVE: '延泊の宿泊日は連続した日付である必要があります',
  REQUESTED_DATES_EMPTY: '延泊の宿泊日を指定して下さい',
  REQUIRED_AT_LEAST_1_TARGET_PRICE_TYPE: '少なくとも一つ更新対象の料金を指定して下さい',
  REQUIRED_ONE_DAY_FOR_TICKET_BOOKING: 'チケットでの予約は1日の宿泊のみ可能です',
  REQUIRED_TICKET_ID: 'チケットIDを指定して下さい',
  RESTRICT_FACILITY_ID_CHECK_FAILED: '指定データを変更する権限がありません',
  ROOM_ALLOCATION_BOOKING_ROOM_FIXED: '部屋割りが既に固定されているため変更できません',
  ROOM_ALLOCATION_NO_CHANGE_ROOM_TYPE: '異なる部屋タイプの部屋を割り振ることはできません',
  ROOM_NOT_AVAILABLE_FOR_EXTENSION_DATES: '当該予約の部屋に空きがないため延泊することができません',
  ROOM_NOT_FOUND: '存在しない部屋です',
  ROOM_OVERBOOKING: '指定日程で空きのある部屋が存在しません',
  NO_AVAILABLE_ROOM: '本日程では部屋割りができないため予約を作成できません。1泊ずつのご予約をお試しください。',
  ROOM_TYPE_AND_TICKET_TYPE_NOT_FOUND: '指定のチケットで利用できない部屋タイプです',
  ROOM_TYPE_NOT_FOUND: '存在しない部屋タイプです',
  ROW_CSV_IMPORT_INVALID_EMAIL_FORMAT: '不正なメールアドレスが含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_COMPANY: '不正な個人法人区分が含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_GENDER: '不正な性別が含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_PRODUCT: '不正な希望商品が含まれています',
  ROW_CSV_IMPORT_INVALID_FILE_FORMAT: 'CSVのファイル形式が不正です',
  ROW_CSV_IMPORT_INVALID_FILE_FORMAT_POSTCODE: '不正な郵便番号が含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_CAMPAIGN: '不正な広告施策IDが含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_PURCHASE_RATE: '不正な購入評価IDが含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_STAFF_ID: '不正な社員IDが含まれています',
  SALE_ITEM_NOT_FOR_SALE: '現在販売登録されていない販売品です',
  SALE_ITEM_NOT_FOUND: '存在しない販売品です',
  SALE_ITEM_PRICE_OVERRIDE_NOT_ALLOWED: '価格変更が許可されていない販売品です',
  SALE_ITEM_UPDATE_NOT_UPDATABLE: '指定の販売品は編集はできません',
  SET_HOLIDAY_DATES_NOT_OF_YEAR: '異なる年度の日付が含まれています',
  SEVERAL_TYPES_OF_ROOM_NOT_FOUND: '指定された部屋タイプが不正です',
  SIGNUP_BONUS_APPLICATION_NOT_FOUND: '存在しない契約特典適用です',
  SIGNUP_BONUS_NOT_FOUND: '存在しない契約特典です',
  STAFF_FACILITY_OWNERSHIP_ACCESS_DENIED: '指定施設情報の参照権限がありません',
  STAFF_GROUP_NOT_FOUND: '存在しない社員グループです',
  STAFF_NOT_FOUND: '存在しない社員です',
  STAFF_ROLE_DUPLICATED_NAME: '同名の役割が既に登録されています',
  STAFF_ROLE_NOT_FOUND: '存在しない役割です',
  STAFF_ROLE_NOT_INCLUDED: 'ロールが未設定のためログインできません',
  STAFF_ROLE_PERMISSION_NOT_FOUND: '存在しない権限です',
  STAY_DATE_IS_IN_EXCEPTION_DATE: '指定のチケットの除外日が含まれています',
  SUB_MEMBER_NOT_FOUND: '存在しない付属会員です',
  SUB_MEMBER_TYPE_NOT_FOUND: '付属会員のタイプが不正です',
  SUBJECT_NOT_FOUND: '存在しない科目です',
  SUBMIT_CNV_FACILITY_REPORT_INVALID_BOOKING_ID: '日報の対象ではない予約が含まれます',
  SUGGEST_PIC_CLIENTS_CANDIDATESTAFFIDS_EMPTY: '担当者を少なくとも一人選択して下さい',
  SUGGEST_PIC_CLIENTS_INCREASE_MAX_CLIENTS: '担当数の上限を超えました。上限を増やして再度実行して下さい。',
  SUGGEST_PIC_CLIENTS_WEBREQUESTIDS_EMPTY: '担当者割り振り対象を指定して下さい',
  TARGET_ENTITY_ID_REQUIRED: 'ファイルの対象IDを指定する必要があります',
  THE_INVENTORY_TYPE_CANNOT_BE_CHANGED: '変更対象外の枠属性です',
  TICKET_HAS_BEEN_ALREADY_USED: '既に利用済みのチケットです',
  TICKET_IS_DISABLED: '無効化されたチケットです',
  TO_DATE_IS_NOT_IN_BOOKING_DATE_RANGE: '駐車場キャンセル待ちは宿泊日の範囲でのみ行えます',
  UNSUPPORTED_BOOKING_TYPE_SUPPLIED: '予約時備考の対象でない予約タイプです',
  UNSUPPORTED_CONTRACT_PRODUCT_TYPE_FOR_POINT_BOOKING: 'ポイント提携予約に利用できない契約です',
  UNSUPPORTED_MEDIA_TYPE: 'サポートされていない広告媒体です',
  UPDATE_BOOKING_GUESTS_INVALID_STAY_DATE: '宿泊期間外の宿泊日が指定されています',
  UPDATE_CONTRACT_POINT_DURATION_INVALID_DATES: '権利開始日は終了日の前である必要があります',
  UPDATE_PARKING_INVALID_DATE_RANGE: '不正な駐車場予約日付範囲です',
  UPDATE_STAFF_ROLE_NON_EXISTS_PERMISSION: '不正な権限設定が含まれています',
  UPLOAD_USER_GUIDE_DUPLICATED_YEAR: '既に同一年度で利用ガイドは登録済みです',
  UPLOAD_VACATION_LIFE_DUPLICATED_YEAR_MONTH: '既に同一年月でバケーションライフは登録済みです',
  USER_GUIDE_NOT_FOUND: '存在しない利用ガイドです',
  USER_IS_DISABLED: '指定のユーザは無効化されています',
  USER_NOT_FOUND: '存在しないユーザです',
  VACATION_LIFE_NOT_FOUND: '存在しないバケーションライフです',
  VICE_MEMBER_CANNOT_BE_CREATED_BY_CLIENT: '副会員の追加は現在受け付けておりません。コールセンターまでご連絡下さい',
  VM_CANCEL_STAY_RIGHT_ALREADY_REFUNDED: '既にポイント返還されたVM権利が含まれています',
  VM_CANCEL_STAY_RIGHT_ALREADY_REVOKED: '既に無効化されたVM権利が含まれています',
  VM_DELETE_TENTATIVE_BOOKINGS_NOT_TENTATIVE: '既に確定されたVM契約は削除できません',
  VS_APPLICATION_NOT_EXISTS: '存在しないバケーションスタイル入会申し込みです',
  VS_PROMO_CODE_EXISTS: '既に存在する特典コードです。再生成の上再度お試し下さい',
  VS_SIGNUP_CONFIRM_NON_DUPLICATION_CLIENT_HAS_VS: '指定のお客様は既にバケーションスタイル契約を保持しています',
  VS_SIGNUP_CONFIRM_NON_DUPLICATION_NOT_DUPLICATED: '指定の申込は重複と判断されていません',
  VS_SIGNUP_EMAIL_ALREADY_EXISTS: '既に利用されているメールアドレスのため利用できません',
  VS_SIGNUP_INSUFFICIENT_INPUT: '入力されていない必須項目があります。お確かめの上再度お試し下さい',
  // VS_SIGNUP_PROMO_ENDED: '終了した契約特典です',
  VS_SIGNUP_PROMO_ENDED: '指定の特典は終了しています',
  VS_SIGNUP_PROMO_NOT_FOUND: '存在しない特典コードです',
  WEB_NOTIFICATION_NOT_FOUND: '存在しないWeb通知です',
  WEB_REQUEST_CLIENT_EXISTS: '存在しない顧客です',
  WEB_REQUEST_CSV_PARSE_FAILED: '資料請求CSVの読み込みに失敗しました。',
  WEB_REQUEST_NOT_FOUND: '存在しないWeb申し込みです',
  BOOKING_TYPE_AND_PLAN_TARGET_MISMATCH: '指定の契約では使用できないプランです',
  INVALID_MFA_TOKEN: '無効なコードが入力されました。確認の上再度お試し下さい',
  STAFF_EMAIL_NOT_REGISTERED: 'ログインまたはパスワードが無効です',
  CANNOT_DELETE_BEING_REFERENCED: '既にシステムで使用されているデータのため削除できません',
  ACCOMMODATION_PRICE_CSV_IMPORT_INVALID_STAY_DATE: 'ファイル内に不正な日付が含まれています',
  REPORT_INTEGER_OUT_OF_RANGE: '異常データが存在するため桁溢れエラーが発生しました。',
  CREATE_BOOKING_FACILITY_ID_MISMTACH: '異なる施設の予約をグループに追加することはできません',
  PRE_PAYMENT_BOOKING_NEGATIVE_REMAINING: '入力したTVP数が利用指定TVP数を超えているため請求書を作成できません。 利用指定TVP数を変更いただくか、利用指定TVP数以下のTVP数を入力してください。',
  PRE_PAYMENT_BOOKING_EXCESS: '登録した利用指定TVP数がすべて請求書に反映されていません。 利用指定TVP数をすべて消費するようTVP数を入力してください。',
  BOOKING_PRE_PAYMENT_CANNOT_CHANGE_AFTER_CHECK_IN: 'すでにチェックインされているため利用ポイント数は変更することはできません。',
  PERMANENT_BLOCK_BOOKING_CAN_NOT_BE_MODIFIED: '無期限売り止め予約の変更はできません。',
  GMO_CHARGE_E01010001: 'E01010001: ショップIDが指定されていません。',
  GMO_CHARGE_E01010008: 'E01010008: ショップIDに半角英数字以外の文字が含まれているか、13文字を超えています。',
  GMO_CHARGE_E01010010: 'E01010010: ショップIDが一致しません。',
  GMO_CHARGE_E01020001: 'E01020001: ショップパスワードが指定されていません。',
  GMO_CHARGE_E01020008: 'E01020008: ショップパスワードに半角英数字以外の文字が含まれているか、10文字を超えています。',
  GMO_CHARGE_E01030002: 'E01030002: 指定されたIDとパスワードのショップが存在しません。',
  GMO_CHARGE_E01030061: 'E01030061: 強制返品はご利用できません。',
  GMO_CHARGE_E01040001: 'E01040001: オーダーIDが指定されていません。',
  GMO_CHARGE_E01040003: 'E01040003: オーダーIDが最大文字数を超えています。',
  GMO_CHARGE_E01040010: 'E01040010: すでにオーダーIDが存在しています。',
  GMO_CHARGE_E01040013: 'E01040013: オーダーIDに半角英数字と”－”以外の文字が含まれています。',
  GMO_CHARGE_E01050001: 'E01050001: 処理区分が指定されていません。',
  GMO_CHARGE_E01050002: 'E01050002: 指定された処理区分は定義されていません。',
  GMO_CHARGE_E01050004: 'E01050004: 指定した処理区分の処理は実行できません。',
  GMO_CHARGE_E01060001: 'E01060001: 利用金額が指定されていません。',
  GMO_CHARGE_E01060005: 'E01060005: 利用金額が最大桁数を超えています。',
  GMO_CHARGE_E01060006: 'E01060006: 利用金額に数字以外の文字が含まれています。',
  GMO_CHARGE_E01060010: 'E01060010: 取引の利用金額と指定した利用金額が一致していません。',
  GMO_CHARGE_E01060011: 'E01060011: 利用金額が有効な範囲を超えています。',
  GMO_CHARGE_E01060021: 'E01060021: 取引の利用金額と指定した利用金額が一致していません。',
  GMO_CHARGE_E01070005: 'E01070005: 税送料が最大桁数を超えています。',
  GMO_CHARGE_E01070006: 'E01070006: 税送料に数字以外の文字が含まれています。',
  GMO_CHARGE_E01080007: 'E01080007: 3Dセキュア使用フラグに0,1,2以外の値が指定されています。',
  GMO_CHARGE_E01080010: 'E01080010: 3Dセキュア利用しない契約ですが、3Dセキュア使用フラグ（TdFlag）が指定されています。',
  GMO_CHARGE_E01080101: 'E01080101: 3D必須店舗にも関わらず3Dセキュア使用フラグがOFFになっています。',
  GMO_CHARGE_E01090001: 'E01090001: 取引IDが指定されていません。',
  GMO_CHARGE_E01090008: 'E01090008: 取引IDの書式が正しくありません。',
  GMO_CHARGE_E01100001: 'E01100001: 取引パスワードが指定されていません。',
  GMO_CHARGE_E01100008: 'E01100008: 取引パスワードの書式が正しくありません。',
  GMO_CHARGE_E01110002: 'E01110002: 指定されたIDとパスワードの取引が存在しません。',
  GMO_CHARGE_E01110010: 'E01110010: 指定された取引は決済が完了していません。',
  GMO_CHARGE_E01130012: 'E01130012: カード会社略称が最大バイト数を超えています。',
  GMO_CHARGE_E01160001: 'E01160001: ボーナス分割回数が指定されていません。',
  GMO_CHARGE_E01160007: 'E01160007: ボーナス分割回数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01160010: 'E01160010: ボーナス分割回数に“２”以外を指定しています。',
  GMO_CHARGE_E01170001: 'E01170001: カード番号が指定されていません。',
  GMO_CHARGE_E01170003: 'E01170003: カード番号が最大文字数を超えています。',
  GMO_CHARGE_E01170006: 'E01170006: カード番号に数字以外の文字が含まれています。',
  GMO_CHARGE_E01170011: 'E01170011: カード番号が10桁～16桁の範囲ではありません。',
  GMO_CHARGE_E01180001: 'E01180001: 有効期限が指定されていません。',
  GMO_CHARGE_E01180003: 'E01180003: 有効期限が4桁ではありません。',
  GMO_CHARGE_E01180006: 'E01180006: 有効期限に数字以外の文字が含まれています。',
  GMO_CHARGE_E01180008: 'E01180008: 有効期限の書式が正しくありません。',
  GMO_CHARGE_E01180011: 'E01180011: 有効期限の書式が正しくありません。',
  GMO_CHARGE_E01190001: 'E01190001: サイトIDが指定されていません。',
  GMO_CHARGE_E01190008: 'E01190008: サイトIDの書式が正しくありません。',
  GMO_CHARGE_E01200001: 'E01200001: サイトパスワードが指定されていません。',
  GMO_CHARGE_E01200007: 'E01200007: サイトIDが正しくありません。',
  GMO_CHARGE_E01200008: 'E01200008: サイトパスワードの書式が正しくありません。',
  GMO_CHARGE_E01210002: 'E01210002: 指定されたIDとパスワードのサイトが存在しません。',
  GMO_CHARGE_E01220001: 'E01220001: 会員IDが指定されていません。',
  GMO_CHARGE_E01220005: 'E01220005: 会員IDが最大桁数を超えています。',
  GMO_CHARGE_E01220008: 'E01220008: 会員IDの書式が正しくありません。',
  GMO_CHARGE_E01220010: 'E01220010: 会員IDが重複しています。',
  GMO_CHARGE_E01220012: 'E01220012: 会員IDの長さが正しくありません。',
  GMO_CHARGE_E01230006: 'E01230006: カード登録連番に数字以外の文字が含まれています。',
  GMO_CHARGE_E01230009: 'E01230009: カード登録連番が最大登録可能数を超えています。',
  GMO_CHARGE_E01240002: 'E01240002: 指定されたカードが存在しません。',
  GMO_CHARGE_E01240012: 'E01240012: 指定された会員IDがファイル内で重複しています。',
  GMO_CHARGE_E01250008: 'E01250008: カードパスワードの書式が正しくありません。',
  GMO_CHARGE_E01250010: 'E01250010: カードパスワードが一致しません。',
  GMO_CHARGE_E01260001: 'E01260001: 支払方法が指定されていません。',
  GMO_CHARGE_E01260002: 'E01260002: 指定された支払方法が存在しません。',
  GMO_CHARGE_E01260010: 'E01260010: 指定されたカード番号または支払方法が正しくありません。',
  GMO_CHARGE_E01270001: 'E01270001: 支払回数が指定されていません。',
  GMO_CHARGE_E01270005: 'E01270005: 支払回数が最大桁数を超えています。',
  GMO_CHARGE_E01270006: 'E01270006: 支払回数の数字以外の文字が含まれています。',
  GMO_CHARGE_E01270010: 'E01270010: 指定された支払回数はご利用できません。',
  GMO_CHARGE_E01290001: 'E01290001: HTTP_ACCEPTが指定されていません。',
  GMO_CHARGE_E01300001: 'E01300001: HTTP_USER_AGENTが指定されていません。',
  GMO_CHARGE_E01310001: 'E01310001: 使用端末に”0”,”1”以外の値が指定されています。',
  GMO_CHARGE_E01320012: 'E01320012: 加盟店自由項目1の値が最大バイト数を超えています。',
  GMO_CHARGE_E01320013: 'E01320013: 加盟店自由項目1の値に利用できない文字が含まれています。',
  GMO_CHARGE_E01330012: 'E01330012: 加盟店自由項目2の値が最大バイト数を超えています。',
  GMO_CHARGE_E01330013: 'E01330013: 加盟店自由項目2の値に利用できない文字が含まれています。',
  GMO_CHARGE_E01340012: 'E01340012: 加盟店自由項目3の値が最大バイト数を超えています。',
  GMO_CHARGE_E01340013: 'E01340013: 加盟店自由項目3の値に利用できない文字が含まれています。',
  GMO_CHARGE_E01350001: 'E01350001: MDが指定されていません。',
  GMO_CHARGE_E01350008: 'E01350008: MDの書式が正しくありません。',
  GMO_CHARGE_E01360001: 'E01360001: PaResが指定されていません。',
  GMO_CHARGE_E01370008: 'E01370008: 3Dセキュア表示店舗名の書式が正しくありません。',
  GMO_CHARGE_E01370012: 'E01370012: 3Dセキュア表示店舗名の値が最大バイト数を超えています。',
  GMO_CHARGE_E01390002: 'E01390002: 指定されたサイトIDと会員IDの会員が存在しません。',
  GMO_CHARGE_E01390010: 'E01390010: 指定されたサイトIDと会員IDの会員がすでに存在しています。',
  GMO_CHARGE_E01400007: 'E01400007: 加盟店自由項目返却フラグに”0”,”1”以外の値が指定されています。',
  GMO_CHARGE_E01410010: 'E01410010: 該当取引は操作禁止状態です。',
  GMO_CHARGE_E01420010: 'E01420010: 仮売上有効期間を超えています。',
  GMO_CHARGE_E01430012: 'E01430012: 会員名の値が最大バイト数を超えています。',
  GMO_CHARGE_E01440008: 'E01440008: 洗替・継続課金フラグの書式が正しくありません。',
  GMO_CHARGE_E01450008: 'E01450008: 商品コードの書式が正しくありません。',
  GMO_CHARGE_E01460008: 'E01460008: セキュリティコードの書式が正しくありません。',
  GMO_CHARGE_E01470008: 'E01470008: カード登録連番モードの書式が正しくありません。',
  GMO_CHARGE_E01480008: 'E01480008: 名義人の書式が正しくありません。',
  GMO_CHARGE_E01480011: 'E01480011: 名義人の最大文字数を超えています。',
  GMO_CHARGE_E01490005: 'E01490005: 利用金額・税送料の合計値が有効な範囲を超えています。',
  GMO_CHARGE_E01500001: 'E01500001: ショップ情報文字列が設定されていません。',
  GMO_CHARGE_E01500005: 'E01500005: ショップ情報文字列の文字数が間違っています。',
  GMO_CHARGE_E01500012: 'E01500012: ショップ情報文字列が他の項目と矛盾しています。',
  GMO_CHARGE_E01510001: 'E01510001: 購買情報文字列が設定されていません。',
  GMO_CHARGE_E01510005: 'E01510005: 購買情報文字列の文字数が間違っています。',
  GMO_CHARGE_E01510010: 'E01510010: 利用日の書式が正しくありません。',
  GMO_CHARGE_E01510011: 'E01510011: 利用日の値が指定可能範囲外です。',
  GMO_CHARGE_E01510012: 'E01510012: 購買情報文字列が他の項目と矛盾しています。',
  GMO_CHARGE_E01520002: 'E01520002: ユーザー利用端末情報に無効な値が設定されています。',
  GMO_CHARGE_E01530001: 'E01530001: 決済結果戻り先URLが設定されていません。',
  GMO_CHARGE_E01530005: 'E01530005: 決済結果戻り先URLが最大文字数を超えています。',
  GMO_CHARGE_E01540005: 'E01540005: 決済キャンセル時URLが最大文字数を超えています。',
  GMO_CHARGE_E01550001: 'E01550001: 日時情報文字列が設定されていません。',
  GMO_CHARGE_E01550005: 'E01550005: 日時情報文字列の文字数が間違っています。',
  GMO_CHARGE_E01550006: 'E01550006: 日時情報文字列に無効な文字が含まれます。',
  GMO_CHARGE_E01590005: 'E01590005: 商品コードが最大桁数を超えています。',
  GMO_CHARGE_E01590006: 'E01590006: 商品コードに無効な文字が含まれます。',
  GMO_CHARGE_E01600001: 'E01600001: 会員情報チェック文字列が設定されていません。',
  GMO_CHARGE_E01600005: 'E01600005: 会員情報チェック文字列が最大文字数を超えています。',
  GMO_CHARGE_E01600012: 'E01600012: 会員情報チェック文字列が他の項目と矛盾しています。',
  GMO_CHARGE_E01610005: 'E01610005: リトライ回数が0～99の範囲外です。',
  GMO_CHARGE_E01610006: 'E01610006: リトライ回数に数字以外が設定されています。',
  GMO_CHARGE_E01620005: 'E01620005: セッションタイムアウト値が0～9999の範囲外です。',
  GMO_CHARGE_E01620006: 'E01620006: セッションタイムアウト値に数字以外が設定されています。',
  GMO_CHARGE_E01630010: 'E01630010: 取引後カード登録時、取引の会員IDとパラメータの会員IDが一致しません。',
  GMO_CHARGE_E01640010: 'E01640010: 取引後カード登録時、取引のサイトIDとパラメータのサイトIDが一致しません。',
  GMO_CHARGE_E01650012: 'E01650012: 指定されたショップは、指定されたサイトに属していません。',
  GMO_CHARGE_E01660013: 'E01660013: 言語パラメータにサポートされない値が設定されています。',
  GMO_CHARGE_E01670013: 'E01670013: 出力エンコーディングにサポートされない値が設定されています。',
  GMO_CHARGE_E01700001: 'E01700001: 項目数が誤っています。',
  GMO_CHARGE_E01710001: 'E01710001: 取引区分(継続課金)が設定されていません。',
  GMO_CHARGE_E01710002: 'E01710002: 指定された取引区分が存在しません。',
  GMO_CHARGE_E01730001: 'E01730001: ボーナス金額が指定されていません。',
  GMO_CHARGE_E01730005: 'E01730005: ボーナス金額が最大桁数を超えています。',
  GMO_CHARGE_E01730006: 'E01730006: 商品コードが”0000990”ではありません。',
  GMO_CHARGE_E01730007: 'E01730007: ボーナス金額に数字以外の文字が含まれています。',
  GMO_CHARGE_E01740001: 'E01740001: 端末処理通番が指定されていません。',
  GMO_CHARGE_E01740005: 'E01740005: 端末処理通番が最大桁数を超えています。',
  GMO_CHARGE_E01740007: 'E01740007: 端末処理通番に数字以外の文字が含まれています。',
  GMO_CHARGE_E01750001: 'E01750001: 利用日が指定されていません。',
  GMO_CHARGE_E01750008: 'E01750008: 利用日の書式が正しくありません。',
  GMO_CHARGE_E01770002: 'E01770002: 区分が不正です。',
  GMO_CHARGE_E01780002: 'E01780002: 有効性チェック有無が不正です。',
  GMO_CHARGE_E01790007: 'E01790007: チェック実施日が不正です。',
  GMO_CHARGE_E01790011: 'E01790011: チェック実施日が最大桁数を超えています。',
  GMO_CHARGE_E01800001: 'E01800001: 暗証番号が未入力です。',
  GMO_CHARGE_E01800008: 'E01800008: 暗証番号の書式が正しくありません。',
  GMO_CHARGE_E01800010: 'E01800010: 暗証番号は利用できません。',
  GMO_CHARGE_E01800050: 'E01800050: 暗証番号が不正です。(0000は使用できません)',
  GMO_CHARGE_E01810001: 'E01810001: 磁気ストライプ区分が指定されていません磁気ストライプ区分が不正です。',
  GMO_CHARGE_E01810008: 'E01810008: 磁気ストライプ区分に不正値が設定されています。磁気ストライプ区分が不正です。',
  GMO_CHARGE_E01820001: 'E01820001: 磁気ストライプ情報が指定されていません。磁気ストライプ情報が不正です。',
  GMO_CHARGE_E01820003: 'E01820003: 磁気ストライプ情報の文字数が正しくありません。磁気ストライプ情報が不正です。',
  GMO_CHARGE_E01820008: 'E01820008: 磁気ストライプ情報の書式が正しくありません。磁気ストライプ情報が不正です。',
  GMO_CHARGE_E01840010: 'E01840010: 入力パラメータの組み合わせが不正です。必要な入力パラメータが指定されていません。',
  GMO_CHARGE_E01850008: 'E01850008: 更新区分の書式が正しくありません。更新区分の書式が正しくありません。',
  GMO_CHARGE_E01860008: 'E01860008: しくありません。カード番号マスクフラグの書式が正しくありません。',
  GMO_CHARGE_E01870008: 'E01870008: トークンタイプの書式が正しくありません。トークンタイプの書式が正しくありません。',
  GMO_CHARGE_E01880001: 'E01880001: 登録済み会員IDが指定されていません。',
  GMO_CHARGE_E01880002: 'E01880002: 指定されたサイトIDと登録済み会員IDの会員が存在しません。',
  GMO_CHARGE_E01880008: 'E01880008: 登録済み会員IDの書式が正しくありません。',
  GMO_CHARGE_E01890001: 'E01890001: 登録済みカード登録連番が指定されていません。',
  GMO_CHARGE_E01890002: 'E01890002: 指定された登録済みカードが存在しません。',
  GMO_CHARGE_E01890006: 'E01890006: 登録済みカード登録連番に数字以外の文字が含まれています。',
  GMO_CHARGE_E01890009: 'E01890009: カード登録連番が最大登録可能数を超えています。',
  GMO_CHARGE_E01910008: 'E01910008: サイト設定のマスクレベル利用有無に”0”,”1”以外の値が指定されています。',
  GMO_CHARGE_E01950008: 'E01950008: 3DS2.0未対応時取り扱いの書式が正しくありません。',
  GMO_CHARGE_E01960008: 'E01960008: カード会員最終更新日の書式が正しくありません。',
  GMO_CHARGE_E01970008: 'E01970008: カード会員作成日の書式が正しくありません。',
  GMO_CHARGE_E01980008: 'E01980008: カード会員パスワード変更日の書式が正しくありません。',
  GMO_CHARGE_E01990005: 'E01990005: 過去6ヶ月間の購入回数が最大桁数を超えています。',
  GMO_CHARGE_E01990006: 'E01990006: 過去6ヶ月間の購入回数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01999998: 'E01999998: 項目1「フォーマットバージョン」に"001"が指定されていません。',
  GMO_CHARGE_E01A00008: 'E01A00008: カード登録日の書式が正しくありません。',
  GMO_CHARGE_E01A10005: 'E01A10005: 過去24時間のカード追加の試行回数が最大桁数を超えています。',
  GMO_CHARGE_E01A10006: 'E01A10006: 過去24時間のカード追加の試行回数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01A20008: 'E01A20008: 配送先住所の初回使用日の書式が正しくありません。',
  GMO_CHARGE_E01A30008: 'E01A30008: カード会員名と配送先名の一致/不一致の書式が正しくありません。',
  GMO_CHARGE_E01A40008: 'E01A40008: カード会員の不審行為情報の書式が正しくありません。',
  GMO_CHARGE_E01A50005: 'E01A50005: 過去24時間の取引回数が最大桁数を超えています。',
  GMO_CHARGE_E01A50006: 'E01A50006: 過去24時間の取引回数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01A60005: 'E01A60005: 前年の取引回数が最大桁数を超えています。',
  GMO_CHARGE_E01A60006: 'E01A60006: 前年の取引回数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01A70012: 'E01A70012: ログイン証跡が最大バイト数を超えています。',
  GMO_CHARGE_E01A80008: 'E01A80008: ログイン方法の書式が正しくありません。',
  GMO_CHARGE_E01A90008: 'E01A90008: ログイン日時の書式が正しくありません。',
  GMO_CHARGE_E01B00008: 'E01B00008: 請求先住所と配送先住所の一致/不一致の書式が正しくありません。',
  GMO_CHARGE_E01B10005: 'E01B10005: 請求先住所の都市が最大桁数を超えています。',
  GMO_CHARGE_E01B20002: 'E01B20002: 請求先住所の国番号が存在しません。',
  GMO_CHARGE_E01B20005: 'E01B20005: 請求先住所の国番号が3桁ではありません。',
  GMO_CHARGE_E01B30005: 'E01B30005: 請求先住所の区域部分の１行目が最大桁数を超えています。',
  GMO_CHARGE_E01B40005: 'E01B40005: 請求先住所の区域部分の２行目が最大桁数を超えています。',
  GMO_CHARGE_E01B50005: 'E01B50005: 請求先住所の区域部分の３行目が最大桁数を超えています。',
  GMO_CHARGE_E01B60005: 'E01B60005: 請求先住所の郵便番号が最大桁数を超えています。',
  GMO_CHARGE_E01B70005: 'E01B70005: 請求先住所の州または都道府県番号が最大桁数を超えています。',
  GMO_CHARGE_E01B70010: 'E01B70010: 請求先住所の州または都道府県番号を指定する場合は請求先住所の国番号を省略できません。',
  GMO_CHARGE_E01B80005: 'E01B80005: カード会員のメールアドレスが最大桁数を超えています。',
  GMO_CHARGE_E01B80008: 'E01B80008: カード会員のメールアドレスの書式が正しくありません。',
  GMO_CHARGE_E01B90005: 'E01B90005: 自宅電話の国コードが最大桁数を超えています。',
  GMO_CHARGE_E01C00005: 'E01C00005: 自宅電話番号が最大桁数を超えています。',
  GMO_CHARGE_E01C00006: 'E01C00006: 自宅電話番号に数字以外の文字が含まれています。',
  GMO_CHARGE_E01C10005: 'E01C10005: 携帯電話の国コードが最大桁数を超えています。',
  GMO_CHARGE_E01C20005: 'E01C20005: 携帯電話番号が最大桁数を超えています。',
  GMO_CHARGE_E01C20006: 'E01C20006: 携帯電話番号に数字以外の文字が含まれています。',
  GMO_CHARGE_E01C30005: 'E01C30005: 職場電話の国コードが最大桁数を超えています。',
  GMO_CHARGE_E01C40005: 'E01C40005: 職場電話番号が最大桁数を超えています。',
  GMO_CHARGE_E01C40006: 'E01C40006: 職場電話番号に数字以外の文字が含まれています。',
  GMO_CHARGE_E01C50005: 'E01C50005: 配送先住所の都市が最大桁数を超えています。',
  GMO_CHARGE_E01C60002: 'E01C60002: 配送先住所の国番号が存在しません。',
  GMO_CHARGE_E01C60005: 'E01C60005: 配送先住所の国番号が3桁ではありません。',
  GMO_CHARGE_E01C70005: 'E01C70005: 配送先住所の区域部分の１行目が最大桁数を超えています。',
  GMO_CHARGE_E01C80005: 'E01C80005: 配送先住所の区域部分の２行目が最大桁数を超えています。',
  GMO_CHARGE_E01C90005: 'E01C90005: 配送先住所の区域部分の３行目が最大桁数を超えています。',
  GMO_CHARGE_E01D00005: 'E01D00005: 配送先住所の郵便番号が最大桁数を超えています。',
  GMO_CHARGE_E01D10005: 'E01D10005: 配送先住所の州または都道府県番号が最大桁数を超えています。',
  GMO_CHARGE_E01D10010: 'E01D10010: 配送先住所の州または都道府県番号を指定する場合は配送先住所の国番号を省略できません。',
  GMO_CHARGE_E01D20005: 'E01D20005: 納品先電子メールアドレスが最大桁数を超えています。',
  GMO_CHARGE_E01D20008: 'E01D20008: 納品先電子メールアドレスの書式が正しくありません。',
  GMO_CHARGE_E01D30008: 'E01D30008: 商品納品時間枠の書式が正しくありません。',
  GMO_CHARGE_E01D40005: 'E01D40005: プリペイドカードまたはギフトカードの総購入金額が最大桁数を超えています。',
  GMO_CHARGE_E01D40006: 'E01D40006: プリペイドカードまたはギフトカードの総購入金額に数字以外の文字が含まれています。',
  GMO_CHARGE_E01D50005: 'E01D50005: 購入されたプリペイドカードまたはギフトカード の総数が最大桁数を超えています。',
  GMO_CHARGE_E01D50006: 'E01D50006: 購入されたプリペイドカードまたはギフトカード の総数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01D60005: 'E01D60005: 購入されたプリペイドカードまたはギフトカードの通貨コードが3桁ではありません。',
  GMO_CHARGE_E01D70008: 'E01D70008: 商品の発売予定日の書式が正しくありません。',
  GMO_CHARGE_E01D80008: 'E01D80008: 商品の販売時期情報の書式が正しくありません。',
  GMO_CHARGE_E01D90008: 'E01D90008: 商品の注文情報の書式が正しくありません。',
  GMO_CHARGE_E01E00008: 'E01E00008: 取引の出荷方法の書式が正しくありません。',
  GMO_CHARGE_E01E10008: 'E01E10008: 継続課金の期限の書式が正しくありません。',
  GMO_CHARGE_E01E20005: 'E01E20005: 継続課金の課金最小間隔日数が最大桁数を超えています。',
  GMO_CHARGE_E01E20006: 'E01E20006: 継続課金の課金最小間隔日数に数字以外の文字が含まれています。',
  GMO_CHARGE_E01E30001: 'E01E30001: 加盟店戻りURLが指定されていません。',
  GMO_CHARGE_E01E30005: 'E01E30005: 加盟店戻りURLが最大桁数を超えています。',
  GMO_CHARGE_E01E30008: 'E01E30008: 加盟店戻りURLの書式が正しくありません。',
  GMO_CHARGE_E01E50001: 'E01E50001: 認証状態が指定されていません。',
  GMO_CHARGE_E01E50008: 'E01E50008: 認証状態の書式が正しくありません。',
  GMO_CHARGE_E01E60001: 'E01E60001: カード会員のログイン情報の指定が正しくありません。ログイン証跡/ログイン方法/ログイン日時のいずれかの省略はできません。',
  GMO_CHARGE_E01E70001: 'E01E70001: 自宅電話番号の指定が正しくありません。自宅電話の国コード/自宅電話番号のいずれかの省略はできません。',
  GMO_CHARGE_E01E80001: 'E01E80001: 携帯電話番号の指定が正しくありません。携帯電話の国コード/携帯電話番号のいずれかの省略はできません。',
  GMO_CHARGE_E01E90001: 'E01E90001: 職場電話番号の指定が正しくありません。職場電話の国コード/職場電話番号のいずれかの省略はできません。',
  GMO_CHARGE_E01EA0007: 'E01EA0007: コールバック方法に1,2以外の値が指定されています。',
  GMO_CHARGE_E01EA0010: 'E01EA0010: コールバック方法に1,2以外の値が指定されています。',
  GMO_CHARGE_E01EB0001: 'E01EB0001: 3DS2.0認証パラメータが指定されていません。',
  GMO_CHARGE_E01EB0005: 'E01EB0005: 3DS2.0認証パラメータが最大桁数を超えています。',
  GMO_CHARGE_E01EC0002: 'E01EC0002: 指定された3DS SDK インターフェースは定義されていません。',
  GMO_CHARGE_E01ED0002: 'E01ED0002: 指定された3DS SDK UIタイプは定義されていません。',
  GMO_CHARGE_E01EE0001: 'E01EE0001: アプリIDが指定されていません。',
  GMO_CHARGE_E01EE0008: 'E01EE0008: アプリIDの書式が正しくありません。',
  GMO_CHARGE_E01EF0001: 'E01EF0001: 3DS2.0暗号化データが指定されていません。',
  GMO_CHARGE_E01EF0005: 'E01EF0005: 3DS2.0暗号化データが最大桁数を超えています。',
  GMO_CHARGE_E01EG0002: 'E01EG0002: 指定された3DS2.0 JWS アルゴリズムは定義されていません。',
  GMO_CHARGE_E01EH0002: 'E01EH0002: 指定された3DS2.0 JWS オペレーション（Sign）は定義されていません。',
  GMO_CHARGE_E01EI0002: 'E01EI0002: 指定された3DS2.0 JWS オペレーション（Verify）は定義されていません。',
  GMO_CHARGE_E01EJ0002: 'E01EJ0002: 指定された3DS2.0 JWS オペレーション（Encrypt）は定義されていません。',
  GMO_CHARGE_E01EK0002: 'E01EK0002: 指定された3DS2.0 JWS オペレーション（Decrypt）は定義されていません。',
  GMO_CHARGE_E01EL0002: 'E01EL0002: 指定された3DS2.0 JWS オペレーション（WrapKey）は定義されていません。',
  GMO_CHARGE_E01EM0002: 'E01EM0002: 指定された3DS2.0 JWS オペレーション（UnwrapKey）は定義されていません。',
  GMO_CHARGE_E01EN0002: 'E01EN0002: 指定された3DS2.0 JWS オペレーション（DeriveKey）は定義されていません。',
  GMO_CHARGE_E01EO0002: 'E01EO0002: 指定された3DS2.0 JWS オペレーション（DeriveBits）は定義されていません。',
  GMO_CHARGE_E01EP0005: 'E01EP0005: 3DS2.0 JWS キーIDが最大桁数を超えています。',
  GMO_CHARGE_E01EQ0001: 'E01EQ0001: 3DS2.0 JWS キータイプが指定されていません。',
  GMO_CHARGE_E01EQ0002: 'E01EQ0002: 3DS2.0 JWS キータイプは定義されていません。',
  GMO_CHARGE_E01ER0005: 'E01ER0005: 3DS2.0 JWS 用途が最大桁数を超えています。',
  GMO_CHARGE_E01ES0005: 'E01ES0005: 3DS2.0 JWS 証明書チェーンが最大桁数を超えています。',
  GMO_CHARGE_E01ET0005: 'E01ET0005: 3DS2.0 JWS 証明書が最大桁数を超えています。',
  GMO_CHARGE_E01EU0005: 'E01EU0005: 3DS2.0 JWS 証明書チェーンURLが最大桁数を超えています。',
  GMO_CHARGE_E01EV0001: 'E01EV0001: 最大タイムアウトが指定されていません。',
  GMO_CHARGE_E01EV0008: 'E01EV0008: 最大タイムアウトの書式が正しくありません。',
  GMO_CHARGE_E01EV0006: 'E01EV0006: 最大タイムアウトに数字以外の文字が含まれています。',
  GMO_CHARGE_E01EW0001: 'E01EW0001: リファレンス番号が指定されていません。',
  GMO_CHARGE_E01EW0005: 'E01EW0005: リファレンス番号が最大桁数を超えています。',
  GMO_CHARGE_E01EX0001: 'E01EX0001: SDK取引IDが指定されていません。',
  GMO_CHARGE_E01EX0008: 'E01EX0008: SDK取引IDの書式が正しくありません。',
  GMO_CHARGE_E01EY0007: 'E01EY0007: モバイルアプリモードの指定が正しくありません。',
  GMO_CHARGE_E11010001: 'E11010001: 決済を中止して、取引ができない事を通知してください。この取引はすでに決済が終了しています。',
  GMO_CHARGE_E11010002: 'E11010002: この取引は決済が終了していませんので、変更する事ができません。',
  GMO_CHARGE_E11010003: 'E11010003: この取引は指定処理区分処理を行う事ができません。',
  GMO_CHARGE_E11010010: 'E11010010: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010011: 'E11010011: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010012: 'E11010012: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010013: 'E11010013: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010014: 'E11010014: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010015: 'E11010015: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010016: 'E11010016: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010017: 'E11010017: 180日超えの取引のため、処理を行う事ができません。',
  GMO_CHARGE_E11010099: 'E11010099: このカードはご利用になれません。',
  GMO_CHARGE_E11010100: 'E11010100: このカードはご利用になれません。',
  GMO_CHARGE_E11010999: 'E11010999: －',
  GMO_CHARGE_E11310001: 'E11310001: この取引はリンク決済を実行できません。',
  GMO_CHARGE_E11310002: 'E11310002: この取引はリンク決済を実行できません。',
  GMO_CHARGE_E11310003: 'E11310003: この取引はリンク決済を実行できません。',
  GMO_CHARGE_E11310004: 'E11310004: この取引はリンク決済を実行できません。',
  GMO_CHARGE_E11310005: 'E11310005: すでにカードを登録している会員は、取引後カード登録を実行できません。',
  GMO_CHARGE_E21010001: 'E21010001: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21010007: 'E21010007: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21010999: 'E21010999: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21020001: 'E21020001: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21020002: 'E21020002: 3Dセキュア認証がキャンセルされました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21020007: 'E21020007: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21020999: 'E21020999: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21010201: 'E21010201: このカードでは取引をする事ができません。3Dセキュア認証に対応したカードをお使いください。',
  GMO_CHARGE_E21010202: 'E21010202: このカードでは取引をする事ができません。3Dセキュア認証に対応したカードをお使いください。',
  GMO_CHARGE_E21030001: 'E21030001: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21030007: 'E21030007: 3Dセキュア認証に失敗しました。もう一度、購入画面からやり直してください。',
  GMO_CHARGE_E21030201: 'E21030201: このカードでは取引をする事ができません。3Dセキュア認証に対応したカードをお使いください。',
  GMO_CHARGE_E21030202: 'E21030202: このカードでは取引をする事ができません。3Dセキュア認証に対応したカードをお使いください。',
  GMO_CHARGE_E31500014: 'E31500014: －',
  GMO_CHARGE_E41170002: 'E41170002: 入力されたカード会社に対応していません。別のカード番号を入力してください。',
  GMO_CHARGE_E41170099: 'E41170099: カード番号に誤りがあります。再度確認して入力してください。',
  GMO_CHARGE_E61010001: 'E61010001: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E61010002: 'E61010002: ご利用出来ないカードをご利用になったもしくはカード番号が誤っております。',
  GMO_CHARGE_E61010003: 'E61010003: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E61020001: 'E61020001: 指定の決済方法は利用停止になっています。',
  GMO_CHARGE_E61030001: 'E61030001: ご契約内容エラー/現在のご契約では、ご利用になれません。',
  GMO_CHARGE_E61040001: 'E61040001: 現在のご契約では、カード番号を指定した決済処理は許可されていません。',
  GMO_CHARGE_E82010001: 'E82010001: 実行中にエラーが発生しました。処理は開始されませんでした。',
  GMO_CHARGE_E90010001: 'E90010001: 現在処理を行っているのでもうしばらくお待ちください。',
  GMO_CHARGE_E91099996: 'E91099996: システムの内部エラーです。発生時刻や呼び出しパラメータをご確認のうえ、お問い合わせください。',
  GMO_CHARGE_E91099997: 'E91099997: リクエストされたAPIは存在しません。URLをお確かめください。',
  GMO_CHARGE_E91019999: 'E91019999: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E91020001: 'E91020001: 通信タイムアウトが発生しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E91029998: 'E91029998: 決済処理に失敗しました。該当のお取引について、店舗までお問い合わせください。',
  GMO_CHARGE_E91029999: 'E91029999: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E91050001: 'E91050001: 決済処理に失敗しました。',
  GMO_CHARGE_E91060001: 'E91060001: システムの内部エラーです。発生時刻や呼び出しパラメータをご確認のうえ、お問い合わせください。',
  GMO_CHARGE_E91099999: 'E91099999: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_E92000001: 'E92000001: 只今、大変込み合っていますので、しばらく時間をあけて再度決済を行ってください。',
  GMO_CHARGE_E92000002: 'E92000002: 只今、大変込み合っていますので、しばらく時間をあけて再度決済を行ってください。',
  GMO_CHARGE_EX1000301: 'EX1000301: 決済処理に失敗しました。もう一度カード番号を入力してください。',
  GMO_CHARGE_EX1000302: 'EX1000302: 決済処理に失敗しました。もう一度カード番号を入力してください。',
  GMO_CHARGE_EX1000303: 'EX1000303: 決済処理に失敗しました。もう一度カード番号を入力してください。',
  GMO_CHARGE_EX1000304: 'EX1000304: 決済処理に失敗しました。もう一度カード番号を入力してください。',
  GMO_CHARGE_42C010000: '42C010000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C030000: '42C030000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C120000: '42C120000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C130000: '42C130000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C140000: '42C140000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C150000: '42C150000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C330000: '42C330000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C500000: '42C500000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C510000: '42C510000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C530000: '42C530000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C540000: '42C540000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C550000: '42C550000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C560000: '42C560000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C570000: '42C570000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C580000: '42C580000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C600000: '42C600000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C700000: '42C700000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C710000: '42C710000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C720000: '42C720000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C730000: '42C730000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C740000: '42C740000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C750000: '42C750000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C760000: '42C760000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C770000: '42C770000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42C780000: '42C780000: 決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。',
  GMO_CHARGE_42G020000: '42G020000: カード残高が不足しているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G030000: '42G030000: カード限度額を超えているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G040000: '42G040000: カード残高が不足しているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G050000: '42G050000: カード限度額を超えているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G060000: '42G060000: デビットカードで口座の残高が不足しています。',
  GMO_CHARGE_42G070000: '42G070000: カード限度額を超えているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G120000: '42G120000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G220000: '42G220000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G300000: '42G300000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G420000: '42G420000: 暗証番号が誤っていた為に、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G440000: '42G440000: セキュリティーコードが誤っていた為に、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G450000: '42G450000: セキュリティーコードが入力されていない為に、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G540000: '42G540000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G550000: '42G550000: カード限度額を超えているために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G560000: '42G560000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G600000: '42G600000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G610000: '42G610000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G650000: '42G650000: カード番号に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G670000: '42G670000: 商品コードに誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G680000: '42G680000: 金額に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G690000: '42G690000: 税送料に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G700000: '42G700000: ボーナス回数に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G710000: '42G710000: ボーナス月に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G720000: '42G720000: ボーナス額に誤りがあるために、決済を完了することができませんでした。',
  GMO_CHARGE_42G730000: '42G730000: 支払開始月に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G740000: '42G740000: 分割回数に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G750000: '42G750000: 分割金額に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G760000: '42G760000: 初回金額に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G770000: '42G770000: 業務区分に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G780000: '42G780000: 支払区分に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G790000: '42G790000: 照会区分に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G800000: '42G800000: 取消区分に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G810000: '42G810000: 取消取扱区分に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G830000: '42G830000: 有効期限に誤りがあるために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G910000: '42G910000: システム障害のために、決済を完了する事ができませんでした。',
  GMO_CHARGE_42G920000: '42G920000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G950000: '42G950000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G960000: '42G960000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G970000: '42G970000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G980000: '42G980000: このカードでは取引をする事ができません。',
  GMO_CHARGE_42G990000: '42G990000: このカードでは取引をする事ができません。',
  'Network error: Response not successful: Received status code 400': '入力内容に誤りがあります。'
}
