import actions from './actions'

const reversion = {
  state: {
    reversion: {},
    createBooking: {},
    bookingTypeList: [],
    bookingGrougId: null,
    status: false

  },

  getters: {
    getReversion (state) {
      return state.reversion
    }
  },

  mutations: {
    setReversion (state, payload) {
      state.reversion = payload.reversion
    },

    setCreateBooking (state, payload) {
      state.createBooking = payload.createBooking
    },

    setBookingType (state, payload) {
      state.bookingTypeList = payload.bookingTypesList
    }

  },
  actions
}

export default reversion
