import gql from 'graphql-tag'

export const CALCULATE_BOOKING_CANCEL_PENALTY = gql`
query($bookingId: Int!, $stayDates:[DateString!] = [], $cancelDate: DateString, $noShow: Boolean) {
    calculateBookingCancelPenalty(
      bookingId: $bookingId
      stayDates: $stayDates
      cancelDate: $cancelDate
      noShow: $noShow
    ) {
      penalties {
        stayDate 
        price 
        spPoint 
        fpPoint 
        tvpPoint
        penaltyRate 
        penaltyPrice 
        penaltySpPoint
        penaltyFpPoint 
        penaltyTvpPoint
      }
    }
  }
`

export const CANCEL_BOOKING = `
mutation ($penaltyRates: [CancelPenaltyRateInput!]!, $bookingId: Int!) {
    cancelBooking(
      bookingId: $bookingId
      penaltyRates: $penaltyRates
    ) {
      __typename
      needsTvpAdjustment
    }
  }
`

export const UPLOAD_PASSPORT_URL = gql`
mutation($contentLength: Int!, $contentType: String!) {
  generateUploadUrl(
    type: "booking/passport"
    contentType: $contentType
    contentLength: $contentLength
  ) {
    uploadUrl
    fileUrl
    key
  }
}
`
