import gql from 'graphql-tag'

export const ACCOMMODATION_PRICE_IMPORT_LIST = gql`
  query($take: Int!, $skip: Int!) {
    accommodationPriceImportList(pagination: { take: $take, skip: $skip }) {
      total
      items {
        id
        status 
        scheduledAt
        authorizedBy 
        remarks 
        createdAt
      }
    }
  }
`
