import actions from './actions'

const facilityHotelTaxAmount = {
  state: {

  },
  mutations: {

  },
  actions
}

export default facilityHotelTaxAmount
