import gql from 'graphql-tag'

export const BOOKING = gql`
query ($id: Int!) {
    booking(id: $id) {
      id
      cancelledAt
      bookingGroupId
      bookingTypeId 
      bookingType { id name code }
      computed {
        status
      }
      blockRoom 
      bookingPlan { title }
      roomFixed 
      permanentBlock
      bookingBarNote 
      
      checkInDate
      checkOutDate
      checkedInAt
      petInfo
      hasAgreedPetPolicy
      room {
        id name 
        roomType {
          id name acceptsPet
          facility {
            id name 
            floorPlanPdfUrl
          }      
        }    
      }
      
      bookingGroup {
        id 
        createdClient {name} 
        createdSubMember {
          name
        }
        otaBookingId 
        otaName
        
        bookings {
          checkInDate
          checkOutDate
          id 
          computed{
            status 
          }
          room {
            name
            roomType {
              name 
              facility {
                id name 
              }  
            }
          }
          representative {
            name 
          }      }    }
      
      contract {
        id
        productType {
          id
          name
        }
        client {
          type
          id
          name
          isCompany
        }
      }    
      salesChannel
      
      toothbrush
      yukata 
      bookingCertificateUrl
      mailBookingCertificate
      mailFacilityGuide 
      mailPointCertificate 
      mailAds 
      remarks 
      representative {
        clientId
        subMemberId
        name
        kana 
        tel 
        email
        address1 
        sex
        age
        occupation
        nationality 
        passportPhoto 
        passportPhotoUrl
        passportNumber 
      }
    }
  }
`

export const ROOM_LIST = gql`
query ($roomTypeId: Int!) {
  roomList(roomTypeId: $roomTypeId) {
    name
    id
  }
}
`

export const BOOKING_TYPES_LIST = `
query {
  bookingTypesList {
    id
    code
    name
  }
}
`

export const BOOKING_BASIC_UPDATE = `
mutation ($basicUpdateInput: BasicUpdateBookingInput!){
  bookingBasicUpdate(basicUpdateInput:$basicUpdateInput) {
    __typename
  }
}
`
export const GET_PRE_PAYMENT_INFO = `
query getPrePaymentInfo($bookingId: Int!) {
  getPrePaymentInfo(bookingId: $bookingId) {
    prepaymentAmount
    targetCharges
    remainingPoint
    remainigCharges
    currentTvp
  }
}
`

export const BOOKING_PRE_PAYMENT_UPDATE = `
mutation ($bookingId: Int!, $prePayment: Int!){
  bookingBasicUpdate (basicUpdateInput: { bookingId: $bookingId, bookingPrePayment: { amount: $prePayment }}) {
    id
  }
}
`

export const BOOKING_SUMMARY = `query getBookingSummary($id: Int!) {
  bookingSummary (id: $id) {
    booking {
      id
      checkInDate
      checkOutDate
      createdAt
      cancelledAt
      petInfo
      hasAgreedPetPolicy
      remarks
      bookingTicketId
      bookingTypeId
      bookingType { id code }
      room {
        name
        roomType {
          name
          facility {
            name
          }
        }
      }
      guests {
        id child bedShared
      }
      guestSchedules { 
        guestId
        stayDate
        stay
      }
      client {
        id
        name
        memberId
        nameKana
      }
      contract {
        id
        productTypeId
      }
      representative {
        name kana tel
      }
      charges {
        stayDate
        subject
        spPoint
        fpPoint
        tvpPoint
        totalPrice
        type {
          name
        }
      }
      rentalItems { fromDate toDate }
      parkings { fromDate toDate }
    }
    bookingGroup {
      registerdCCSubjects
      onsiteSubjects
      defaultPaymentType
    }
    otherBookingIds
    chageHistories {
      id
      fieldName
      valueBefore
      valueAfter
      changedByName
      changedAt
    }
    prepaymentAmount
  }
}`
