export const FACILITY_BOOKING_REMARKS_LIST = `query ($facilityId: Int!){
  facilityBookingRemarksList (facilityId: $facilityId) {
    bookingType {id code}
    remarks
  }
}`

export const UPDATE_BOOKING_REMARK = `mutation ($facilityId: Int!, $data: CreateFacilityBookingRemarksInput! ){
  createFacilityBookingRemarks(facilityId: $facilityId, data: [$data]) {
    bookingTypeId
    remarks
  }
}`
