import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/pageNotFound'

import dashboardRouter from './modules/dashboard'
import store from '../store'
import { checkRouterPrior, checkRouterPathRoot, checkRedirect403 } from '@/utils/permissions'

import {
  GUEST_ROOM_DAILY_REPORT_PATH,
  GUEST_ROOM_DAILY_REPORT_PATH_SUB,
  GUEST_ROOM_DAILY_REPORT_ROUTER_SUB,
  CALENDAR_PATH,
  CALENDAR_PATH_SUB,
  CALENDAR_ROUTER_SUB,
  RESERVATION_PATH,
  RESERVATION_PATH_SUB,
  RESERVATION_ROUTER_SUB,
  FEES_POINTS_PLANS_PATH,
  FEES_POINTS_PLANS_PATH_SUB,
  FEES_POINTS_PLANS_ROUTER_SUB,
  INVITATIONS_AND_COUPONS_PATH,
  INVITATIONS_AND_COUPONS_PATH_SUB,
  INVITATIONS_AND_COUPONS_ROUTER_SUB,
  FORM_REPORT_PATH,
  FORM_REPORT_PATH_SUB,
  FORM_REPORT_ROUTER_SUB,
  FRAME_SETTING_PATH,
  FRAME_SETTING_PATH_SUB,
  FRAME_SETTING_ROUTER_SUB,
  FACILITY_PATH,
  FACILITY_PATH_SUB,
  FACILITY_ROUTER_SUB,
  USER_PATH,
  USER_PATH_SUB,
  USER_ROUTER_SUB
} from '@/constants/enum'

Vue.use(VueRouter)

const routes = [
  dashboardRouter,
  {
    path: '/sign-in',
    name: 'LoginPMS',
    component: () => import('../views/auth/signin'),
    beforeEnter (to, from, next) {
      if (store.getters.token) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/notpage',
    component: PageNotFound,
    name: 'NotPage',
    hidden: true,
    beforeEnter (to, from, next) {
      if (!localStorage.getItem('token')) {
        next('/sign-in')
      } else {
        next()
      }
    }
  },
  {
    path: '/change-password',
    component: () => import('@/views/auth/changePassword'),
    beforeEnter: (to, from, next) => {
      if (store.state.user.emailSave) next()
      else next({ path: 'sign-in' })
    }
  },
  {
    path: '/confirm-mfa-staff',
    name: 'confirmMfaStaff',
    component: () => import('@/views/auth/confirmMFAStaff'),
    beforeEnter: (to, from, next) => {
      if (store.state.user.emailSave) next()
      else next({ path: 'sign-in' })
    }
  },
  {
    path: '/reset-password-staff',
    name: 'reset-password-staff',
    component: () => import('@/views/auth/resetPasswordStaff')
  },
  // {
  //   path: '/reset-password',
  //   component: () => import('../views/auth/forgotPassword'),
  //   beforeEnter (to, from, next) {
  //     if (store.getters.token) {
  //       next('/')
  //     } else {
  //       next()
  //     }
  //   }
  // },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// check redirect role,permission
router.beforeEach((to, from, next) => {
  if (!hasAccess(to)) {
    next({ name: 'NotPage' })
  }

  let routerPathRoot = ''
  let countPath = 0
  // begin check redirect route path root: "/" with permission, role
  if (to.path === '/') {
    countPath = checkRedirect403()
    if (countPath === 0) {
      // rediect page 403
      return next({ name: 'NotPage', query: { show: true } })
    } else {
      routerPathRoot = checkRouterPathRoot()
      if (routerPathRoot) {
        return next(routerPathRoot)
      }
    }
  }
  // end check redirect route path root: "/" with permission, role

  // begin check router sub prior with permission, role
  let routerCurrent = ''
  if (to.path === GUEST_ROOM_DAILY_REPORT_PATH || to.path === GUEST_ROOM_DAILY_REPORT_PATH_SUB) {
    routerCurrent = checkRouterPrior(GUEST_ROOM_DAILY_REPORT_PATH, GUEST_ROOM_DAILY_REPORT_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === CALENDAR_PATH || to.path === CALENDAR_PATH_SUB) {
    routerCurrent = checkRouterPrior(CALENDAR_PATH, CALENDAR_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === RESERVATION_PATH || to.path === RESERVATION_PATH_SUB) {
    routerCurrent = checkRouterPrior(RESERVATION_PATH, RESERVATION_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === FEES_POINTS_PLANS_PATH || to.path === FEES_POINTS_PLANS_PATH_SUB) {
    routerCurrent = checkRouterPrior(FEES_POINTS_PLANS_PATH, FEES_POINTS_PLANS_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === INVITATIONS_AND_COUPONS_PATH || to.path === INVITATIONS_AND_COUPONS_PATH_SUB) {
    routerCurrent = checkRouterPrior(INVITATIONS_AND_COUPONS_PATH, INVITATIONS_AND_COUPONS_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === FRAME_SETTING_PATH || to.path === FRAME_SETTING_PATH_SUB) {
    routerCurrent = checkRouterPrior(FRAME_SETTING_PATH, FRAME_SETTING_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === FORM_REPORT_PATH || to.path === FORM_REPORT_PATH_SUB) {
    routerCurrent = checkRouterPrior(FORM_REPORT_PATH, FORM_REPORT_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === FACILITY_PATH || to.path === FACILITY_PATH_SUB) {
    routerCurrent = checkRouterPrior(FACILITY_PATH, FACILITY_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  if (to.path === USER_PATH || to.path === USER_PATH_SUB) {
    routerCurrent = checkRouterPrior(USER_PATH, USER_ROUTER_SUB)
    if (routerCurrent) {
      return next(routerCurrent)
    }
  }
  // end check router prior with permission, role
  return next()
})
function hasAccess (routerInfo) {
  const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
  const permissionUserCurrent = store.getters.permissionUserCurrent
  if (roleAdminUserCurrent || routerInfo.path === '/notpage' || routerInfo.path === '/sign-in' || routerInfo.path === '/confirm-mfa-staff' || routerInfo.path === 'reset-password-staff') {
    return true
  }
  if (Object.keys(permissionUserCurrent).length !== 0) {
    let isPath = false
    for (const [key] of Object.entries(permissionUserCurrent)) {
      if (key.toString().includes(routerInfo.path)) {
        isPath = true
        break
      }
    }
    if (!isPath) {
      return false
    }
  }
  return true
}

export default router
