const NON_PRODUCT_FRAGMENT = `fragment nonProductPrice on NonProductPrice {
  id
  nonProductId
  stayDate
  roomTypeId
  price1
  price2
  price3
  price4
  price5
  price6
  price7
  price8
  price9
  price10
}`

export const NON_PRODUCT_LIST = `
query {
  nonProductList{id name}
}
`

export const ACCOMMODATION_PRICE_LIST = `
  query(
    $facilityId: Int
    $accommodationFromDate: String!
    $accommodationToDate: String!
    $nonProductFromDate: DateString!
    $nonProductToDate: DateString!
    $mwWeekCalendarFromDate: DateString
    $mwWeekCalendarToDate: DateString
    $bookingPlanFilter: BookingPlanListFilterInput!
    $bookingPlanPagination: PaginationInput
    $roomTypeId: Int
    $includeNonProduct: Boolean!
    $includeMwCalendar: Boolean!
  ) {
    accommodationPriceList(
      fromDate: $accommodationFromDate
      toDate: $accommodationToDate
      facilityId: $facilityId
      roomTypeId: $roomTypeId
    ) {
      id
      roomTypeId
      stayDate
      spPoint
      fpPoint
      dpPrice
      vmPrice1
      vmPrice2
      vmPrice3
      vmPrice4
      vmPrice5
      vmPrice6
      vmPrice7
      vmPrice8
      vmPrice9
      vmPrice10
      vsPrice1
      vsPrice2
      vsPrice3
      vsPrice4
      vsPrice5
      vsPrice6
      vsPrice7
      vsPrice8
      vsPrice9
      vsPrice10
    }
    nonProductPriceList(
      fromDate: $nonProductFromDate
      toDate: $nonProductToDate
      facilityId: $facilityId
      roomTypeId: $roomTypeId
    ) @include(if: $includeNonProduct) {
      ...nonProductPrice
    }
    mwWeekCalendarList(
      fromDate: $mwWeekCalendarFromDate
      toDate: $mwWeekCalendarToDate
      facilityId: $facilityId
    ) @include(if: $includeMwCalendar) {
      facilityId
      mwDate
      mwRank
    }
    bookingPlanList(
      filter: $bookingPlanFilter
      pagination: $bookingPlanPagination
    ) {
      items {
        roomTypeId
        enabled
        targetBookingType
        title
        id
        minDays
        maxDays
        minGuestCount
        maxGuestCount
        availableFromDate
        availableToDate
        additionalChargeType
        fixedAdditionalCharge
        bookingPlanCharges {
          stayDate
          fixedAdditionalCharge
        }
      }
    }
  }

${NON_PRODUCT_FRAGMENT}
`

export const NON_PRODUCT_PRICE = `
query(
  $nonProductFromDate: DateString!
  $nonProductToDate: DateString!
  $roomTypeId: Int!
) {
  nonProductPriceList(
    fromDate: $nonProductFromDate
    toDate: $nonProductToDate
    roomTypeId: $roomTypeId
  ) {
    ...nonProductPrice
  }
}

${NON_PRODUCT_FRAGMENT}
`

export const EXTEND_BOOKING = `
mutation ($bookingId:Int!, $days:[BookingExtendDayInput!]!) {
  extendBooking (bookingId:$bookingId, days: $days) {
    checkOutDate
  }
}
`
