import router from '../router'

export function getBookingPath (bookingId, { block } = { block: false }) {
  if (block) {
    return `/booking/Block-Reservation?id=${bookingId}&tab=Block-Reservation`
  }
  return `/reservation/contract-detail?id=${bookingId}&tab=basic-information`
}

const routeHelpers = {
  booking: getBookingPath
}

export const goToRoute = Object.keys(routeHelpers).reduce((result, key) => {
  const goToFunc = (...args) => {
    const path = routeHelpers[key](...args)
    router.push(path)
  }
  const replaceFunc = (...args) => {
    const path = routeHelpers[key](...args)
    router.replace(path)
  }
  const openFunc = (...args) => {
    const path = routeHelpers[key](...args)

    const routeData = router.resolve({ path })
    window.open(routeData.href, '_blank')
  }
  const urlFunc = (...args) => {
    const path = routeHelpers[key](...args)

    const routeData = router.resolve({ path })
    return routeData.href
  }
  goToFunc.push = goToFunc
  goToFunc.replace = replaceFunc
  goToFunc.open = openFunc
  goToFunc.url = urlFunc
  result[key] = goToFunc
  return result
}, {})
