<template>
  <v-text-field
    v-model="localValue"
    :rules="intRules"
    @change="commit"
    :prefix="intPrefix"
    :disabled="disabled"
    v-bind="$attrs"
    :error="error"
    @update:error="val => $emit('update:error', val)"
  />
</template>

<script>
import { translateZenkakuNumbers } from '@/utils/japanese'
export default {
  props: {
    value: [Number, String],
    prefix: String,
    rules: Array,
    negative: Boolean,
    jpy: Boolean,
    required: Boolean,
    min: Number,
    max: Number,
    disabled: Boolean,
    type: String, // dont want to re-bind this to the text-field
    error: Boolean
  },
  data () {
    return {
      localValue: null
    }
  },
  computed: {
    numberLocalValue () {
      let number = null
      if (this.localValue || this.localValue === 0) {
        const numberText = translateZenkakuNumbers(this.localValue)
        number = Number(numberText.replace(/[^0-9.-]/g, ''))
        if (isNaN(number)) {
          number = null
        }
      }
      return number
    },
    finalMax () {
      return typeof this.max === 'number' ? this.max : 2147483647
    },
    finalMin () {
      if (typeof this.min === 'number') return this.min
      if (this.negative) return -2147483648
      return 0
    },
    intRules () {
      const rules = [
        // v => !!v || this.$t('rules.isRequired'),
        v => typeof this.numberLocalValue !== 'number' || this.numberLocalValue <= this.finalMax || `最大値は${this.$options.filters.toThousands(this.finalMax)}です`,
        v => typeof this.numberLocalValue !== 'number' || this.numberLocalValue >= this.finalMin || `${this.$options.filters.toThousands(this.finalMin)}以上の値を入力下さい`
      ].concat((this.rules ?? []).map(ruleFunc => {
        return () => ruleFunc(this.numberLocalValue)
      }))
      if (this.required) {
        rules.unshift(this.$rules.required)
      }
      return rules
    },
    intPrefix () {
      return this.prefix ?? (this.jpy ? '¥' : undefined)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        if (v || v === 0) {
          const num = Number(v)
          this.localValue = isNaN(num) ? null : num.toString()
          this.formatValue()
        } else {
          this.localValue = null
        }
      }
    }
  },
  methods: {
    formatValue () {
      if (this.numberLocalValue) {
        this.localValue = this.$options.filters.toThousands(this.numberLocalValue)
      }
    },
    commit () {
      this.$emit('input', this.numberLocalValue)
      this.$emit('change', this.numberLocalValue)
      this.formatValue()
    }
  }
}
</script>
<style lang="scss" scoped></style>
