import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import { CREATE_SALE_ITEM, DELETE_SALE_ITEM, UPDATE_SALE_ITEM } from '../../../api/graphql/sale-item'
import gql from 'graphql-tag'

export default {
  async createSaleItem (context, { apollo, saleItem, commit }) {
    const variables = {
      data: saleItem
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${CREATE_SALE_ITEM}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_SALE_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateSaleItem (context, { apollo, id, saleItem, commit }) {
    const variables = {
      id,
      data: saleItem
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${UPDATE_SALE_ITEM}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_SALE_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteSaleItem (context, { apollo, id, commit }) {
    const variables = {
      id
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${DELETE_SALE_ITEM}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_SALE_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
