import gql from 'graphql-tag'

export const GET_HOLIDAYS = gql`
query(
    $from: DateString!
    $to: DateString!
  ) {
    holidays(from: $from, to: $to) {
      holidayDate
      name
    }
  }
`
