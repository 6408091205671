import * as dateUtil from './dateUtil'
import {
  SALES_CHANEL_ENUM_BOOKING_LIST
} from '@/constants/enum'

// This is a future filter example
export function currency (value, symbol = '¥') {
  let final = Math.round(value * 100) / 100
  final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return `${symbol} ${final}`
}

export function currency2 (value, symbol = '¥') {
  let final = Math.round(value * 100) / 100
  final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${symbol}${final}`
}

export const jaYearMonth = dateUtil.jaYearMonth

export const jaDayFromDay = dateUtil.getDayText

export const jaLongDate = dateUtil.jaLongDate

export const jaLongDateNoDow = (date) => dateUtil.jaLongDate(date, { noDow: true })

export const jaLongDateSlash = dateUtil.jaLongDateSlash

export const jaShortDateSlash = dateUtil.jaShortDateSlash

export const formatDate = jaShortDateSlash // alias as in CRM

export const jaShortDateWithDow = dateUtil.jaShortDateWithDow

export const isoDate = dateUtil.isoDate

export const dateTimeSting = (date, options) => date ? dateUtil.getDateTime(date, {
  withSecond: false,
  delimeter: '/',
  ...options,
}) : ''

export const dateTimeStingWithSec = (date) => date ? dateUtil.getDateTime(date, {
  withSecond: true,
  delimeter: '/'
}) : ''

export function percent (float) {
  if (isNaN(float)) return '-%'
  return `${Math.round(float * 100)}%`
}

export function bookingTypeShort (booking) {
  if (booking.blockRoom) return 'ブロ'

  switch (booking.bookingTypeId ?? booking.bookingType.id) {
    case 1: return 'SP'
    case 2: return 'FP'
    case 3: return 'DP'
    case 4: return 'チケ'
    case 5: return 'VS'
    case 6: return 'MO'
    case 7: return 'VM'
    case 8: return 'OTA'
    case 9: return 'PW'
    case 10: return 'MW'
    case 11: return 'その他'
    case 12: return 'ポ提'
    case 13: return '体験'
  }
  return 'その他'
}
export function toThousands (num, options = { nan: '' }) {
  const returnDefault = options?.nan ?? ''
  if (num === null || typeof num === 'undefined') {
    return returnDefault
  }
  num = Number(num)
  if (isNaN(num)) return returnDefault
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function salesChannelName (channel) {
  return SALES_CHANEL_ENUM_BOOKING_LIST.find(item => item.key === channel)?.value || channel
}
