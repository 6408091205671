import { UPDATE_INVENTORY, SET_INVENTORY, INVENTORY_TYPE_LIST } from '@/api/graphql/inventory/inventory'
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async updateInventory ({ state, commit, rootState }) {
    const variables = {
      data: {
        inventoryTypeFrom: parseInt(state.inventory.inventoryTypeFrom),
        inventoryTypeTo: parseInt(state.inventory.inventoryTypeTo),
        rooms: parseInt(state.inventory.rooms),
        roomTypes: state.inventory.roomTypes,
        dateFrom: state.inventory.dateFrom,
        dateTo: state.inventory.dateTo,
        daysOfWeek: state.inventory.daysOfWeek
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_INVENTORY}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        commit('setChangeStatus', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_INVENTORY, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async setInventorys ({ state, commit, rootState }) {
    const variables = {
      inventoryTypeTo: parseInt(state.inventory.inventoryTypeTo),
      changeFrom: state.changeFrom
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${SET_INVENTORY}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        commit('setChangeStatus', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, SET_INVENTORY, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async loadInventoryTypes ({ commit }) {
    const res = await apolloClient.query({
      query: gql`${INVENTORY_TYPE_LIST}`
    })

    commit('setInventoryTypes', res.data.inventoryTypeList)
  }
}
