import actions from './actions'

const bookingPlans = {
  state: {
    list: [],
    pagination: {
      skip: null,
      take: null
    },
    detail: {
      enable: true,
      title: null,
      subtitle: null,
      targetBookingType: null,
      roomTypeId: null,
      imagePublicUrl1: null,
      imagePublicUrl2: null,
      description: null,
      remarks: null,
      question: {
        text: null,
        type: 'Text', // || Select
        required: false,
        questionSelects: [] // [{ title: '' }]
      },
      createdByName: null,
      chargeType: null,
      chargeAmount: null,
      stopSaleDay: null,
      stopSaleTime: null,
      availableFromDate: null,
      availableToDate: null,
      bookThroughDays: null,
      webPublicFromDate: null,
      webPublicToDate: null,
      bookingFromDate: null,
      bookingToDate: null,
      guestCountLimit: null,
      roomCountLimit: null,
      discountType: null,
      day1Discount: null,
      day2Discount: null,
      day3Discount: null,
      day4Discount: null,
      day5Discount: null,
      day6Discount: null,
      day7Discount: null,
      day8Discount: null,
      day9Discount: null,
      continueDiscountAfterDay9: null,
      minGuestCount: null,
      maxGuestCount: null,
      minDays: null,
      maxDays: null,
      overrideCancelPolicy: null,
      noShowPenalty: null,
      day0Penalty: null,
      threshold1Day: null,
      threshold1Penalty: null,
      threshold2Day: null,
      threshold2Penalty: null,
      threshold3Day: null,
      threshold3Penalty: null,
      threshold4Day: null,
      threshold4Penalty: null,
      threshold5Day: null,
      threshold5Penalty: null,
      threshold6Day: null,
      threshold6Penalty: null,
      threshold7Day: null,
      threshold7Penalty: null,
      availableWeekDays: null,
      csvFileKey: null
    }
  },
  mutations: {
    setBookingPlanList (state, items) {
      state.list = items
    },
    setBookingPlansPagiSkip (state, payload) {
      state.pagination.skip = payload
    },
    setBookingPlansPagiTake (state, payload) {
      state.pagination.take = payload
    }
  },
  actions
}

export default bookingPlans
