import actions from './actions'

const bookingGuest = {
  state: {
    statusReload: true,
    bookingGuest: {
      guests: [],
      guestSchedules: [],
      repSchedules: null
    }
  },

  getters: {
    getStatusReload (state) {
      return state.statusReload
    }
  },

  mutations: {
    setStatusReLoad (state, payload) {
      state.statusReload = !state.statusReload
    },

    setBookingGuest (state, payload) {
      state.bookingGuest.guests = payload.guests
    },
    setBookingGuestSchedules (state, payload) {
      state.bookingGuest.guestSchedules = []
      const myArr = JSON.parse(JSON.stringify(payload.guestSchedules))
      var arr = []
      for (let i = 0; i < myArr.length; i++) {
        delete myArr[i].id
        delete myArr[i].schedules.id
        delete myArr[i].representative
        var myArr2 = []
        myArr2 = JSON.parse(JSON.stringify(myArr[i]))

        const schedule = myArr2.schedules.schedules

        delete myArr2.schedules
        myArr2.schedules = schedule
        for (let j = 0; j < myArr2.schedules.length; j++) {
          delete myArr2.schedules[j].guestId
        }
        arr.push(myArr2)
      }
      state.bookingGuest.guestSchedules = arr
      state.bookingGuest.repSchedules = payload.repSchedules?.map(sche => ({
        stayDate: sche.stayDate,
        bathing: sche.bathing,
        stay: sche.stay
      }))
    }
  },

  actions
}

export default bookingGuest
