import { CREATE_BOOKING_V4, CREATE_BOOKING_NO_CUSTOMER_ID } from '@/api/graphql/guestRoomDailyReport/create-booking'
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import router from '@/router'
import { SEARCH_BOOKING_LIST } from '@/api/graphql/reservation/booking-list.js'
import gql from 'graphql-tag'

export default {
  async actionSearchBookingList ({ state, commit, rootState }, keyword) {
    const variables = {
      keyword: keyword
    }
    // commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${SEARCH_BOOKING_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then(data => {
        // commit('setLoadingOverlayHide', { root: true })
        resolve(data.data.bookingKeywordSearch.items)
      }).catch(error => {
        setCopyErrorTextCommit(commit, SEARCH_BOOKING_LIST, variables, error.graphQLErrors)
        // commit('setLoadingOverlayHide', { root: true })
        if (error.graphQLErrors[0] && error.graphQLErrors[0].message) {
          handlError(commit, error.graphQLErrors)
        }
        reject(error)
      })
    })
  },
  async createBookingV4 ({ state, commit, rootState }) {
    const variables = {
      bookingGroupId: parseInt(state.createBooking.bookingGroupId),
      bookingPlanId: parseInt(state.createBooking.bookingPlanId),
      force: state.createBooking.force,
      contractId: parseInt(state.createBooking.contractId),
      roomTypeId: parseInt(state.createBooking.roomTypeId),
      checkInDate: state.createBooking.checkInDate,
      createdClientId: (state.createBooking.createdClientId),
      createdSubMemberId: (state.createBooking.createdSubMemberId),
      bookingBarNote: state.createBooking.bookingBarNote,
      representativeClientId: (state.createBooking.representativeClientId),
      representativeSubMemberId: (state.createBooking.bookingGrrepresentativeSubMemberIdoupId),
      representativeName: state.createBooking.representativeName,
      representativeKana: state.createBooking.representativeKana,
      representativeTel: state.createBooking.representativeTel,
      representativeEmail: state.createBooking.representativeEmail || null,
      representativeAddress1: state.createBooking.representativeAddress1,
      numberOfAdults: parseInt(state.createBooking.numberOfAdults),
      numberOfChildren: parseInt(state.createBooking.numberOfChildren),
      numberOfChildrenWithBedShare: parseInt(state.createBooking.numberOfChildrenWithBedShare),
      priorityBooking: state.createBooking.priorityBooking,
      days: state.createBooking.days,
      parkings: state.createBooking.parkings,
      bookingTypeId: router.currentRoute.path === '/booking/new-booking' ? 13 : null,
      clientId: state.createBooking.clientId,
      bookingPrePayment: state.createBooking.bookingPrePayment
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_V4}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setLoadingOverlayHide', { root: true })
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        router.push('/reservation/contract-detail?id=' + data.data.createBookingV4.id + '&tab=basic-information')
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_V4, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },
  async createNewBookingNoCustomerID ({ state, commit, rootState }, finishData) {
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_NO_CUSTOMER_ID}`,
        variables: finishData
      }).then(data => {
        commit('setLoadingOverlayHide', { root: true })
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        router.push('/reservation/contract-detail?id=' + data.data.createBookingV4.id + '&tab=basic-information')
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_NO_CUSTOMER_ID, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
