import gql from 'graphql-tag'

export const SEARCH_BOOKING_LIST = `
  query (
    $keyword: String!
  ) {
    bookingKeywordSearch(filter: {keyword: $keyword}) {
      items {
        id
        checkInDate
        bookingTypeId
        bookingTypeCode
        bookingGroupId
        roomId
        roomName
        roomTypeId
        roomTypeName
        facilityId
        facilityName
        representativeName
        clientId
        memberId
        keywordHitIn
      }
    }
  }
  `

export const BOOKING_LIST = `
  query(
    $filter: BookingListFilterInput
    $orderBy: [OrderByInput!]
    $pagination: PaginationInput
  ) {
    bookingList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
      total
      hasMore
      items {
        remarks
        id
        cancelledAt
        bookingGroupId
        computed {
          status
        }
        bookingType {
          name
          code
          id
        }
        room {
          name
          roomType {
            id
            name
            facility {
              name
            }
          }
        }
        bookingPlan {
          title
        }
        checkInDate
        checkOutDate
        representative {
          name
        }
        blockRoom
        priorityBooking
        updatedAt
        createdAt
        computedForList {
          adults
          children
          bedShares
          hasParking #Boolean! 駐車場あり
          hasRentalItem #Boolean! 貸出品あり
          lastUpdatedAt
        }
      }
    }
  }
`

export const REMOVE_BOOKING = `
  mutation($bookingId: Int!) {
    removeBooking(bookingId: $bookingId) {
      __typename
    }
  }
`

export const BOOKING_TYPES_LIST = gql`
query {
  bookingTypesList {
    id
    name
    code
  }
}
`
