import { handlError } from '../constants/functions'
import store from '../store'

export default {
  install (Vue) {
    /**
     *
     * @param {function(): Promise<void>} promise
     */
    Vue.prototype.$showGqlError = async function (promise) {
      try {
        return await (typeof promise === 'function' ? promise() : promise)
      } catch (e) {
        let errors = e.graphQLErrors
        if (!errors || errors.length === 0) {
          errors = Array.isArray(e) ? e : [e]
        }
        handlError(store.commit, errors)
        throw e
      }
    }
  }
}
