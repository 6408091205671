import { apolloClient } from '@/plugins/apollo'
import {
  CANCEL_BOOKING
} from '@/api/graphql/bookingDetail/setting-cancel'
import router from '@/router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async cancelBooking ({ commit, state, rootState }) {
    const variables = {
      bookingId: parseInt(router.currentRoute.query.id),
      penaltyRates: state.bookingCancel.penaltyRates
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CANCEL_BOOKING}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setChangeStatus', true)
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, CANCEL_BOOKING, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
