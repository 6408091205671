import actions from './actions'

const saleItem = {
  state: {

  },
  mutations: {

  },
  actions
}

export default saleItem
