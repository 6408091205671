export const HOLIDAYS = `
  query($from: DateString!, $to: DateString!) {
    holidays(from: $from, to: $to) {
      holidayDate
      name
    }
  }
`

export const SET_HOLIDAYS = `
  mutation($year: Int!,  $holidays: [HolidayInput!]!) {
    setHolidays(
      year: $year
      holidays: $holidays
    ) {
      name
      holidayDate
    }
  }
`
