import { i18n } from '../../plugins/i18n'

const feesPointsRouter = [
  {
    path: '/fees-points-plans'
    // redirect: '/fees-points-plans/fees-and-points'
  },
  {
    path: '/fees-points-plans/fees-and-points',
    name: i18n.t('routeTitles.feesPoints.feesPoints'),
    component: () => import('../../views/dashboard/feesPoints/feesAndPoints/feesAndPoints'),
    meta: {
      title: '料金・ポイント・プラン'
    }
  },
  {
    path: '/fees-points-plans/plans',
    name: i18n.t('routeTitles.feesPoints.plans'),
    component: () => import('../../views/dashboard/feesPoints/plans/plans'),
    meta: {
      title: '料金・ポイント・プラン'
    }
  },
  {
    path: '/fees-points-plans/csv-update',
    name: i18n.t('routeTitles.feesPoints.feesAndPoints'),
    component: () => import('../../views/dashboard/feesPoints/CSVUpdate/CSVUpdate'),
    meta: {
      title: '料金・ポイント・プラン'
    }
  }
]

export default feesPointsRouter
