import { GET_RECORDED_INCOME_FACILITY_DAY, DOWNLOAD_FACILITY_DAILY_REPORT_ATTACHMENT_FILE } from '@/api/graphql/guestRoomDailyReport/get-recorded-income-facility-day'
import { handlError, loadingOverlay, setCopyErrorTextCommit } from '@/constants/functions'
import { apolloClient } from '@/plugins/apollo'
import { downloadFile } from '@/utils/downloadFile'
import gql from 'graphql-tag'

export default {
  async fetchDetailData ({ commit }, obj) {
    loadingOverlay(commit, true)
    const variables = {
      facilityId: parseInt(obj.facilityId),
      reportDate: obj.reportDate
    }
    await apolloClient.query({
      query: gql`${GET_RECORDED_INCOME_FACILITY_DAY}`,
      variables: variables,
      fetchPolicy: 'no-cache'
    }).then(data => {
      const cnvReportDataBookings = []
      data.data.getRecordedIncomeFacilityDay.bookings?.length > 0 &&
        data.data.getRecordedIncomeFacilityDay.bookings.map(item => {
          if (!item.facilityDailyReportConversionDetail) {
            cnvReportDataBookings.push({
              ...item,
              facilityDailyReportConversionDetail: {
                cleaning: null,
                cleaningFeeExempt: null,
                cleaningFeeExemptAmount: null,
                facilityFeeExempt: null,
                facilityFeeExemptAmount: null,
                remarks: null
              }
            })
          } else {
            cnvReportDataBookings.push(item)
          }
        })
      commit('setReportDayData', data.data.getRecordedIncomeFacilityDay)
      commit('setReportDayDataBookings', cnvReportDataBookings)
      loadingOverlay(commit, false)
    }).catch(error => {
      setCopyErrorTextCommit(commit, GET_RECORDED_INCOME_FACILITY_DAY, variables, error.graphQLErrors)
      loadingOverlay(commit, false)
      handlError(commit, error.graphQLErrors)
    })
  },

  async downloadDailyReportAttachment (_noneed, {
    facilityId,
    reportDate,
    paymentMethodId,
    name
  }) {
    await apolloClient
      .query({
        query: gql`${DOWNLOAD_FACILITY_DAILY_REPORT_ATTACHMENT_FILE}`,
        variables: {
          facilityId,
          reportDate,
          paymentMethodId
        }
      })
      .then(data => {
        const urlList =
        data.data.downloadFacilityDailyReportAttachmentFile.attachments
        if (urlList.length > 0) {
          for (let i = 0; i < urlList.length; i++) {
            downloadFile(urlList[i].downloadUrl, name + i)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
