import actions from './actions'

const bookingBasicInfomation = {
  state: {
    bookingBasicInfomation: {}
  },

  mutations: {
    setBookingBasicInfomation (state, payload) {
      state.bookingBasicInfomation = payload.bookingBasicInfomation
    }
  },

  actions
}

export default bookingBasicInfomation
