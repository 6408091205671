import actions from './actions'
const accommodationPrice = {
  state: {
  },
  mutations: {
  },
  actions
}

export default accommodationPrice
