import gql from 'graphql-tag'

export const STAFF = gql`query ($id: Int!){
  staff(id: $id) {
    name
    nameAbbr
    crmRep
    email
    lastLoginAt
  }
}`

export const STAFF_LIST = gql`query ($showDisabled: Boolean){
  staffList(showDisabled: $showDisabled) {
    disabled
    profileImgUrl
    name
    id
    crmRep
    email
    lastLoginAt
    role { name }
    facility {name}
  }
}`

export const CREATE_STAFF = `mutation ($data: CreateStaffInput!){
  createStaff(data:$data) {
    id
    name
    disabled
    email
    profileImgUrl
    roleId
    facilityId
    crmRep
  }
}`

export const GENERATE_UPLOAD_URL = gql`mutation (
  $contentType: String!
  $contentLength: Int!
) {
  generateUploadUrl(
    type: "staff/profile-image"
    contentType: $contentType
    contentLength: $contentLength
  ) {
    uploadUrl
    fileUrl
  }
}`
export const UPDATE_STAFF = `mutation updateStaff($id: Int!, $data: UpdateStaffInput!){
  updateStaff(id: $id, data: $data) {
    disabled name email profileImgUrl roleId facilityId crmRep
  }
}`
