
export const FACILITY_PARKING_LIST = `query ($facilityId: Int! $hideQtyZero: Boolean){
  facilityParkingList(facilityId: $facilityId hideQtyZero: $hideQtyZero) {
    id name maxHeight quantity allowCancelWait
  }
}`

export const CREATE_FACILITY_PARKING = `mutation ($data: CreateFacilityParkingInput!){
  createFacilityParking(data: $data) {
    id name maxHeight quantity allowCancelWait
  }
}`

export const UPDATE_FACILITY_PARKING = `mutation ($id: Int! $data: UpdateFacilityParkingInput!){
  updateFacilityParking(id: $id, data:$data) {
    id name maxHeight quantity  allowCancelWait
  }
}`

export const DELETE_FACILITY_PARKING = `mutation ($id: Int!){
  deleteFacilityParking(id: $id) {
    name
  }
}`
