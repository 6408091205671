import { CONFIRM_EMAIL_STAFF } from '@/api/graphql/staff/confirm-email'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async confirmEmailStaff ({ state, commit, rootState }) {
    const variables = {
      data: {
        token: state.confirmEmail.token,
        password: state.confirmEmail.password
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CONFIRM_EMAIL_STAFF}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        router.push({ path: '/users/list' })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CONFIRM_EMAIL_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
