import {
  UPDATE_BOOKING_REMARK,
  FACILITY_BOOKING_REMARKS_LIST
} from '@/api/graphql/facility-booking-remark'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createFacilityBookingRemarks ({ state, commit, rootState }) {
    const variables = {
      facilityId: parseInt(router.currentRoute.query.id),
      data: {
        bookingTypeId: parseInt(
          state.facilityBookingRemark.bookingType.id
        ),
        remarks: state.facilityBookingRemark.remarks
      }
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_BOOKING_REMARK}`,
          variables: variables
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true })
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true }
          )
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_BOOKING_REMARK, variables, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async fetchFacilityBookingRemarksList ({ commit }) {
    const variables = {
      facilityId: parseInt(router.currentRoute.query.id)
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient
      .query({
        query: gql`${FACILITY_BOOKING_REMARKS_LIST}`,
        variables: variables
      })
      .then(data => {
        commit(
          'setFacilityBookingRemarksList',
          data.data.facilityBookingRemarksList
        )
        commit('setLoadingOverlayHide', { root: true })
      })
      .catch(error => {
        setCopyErrorTextCommit(commit, FACILITY_BOOKING_REMARKS_LIST, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
      })
  }
}
