import gql from 'graphql-tag'

export const GET_RECORDED_INCOME_FACILITY_DAY = `
  query($facilityId: Int!, $reportDate: DateString) {
    getRecordedIncomeFacilityDay(
      data: { facilityId: $facilityId, reportDate: $reportDate }
    ) {
      report {
        reportDate
        type
        cash1
        cash5
        cash10
        cash50
        cash100
        cash500
        cash1000
        cash2000
        cash5000
        cash10000
        cashAdjustment
        cashAdjustmentReason
        notes
        hqRejectedAt
        hqConfirmedAt
        facilitySubmittedAt
      }
      details {
        order
        paymentMethodId
        category
        name
        amount
        detail {
          check1stName
          check2ndName
          voucher1Amount
          voucher1Count
          voucher2Amount
          voucher2Count
          voucher3Amount
          voucher3Count
          voucher4Amount
          voucher4Count
          voucher5Amount
          voucher5Count
          attachments {
            attachmentFile
            id
          }
        }
      }
      bookings {
        id
        bookingType {name code id}
        bookingGroupId
        computed {
          status #ステータス
        }
        room {
          roomType
          {
            name #部屋タイプ
          }
          name #部屋
  
        }
        checkInDate #チェックイン
        checkOutDate #チェックアウト
        representative {
          name #代表者   
        }
        facilityDailyReportConversionDetail {
          cleaning
          cleaningFeeExempt
          cleaningFeeExemptAmount
          facilityFeeExempt
          facilityFeeExemptAmount
          remarks
          id
        }
      }
    }
  }
`

export const SUBMIT_TVS_FACILITY_DAILY_REPORT = `
  mutation(
    $facilityId: Int!
    $reportDate: DateString!
    $cash1Count: Int!
    $cash5Count: Int!
    $cash10Count: Int!
    $cash50Count: Int!
    $cash100Count: Int!
    $cash500Count: Int!
    $cash1000Count: Int!
    $cash2000Count: Int!
    $cash5000Count: Int!
    $cash10000Count: Int!
    $cashAdjustment: Int!
    $cashAdjustmentReason: String
    $notes: String
  ) {
    submitTVSFacilityDailyReport(
      data: {
        facilityId: $facilityId
        reportDate: $reportDate
        cash1Count: $cash1Count
        cash5Count: $cash5Count
        cash10Count: $cash10Count
        cash50Count: $cash50Count
        cash100Count: $cash100Count
        cash500Count: $cash500Count
        cash1000Count: $cash1000Count
        cash2000Count: $cash2000Count
        cash5000Count: $cash5000Count
        cash10000Count: $cash10000Count
        cashAdjustment: $cashAdjustment
        cashAdjustmentReason: $cashAdjustmentReason
        notes: $notes
      }
    ) {
      __typename
    }
  }
`
export const UPDATE_TVS_FACILITY_DAILY_REPORT = `
  mutation(
    $reportId: Int!
    $cash1Count: Int!
    $cash5Count: Int!
    $cash10Count: Int!
    $cash50Count: Int!
    $cash100Count: Int!
    $cash500Count: Int!
    $cash1000Count: Int!
    $cash2000Count: Int!
    $cash5000Count: Int!
    $cash10000Count: Int!
    $cashAdjustment: Int!
    $cashAdjustmentReason: String
    $notes: String
  ) {
    updateTVSFacilityReport(
      reportId: $reportId
      data: {
        cash1Count: $cash1Count
        cash5Count: $cash5Count
        cash10Count: $cash10Count
        cash50Count: $cash50Count
        cash100Count: $cash100Count
        cash500Count: $cash500Count
        cash1000Count: $cash1000Count
        cash2000Count: $cash2000Count
        cash5000Count: $cash5000Count
        cash10000Count: $cash10000Count
        cashAdjustment: $cashAdjustment
        cashAdjustmentReason: $cashAdjustmentReason
        notes: $notes
      }
    ) {
      __typename
    }
  }
`

export const DOWNLOAD_FACILITY_DAILY_REPORT_ATTACHMENT_FILE = gql`
  query($facilityId: Int!, $reportDate: DateString!, $paymentMethodId: Int!) {
    downloadFacilityDailyReportAttachmentFile(
      data: {
        facilityId: $facilityId
        reportDate: $reportDate
        paymentMethodId: $paymentMethodId
      }
    ) {
      attachments {
        downloadUrl
        __typename
      }
    }
  }
`
export const CONFIRM_FACILITY_DAILY_REPORT_DETAIL = `
  mutation($data: ConfirmDetailRowFacilityDailyReportInput! $byHq: Boolean) {
    confirmFacilityDailyReportDetail(data: $data byHq: $byHq) {
      id
    }
  }
`

export const SUBMIT_CNV_FACILITY_DAILY_REPORT = `
  mutation(
    $facilityId: Int!
    $reportDate: DateString!
    $detail: [SubmitCNVFacilityDailyReportDetails!]!
    $note: String
  ) {
    submitCNVFacilityDailyReport(
      data: {
        facilityId: $facilityId
        reportDate: $reportDate
        details: $detail
        notes: $note
      }
    ) {
      id
    }
  }
`

export const UPDATE_CNV_FACILITY_DAILY_REPORT = `
mutation(
  $reportId: Int!
  $detail: [SubmitCNVFacilityDailyReportDetails!]!
  $note: String
) {
  updateCNVFacilityReport(
    reportId: $reportId
    data: {
      details: $detail
      notes: $note
    }
  ) {
    id
  }
}
`

export const ATTACH_FILE_FACILITY_DAILY_REPORT_DETAIL = gql`
mutation (
  $facilityId: Int!
  $reportDate: DateString!
  $paymentMethodId: Int!
  $fileUrl: String!
  $byHq: Boolean
) {
  attachFileFacilityDailyReportDetail(data: {
    facilityId: $facilityId
    reportDate: $reportDate
    paymentMethodId: $paymentMethodId
    fileUrl: $fileUrl
  } byHq: $byHq) {
    id
  }
}
`
