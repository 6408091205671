import { apolloClient } from '@/plugins/apollo'
import {
  BOOKING_LIST, REMOVE_BOOKING
} from '@/api/graphql/reservation/booking-list'
import router from '@/router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async fetchBookingList ({ commit, state }) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      filter: {
        fromDate: state.listFilter.fromDate,
        toDate: state.listFilter.toDate,
        facilityId: parseInt(state.listFilter.facility.id),
        cancelled: state.listFilter.canceled.key,
        bookingTypeId: state.listFilter.bookingType.key,
        salesChannel: state.listFilter.salesChannel.key,
        remarks: state.listFilter.keyword
      },
      orderBy: state.listFilter.orderBy,
      pagination: {
        take: state.listFilter.take,
        skip: state.listFilter.skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${BOOKING_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then(data => {
        resolve(data)
        commit('setBookingList', data.data.bookingList.items)
        commit('setTotalBookinglist', data.data.bookingList.total)
        commit('setHasMoreBookinglist', data.data.bookingList.hasMore)
        commit('setLoadingOverlayHide', { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, BOOKING_LIST, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async removeBooking ({ commit, rootState }, id) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      bookingId: parseInt(router.currentRoute.query.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${REMOVE_BOOKING}`,
        variables: variables,
        update: store => {
          const oldList = store.readQuery({ query: gql`${BOOKING_LIST}` })
          const item = oldList.data.bookingList.items.find(item => item.id === id)
          oldList.splice(oldList.data.bookingList.items.indexOf(item), 1)
          store.writeQuery({ query: gql`${BOOKING_LIST}`, data: oldList })
        }
      }).then(data => {
        commit('setLoadingOverlayHide', { root: true })
        resolve(data)
        router.push('/reservation')
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, REMOVE_BOOKING, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
      })
    })
  }
}
