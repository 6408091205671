import { CREATE_RENTAL_ITEM, UPDATE_RENTAL_ITEM, DELETE_RENTAL_ITEM, RENTAL_ITEM_LIST } from '@/api/graphql/rental-item-for-facility'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createRentalItem ({ state, commit, rootState }) {
    const variables = {
      data: {
        facilityId: parseInt(state.rentalItemFac.facilityId),
        rentalItemDefId: parseInt(state.rentalItemFac.rentalItemDefId),
        quantity: parseInt(state.rentalItemFac.quantity)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_RENTAL_ITEM}`,
        variables: variables,
        update: (store, { data: { createRentalItem } }) => {
          const data = store.readQuery({
            query: gql`${RENTAL_ITEM_LIST}`,
            variables: {
              facilityId: parseInt(router.currentRoute.query.id)
            }
          })
          const dataUpdate = {
            id: createRentalItem.id,
            quantity: createRentalItem.quantity,
            rentalItemDef: {
              id: parseInt(createRentalItem.rentalItemDefId),
              name: state.rentalItemFac.rentalItemDefName,
              price: parseInt(state.rentalItemFac.facilityPrice),
              rentalPeriod: state.rentalItemFac.facilityrentalPeriod
            }
          }
          data.rentalItemList.push(dataUpdate)
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_RENTAL_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateRentalItem ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(state.rentalItemFac.id),
      data: {
        quantity: parseInt(state.rentalItemFac.quantity),
        rentalItemDefId: parseInt(state.rentalItemFac.rentalItemDefId)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_RENTAL_ITEM}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_RENTAL_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteRentalItem ({ state, commit, rootState }) {
    const variables = {
      id: state.rentalItemFac.id
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_RENTAL_ITEM}`,
        variables: variables,
        update: (store) => {
          const data = store.readQuery({
            query: gql`${RENTAL_ITEM_LIST}`,
            variables: {
              facilityId: parseInt(router.currentRoute.query.id)
            }
          })
          data.rentalItemList = data.rentalItemList.filter(item => item.id !== state.rentalItemFac.id)
          store.writeQuery({
            query: gql`${RENTAL_ITEM_LIST}`,
            variables: {
              facilityId: parseInt(router.currentRoute.query.id)
            },
            data
          })
          commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_RENTAL_ITEM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
