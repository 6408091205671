import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'

import {
  CREATE_ROOM_TYPE,
  UPDATE_ROOM_TYPE,
  ROOM_TYPE_LIST,
  DELETE_ROOM_TYPE
} from '@/api/graphql/facility/facility-room-type'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createRoomType ({ state, commit, rootState }) {
    const variables = {
      data: {
        facilityId: state.facilityRoomType.id,
        name: state.facilityRoomType.name
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_ROOM_TYPE}`,
          variables: variables,
          update: (store, { data: { createRoomType } }) => {
            const data = store.readQuery({
              query: gql`${ROOM_TYPE_LIST}`,
              variables: {
                facilityId: parseInt(router.currentRoute.query.id)
              }
            })
            data.roomTypeList.push(createRoomType)
          }
        })
        .then(data => {
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
          window.open('/facility/settings/room-type/detail?id=' + data.data.createRoomType.id)
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_ROOM_TYPE, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },

  async updateRoomType ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id),
      data: {
        name: state.facilityRoomType.name,
        order: parseInt(state.facilityRoomType.order),
        backgroundColor: state.facilityRoomType.backgroundColor,
        maxOccupancy: parseInt(state.facilityRoomType.maxOccupancy),
        bedShareMaxOccupancy: parseInt(state.facilityRoomType.bedShareMaxOccupancy),
        rakutsuId: state.facilityRoomType.rakutsuId
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_ROOM_TYPE}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_ROOM_TYPE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateRoomTypeBack ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id),
      data: {
        name: state.facilityRoomType.name,
        order: parseInt(state.facilityRoomType.order),
        backgroundColor: state.facilityRoomType.backgroundColor,
        maxOccupancy: parseInt(state.facilityRoomType.maxOccupancy),
        bedShareMaxOccupancy: parseInt(state.facilityRoomType.bedShareMaxOccupancy),
        rakutsuId: state.facilityRoomType.rakutsuId
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_ROOM_TYPE}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        // router.go(-1)
        window.close()
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_ROOM_TYPE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteRoomType ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_ROOM_TYPE}`,
        variables: variables,
        update: (store) => {
          const data = store.readQuery({
            query: gql`${ROOM_TYPE_LIST}`,
            variables: {
              facilityId: parseInt(state.facilityRoomType.facilityId)
            }
          })
          data.roomTypeList = data.roomTypeList.filter(item => item.id !== state.facilityRoomType.id)
        }
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        router.push('/facility/settings/room-type?id=' + state.facilityRoomType.facilityId)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_ROOM_TYPE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  updateSelectedRoomType ({ commit }, roomType) {
    commit('setSelectedRoomType', roomType)
  }
}
