import actions from './actions'

const confirmEmail = {
  state: {
    token: '',
    password: ''
  },
  mutations: {
    setConfirmEmail (state, payload) {
      state.confirmEmail = payload.confirmEmail
    }
  },
  actions
}

export default confirmEmail
