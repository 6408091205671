const bookingRouter = [
  {
    path: '/booking'
    // redirect: '/booking/list'
  },
  {
    path: '/booking/new-booking',
    component: () => import('../../views/dashboard/booking/NewBooking'),
    meta: {
      noSideBar: true,
      title: '新規予約作成'
    }
  },
  {
    path: '/booking/block',
    component: () => import('../../views/dashboard/booking/block/BookingBlock'),
    meta: {
      isSidebar: true,
      title: 'ブロック'
    }
  },
  {
    path: '/booking/Block-Reservation',
    component: () => import('../../views/dashboard/booking/block/ReservationTabs'),
    meta: {
      isSidebar: true,
      title: 'ブロック_予約ID_'
    }
  }

]

export default bookingRouter
