import {
  CREATE_FACILITY_HOTEL_TAX,
  DELETE_FACILITY_HOTEL_TAX,
  UPDATE_FACILITY_HOTEL_TAX
} from '../../../api/graphql/facility-hotel-tax'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {

  async createFacilityHotelTax (context, { apollo, facilityHotelTax, commit }) {
    const variables = {
      data: facilityHotelTax
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${CREATE_FACILITY_HOTEL_TAX}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_FACILITY_HOTEL_TAX, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateFacilityHotelTax (context, { apollo, id, facilityHotelTax, commit }) {
    const variables = {
      id,
      data: facilityHotelTax
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${UPDATE_FACILITY_HOTEL_TAX}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_FACILITY_HOTEL_TAX, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteFacilityHotelTax (context, { apollo, id, commit }) {
    const variables = {
      id
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${DELETE_FACILITY_HOTEL_TAX}`,
        variables: variables
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_FACILITY_HOTEL_TAX, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
