const calendarParking = {
  state: {
    infoCarParks: [],
    typeCarsRoot: [],
    typeCars: [],
    parkingStatistics: []

  },

  mutations: {
    setInfoCarParks (state, payload) {
      state.infoCarParks = payload.infoCarParks
    },
    setTypeCarsRoot (state, payload) {
      state.typeCarsRoot = payload.typeCarsRoot
    },
    setTypeCars (state, payload) {
      state.typeCars = payload.typeCars
    },
    setParkingStatistics (state, payload) {
      state.parkingStatistics = payload.parkingStatistics
    }
  },

  getters: {
    getInfoCarParks (state) {
      return state.infoCarParks
    },
    getTypeCarsRoot (state) {
      return state.typeCarsRoot
    },
    getTypeCars (state) {
      return state.typeCars
    },
    getParkingStatistics (state) {
      return state.parkingStatistics
    }
  }
}

export default calendarParking
