import actions from './actions'

const dailyReport = {
  state: {
    status: null,
    staffName: {
      staff1stName: null,
      staff2ndName: null
    },
    reportDayData: {},
    reportDayDataBookings: []
  },
  mutations: {
    setReportDayDataBookings (state, payload) {
      state.reportDayDataBookings = payload
    },
    setDailyReportStatus (state, payload) {
      state.status = payload
    },
    setStaff1stName (state, payload) {
      state.staff1stName = payload
    },
    setStaff2ndName (state, payload) {
      state.staff2ndName = payload
    },
    setReportDayData (state, payload) {
      state.reportDayData = payload
    }
  },
  actions
}

export default dailyReport
