import { i18n } from '@/plugins/i18n'

export function required (v) {
  return !!v || v === 0 || '必須項目です'
}

export const checkLenghInput = (max) => {
  return v => !v || v?.length <= max || i18n.t('rules.maximumNCharacter', { value: max })
}

export function postalCodeNoHyphen (v) {
  return !v || /^[0-9]{7}$/.test(v) || '郵便番号は7桁の数字で入力して下さい'
}

export function checkKatakana (v) {
  // eslint-disable-next-line no-irregular-whitespace
  return !v || /^[ァ-ヶー 　]*$/.test(v) || i18n.t('rules.isKatakana')
}

export const checkNumber = v => {
  const regex = /^[0-9]+$/
  return !v || regex.test(v) || i18n.t('rules.numberInvalid')
}

export function isEmailValid (v) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !v || re.test(String(v).toLowerCase()) || i18n.t('rules.emailIsInvalid')
}
