import gql from 'graphql-tag'

export const BOOKING = gql`
query ($id: Int!) {
    booking(id: $id) {
      room {
        roomType {
          facility {
            id
          }      
        }    
      }
      checkInDate
      checkOutDate
      parkings {
        id 
        fromDate
        toDate
        facilityParking {
          id name 
        }
        height
        carNumber
        color
      }
    }
    
    facilityParkingCancelWaitlistList(filter: {
      bookingId: $id
    }) {
      items {
        id
        fromDate
        toDate
        facilityParking {
          id name
        }
        notifiedAt
      }
    }
  }
`

export const ADD_PARKING_RESERVATION = `
mutation (
  $bookingId: Int!
  $parkings: [AddReservationFacilityParkingInput!]!
) {
  addParkingReservation(
    bookingId: $bookingId
    parkings: $parkings
  ) {
    id
  }
}
`

export const UPDATE_PARKING_RESERVATION_BULK = `
mutation (
  $reservations: [UpdateFacilityParkingReservationInput!]!
  $waitLists: [UpdateFacilityParkingWaitListInputCombined!]!
) {
  updateParkingReservationBulk(
    reservations: $reservations
    waitLists: $waitLists
  )
}
`
