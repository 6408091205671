import { i18n } from '../../plugins/i18n'

const userRouter = [
  {
    path: '/users'
    // redirect: '/users/information'
  },
  {
    path: '/users/information',
    name: i18n.t('routeTitles.users.information'),
    component: () => import('../../views/dashboard/users/information/information'),
    meta: {
      title: 'ユーザー(CRM共通)'
    }
  },
  {
    path: '/users/list',
    name: i18n.t('routeTitles.users.userList'),
    component: () => import('../../views/dashboard/users/userList/userList'),
    meta: {
      title: 'ユーザー(CRM共通)'
    }
  },
  {
    path: '/confirm-email',
    component: () => import('../../views/dashboard/users/confirmEmail/confirmEmail'),
    meta: {
      title: 'ユーザー(CRM共通)'
    }
  },
  {
    path: '/users/rights',
    name: i18n.t('routeTitles.users.userRights'),
    component: () => import('../../views/dashboard/users/userRights/userRights'),
    meta: {
      title: 'ユーザー(CRM共通)'
    }
  }
]

export default userRouter
