import gql from 'graphql-tag'

export const BOOKING_TICKET_TYPE_LIST = gql`query ($ticketNo: String, $isAvailable: Boolean, $skip: Int, $take: Int, $orderBy: [OrderByInput!] ){
  bookingTicketTypeList(
    filter: {ticketNo: $ticketNo isAvailable: $isAvailable hasIntendedClient: false}
    orderBy: $orderBy
    pagination:{
      skip: $skip
      take: $take
    }
  ) {
    total
    items {
      createdAt
      availableToDate
      id
      computed {
        issuedCount
        usedCount
        availableCount
      }
      name
       roomTypes {
         roomType {
           id
           facility {
             name
           }
         }
       }
      cancelDeadline
      bookingFromDate
      bookingFromDays
      bookingFromMonths
    }
  }
}`

export const BOOKING_TICKET_TYPE = `
query ($id: String!){
  bookingTicketType(id:$id){
    id
    name
    roomTypes {
      roomType {
        id
        name 
        facility {
          id
          name
        }
      }
    }
    availableFromDate
    availableToDate
    availableSunday
    availableMonday
    availableTuesday
    availableWednesday
    availableThursday
    availableFriday
    availableSaturday
    availableHoliday
    notes
    exceptDates {
      exceptDate
    }
    toothbrush razor nightwear rentalItem
    accommodationPrice
    bookCallCenter bookSalesRep
    cancelDeadline
    bookingFromDate
    bookingFromDays
    bookingFromMonths
    assetValue
    notes
    createdAt
    availableToDate
    createdBy {
      name
    }
    computed {
      issuedCount
      usedCount
      availableCount
    }
  }
}
`

export const DISABLE_BOOKING_TICKET = `mutation(
  $ticketId: String!
  $disabled: Boolean!
) {
  disableBookingTicket(
    ticketId: $ticketId
    disabled: $disabled
  ){
    disabled
  }
}`

export const CREATE_BOOKING_TICKETS = `mutation(
  $name: String!
  $numberOfTickets: Int!
  $roomTypeIds: [Int!]!
  $availableFromDate: DateString!
  $availableToDate: DateString!
  $bookCallCenter: Boolean!
  $bookSalesRep: Boolean!
  $monday: Boolean!
  $tuesday: Boolean!
  $wednesday: Boolean!
  $thursday: Boolean!
  $friday: Boolean! 
  $saturday: Boolean!
  $sunday:Boolean!
  $holiday: Boolean! 
  $exceptDates: [DateString!]!
  $nightwear: Boolean! 
  $toothbrush: Boolean!
  $razor: Boolean! 
  $rentalItem: Boolean! 
  $accommodationPrice: Int!
  $cancelDeadline: Int!
  $bookingFromDate: DateString!
  $bookingFromDays: Int!
  $bookingFromMonths: Int!
  $assetValue: Int!
  ) {
  createBookingTickets(ticketData: {
  name: $name
  numberOfTickets: $numberOfTickets
  targetRoomTypeIds: $roomTypeIds
  availableFromDate: $availableFromDate
  availableToDate: $availableToDate
  bookCallCenter: $bookCallCenter
  bookSalesRep: $bookSalesRep
  availableMonday: $monday availableTuesday: $tuesday availableWednesday: $wednesday availableThursday: $thursday
  availableFriday: $friday availableSaturday: $saturday availableSunday: $sunday availableHoliday: $holiday
  exceptionDates: $exceptDates
  nightwear: $nightwear toothbrush: $toothbrush razor: $razor rentalItem: $rentalItem
  accommodationPrice: $accommodationPrice
  cancelDeadline: $cancelDeadline
  bookingFromDate: $bookingFromDate
  bookingFromDays: $bookingFromDays
  bookingFromMonths: $bookingFromMonths
  assetValue: $assetValue
  }) {
  id
  }
  }
`

export const UPDATE_BOOKING_TICKET_TYPE = `
mutation(
  $id: String!
  $notes: String!
) {
  updateBookingTicketType(id: $id, notes: $notes) {
    notes
  }
}`
