import actions from './actions'

const invitationTicket = {
  state: {
    selectDay: null,
    statusCreateSuccess: null,
    idTicket: null,
    listRoomType: [],
    ticketId: null,
    disabled: false,
    name: '',
    numberOfTickets: null,
    roomTypeIds: [],
    availableFromDate: '',
    availableToDate: '',
    bookCallCenter: false,
    bookSalesRep: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    holiday: false,
    exceptDates: [],
    nightwear: false,
    toothbrush: false,
    razor: false,
    rentalItem: false,
    accommodationPrice: null,
    cancelDeadline: null,
    bookingFromDate: '',
    bookingFromDays: null,
    assetValue: null,
    notes: ''
  },

  getters: {
    getSelectDay (state) {
      return state.selectDay
    },

    getStatusCreateSuccess (state) {
      return state.statusCreateSuccess
    }
  },

  mutations: {
    setSelectedDays (state, payload) {
      state.selectDay = payload
    },

    setStatusCreateSuccess (state, payload) {
      state.statusCreateSuccess = !state.statusCreateSuccess
    },

    setInvitationTicket (state, payload) {
      state.invitationTicket = payload.invitationTicket
    },
    setInvitationTicketId (state, payload) {
      state.invitationTicket.idTicket = payload
    }
  },
  actions
}

export default invitationTicket
