import { i18n } from '../../plugins/i18n'

const guestRoomDailyReportRouter = [
  {
    path: '/guest-room-daily-report'
    // redirect: '/guest-room-daily-report/guest-room-panel'
  },
  {
    path: '/guest-room-daily-report/guest-room-panel',
    name: i18n.t('routeTitles.guestRoomDailyReport.guestRoomPanel'),
    component: () => import('../../views/dashboard/guestRoomDailyReport/guestRoomPanel'),
    meta: {
      title: '客室パネル/日報'
    }
  },
  {
    path: '/guest-room-daily-report/daily-report',
    name: i18n.t('routeTitles.guestRoomDailyReport.dailyReport'),
    component: () => import('../../views/dashboard/guestRoomDailyReport/dailyReport'),
    meta: {
      title: '客室パネル/日報'
    }
  },
  {
    path: '/guest-room-daily-report/daily-report-list',
    name: i18n.t('routeTitles.guestRoomDailyReport.dailyReportList'),
    component: () => import('../../views/dashboard/guestRoomDailyReport/dailyReportList'),
    meta: {
      title: '客室パネル/日報'
    }
  },
  {
    path: '/guest-room-daily-report/holiday-setting',
    name: i18n.t('routeTitles.guestRoomDailyReport.holidaySetting'),
    component: () => import('../../views/dashboard/guestRoomDailyReport/holidaySetting'),
    meta: {
      title: '客室パネル/日報'
    }
  }
]

export default guestRoomDailyReportRouter
