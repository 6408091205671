import { CREATE_STAFF, UPDATE_STAFF } from '@/api/graphql/staff/staff-info'
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createStaff ({ state, commit, rootState }) {
    const variables = {
      data: {
        name: state.staffInfo.name,
        email: state.staffInfo.email,
        profileImgUrl: state.staffInfo.profileImgUrl || null,
        roleId: parseInt(state.staffInfo.roleId),
        facilityId: parseInt(state.staffInfo.facilityId),
        crmRep: state.staffInfo.crmRep
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_STAFF}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        commit('statusCreate', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateStaff ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(state.staffInfo.id),
      data: {
        disabled: state.staffInfo.disabled,
        name: state.staffInfo.name,
        email: state.staffInfo.email,
        profileImgUrl: state.staffInfo.profileImgUrl || null,
        roleId: parseInt(state.staffInfo.roleId),
        facilityId: parseInt(state.staffInfo.facilityId),
        crmRep: state.staffInfo.crmRep
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_STAFF}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        commit('statusCreate', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
