import actions from './actions'

const facilitySettings = {
  state: {
    statusCloseTab: true,
    facilitySettings: {
      rakutsuHotelCode: '',
      id: null,
      name: '',
      type: '',
      order: null,
      postalCode: '',
      address1: '',
      tel: '',
      fax: '',
      bedShareRule: '',
      floorPlanPdf: ''
    },
    facilityList: [],
    selectedFacility: null
  },

  getters: {
    getStatusCloseTab (state) {
      return state.statusCloseTab
    }
  },

  mutations: {
    setStatusCloseTab (state, payload) {
      state.statusCloseTab = !state.statusCloseTab
    },

    setFacilitySettings (state, payload) {
      state.facilitySettings = payload.facilitySettings
    },
    setFacilityList (state, payload) {
      state.facilityList = [...payload]
    },
    setSelectedFacility (state, facilityName) {
      state.selectedFacility = facilityName
    }
  },
  actions
}

export default facilitySettings
