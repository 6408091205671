export const REQUEST_MFA_STAFF = `
  mutation ($email: String!) {
    requestMFAStaff(email: $email){
      token
      type
      validUntil
    }
  }
`

export const CONFIRM_MFA_STAFF = `
  mutation ($token: String!, $code: String!, $password: String!) {
    confirmMFAStaff(token: $token, code: $code, password: $password){
      staff {
        id
        name
        email
      },
      accessToken
      role,
      rolePermissions {
        action
        permission
      }
    }
  }
`

export const REQUEST_RESET_PASSWORD_STAFF = `
  mutation ($email: String!) {
    requestResetPasswordStaff(email: $email){
      token
      sentEmail
      validUntil
    }
  }
`

export const CONFIRM_RESET_PASSWORD_STAFF = `
  mutation ($token: String!, $code: String!, $password: String!) {
    confirmResetPasswordStaff(token: $token, code: $code, password: $password){
      staff {
        id
        name
        email
        allowPms
      },
      accessToken
      role
      rolePermissions {
        permission
        action
      }
    }
  }
`

export const STAFF_PROFILE_IMG_URL = `
  query ($id: Int!) {
    staff (id: $id) {
      profileImgUrl
    }
  }`
