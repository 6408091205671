export const RENTAL_ITEM_LIST = `query($facilityId :Int!){
  rentalItemList(facilityId: $facilityId) {
    id
    quantity
    rentalItemDef {
      id
      name
      rentalPeriod
      price
    }
  }
}`

export const CREATE_RENTAL_ITEM = `mutation ($data: CreateRentalItemInput!){
  createRentalItem(data: $data) {
    id
    facilityId
    rentalItemDefId
    quantity
  }
}`

export const UPDATE_RENTAL_ITEM = `mutation ($id: Int!, $data: UpdateRentalItemInput !){
  updateRentalItem(id: $id, data: $data) {
    id
    quantity
  }
}`

export const DELETE_RENTAL_ITEM = `mutation ($id: Int!){
    deleteRentalItem(id: $id)
}`
