import { CREATE_STAFF_ROLE, UPDATE_STAFF_ROLE } from '@/api/graphql/staff/staff-role'
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createStaffRole ({ state, commit, rootState }) {
    const variables = {
      data: {
        name: state.staffRole.name
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_STAFF_ROLE}`,
        variables: variables,
        update: (store, { data: { createStaffRole } }) => {
          state.staffRole.id = createStaffRole.id
        }
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_STAFF_ROLE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateStaffRole ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(state.staffRole.id),
      data: {
        name: state.staffRole.name,
        readPermissionKeys: state.staffRole.readPermissionKeys,
        writePermissionKeys: state.staffRole.writePermissionKeys
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_STAFF_ROLE}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_STAFF_ROLE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
