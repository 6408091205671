import actions from './actions'
import config from '../../../constants'

const user = {
  state: {
    id: null,
    email: '',
    name: '',
    role: '',
    emailSave: '',
    tokenSave: '',
    permissions: null, // { '/facility/edit': 'W' }
    // shortToken: localStorage.getItem(config.shortToken) || '',
    // token: localStorage.getItem(config.authToken) || '',
    token: localStorage.getItem('token') || '',
    current: localStorage.getItem('currentUser') || null,
    roleAdminUser: false,
    permissionUser: {},
    profileImgUrl: null
  },
  getters: {
    getRoleUser (state) {
      return state.roleAdminUser
    },
    getPermissionUser (state) {
      return state.permissionUser
    },
    getProfileImgUrl (state) {
      return state.profileImgUrl
    }
  },
  mutations: {
    setToken (state, token) {
      token ? localStorage.setItem(config.authToken, token) : localStorage.removeItem(config.authToken)
      state.token = token
    },
    setShortToken (state, shortToken) {
      shortToken ? localStorage.setItem(config.shortToken, shortToken) : localStorage.removeItem(config.shortToken)
      state.shortToken = shortToken
    },
    setUser (state, user) {
      user ? localStorage.setItem(config.user, JSON.stringify(user)) : localStorage.removeItem(config.user)
      state.id = user ? user.id : null
      state.email = user ? user.email : ''
      state.name = user ? user.name : ''
      state.role = user ? user.role : ''
      state.permissions = user ? user.permissions : null
    },
    setEmail (state, email) {
      state.email = email
    },
    setTokenAndEmail (state, data) {
      state.tokenSave = data.token
      state.emailSave = data.email
    },
    setRoleAdminUser (state, roleAdminUser) {
      state.roleAdminUser = roleAdminUser
    },
    setPermissionUser (state, permissionUser) {
      state.permissionUser = permissionUser
    },
    setProfileImgUrl (state, profileImgUrl) {
      state.profileImgUrl = profileImgUrl
    }
  },
  actions
}

export default user
