import { SET_HOLIDAYS, HOLIDAYS } from '@/api/graphql/guestRoomDailyReport/holidays'
import { GET_HOLIDAYS } from '@/api/graphql/get-holoday.js'
import { apolloClient } from '@/plugins/apollo'
import { getDateOfYearString } from '@/utils/handleDate'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'
import { addMonthInTokyuWay, isoDate } from '../../../utils/dateUtil'

export default {
  async fetchHolidays ({ commit, state }, append) {
    const startDateOfYear = getDateOfYearString(state.startYear, 0, 1)
    const endDateOfYear = !append ? getDateOfYearString(state.currentYear, 11, 31) : getDateOfYearString(state.startYear, 11, 31)
    const variables = {
      from: startDateOfYear,
      to: endDateOfYear
    }
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${HOLIDAYS}`,
        variables: variables
      }).then(data => {
        resolve(data)
        if (!append) {
          commit('setHolidaysState', data.data.holidays)
        } else {
          // the holidays are from the past year, so we must add 1 year to the dates
          data.data.holidays.forEach(holiday => {
            holiday.holidayDate = isoDate(addMonthInTokyuWay(holiday.holidayDate, 12))
          })
          commit('setMoreHolidays', data.data.holidays)
        }
      }).catch(error => {
        setCopyErrorTextCommit(commit, HOLIDAYS, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async getHolidays ({ commit, state }, option) {
    commit('setLoadingOverlayShow')
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${GET_HOLIDAYS}`,
        variables: {
          from: option.fromDate,
          to: option.toDate
        }
      }).then(data => {
        commit('setLoadingOverlayHide')
        resolve(data)
      }).catch(error => {
        commit('setLoadingOverlayHide')
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async setHolidays ({ commit, rootState }, agr) {
    const stupidArr = []
    for (let i = 0; i < agr.holidays.length; i++) {
      stupidArr.push({
        date: agr.holidays[i].holidayDate,
        name: agr.holidays[i].name
      })
    }
    const variables = {
      year: parseInt(agr.year),
      holidays: stupidArr
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${SET_HOLIDAYS}`,
        variables: variables,
        update: (store, { data: { setHolidays } }) => {
          const startDateOfYear = getDateOfYearString(agr.year, 0, 1)
          const endDateOfYear = getDateOfYearString(agr.year, 11, 31)
          const data = store.readQuery({
            query: gql`${HOLIDAYS}`,
            variables: {
              from: startDateOfYear,
              to: endDateOfYear
            }
          })
          data.holidays = setHolidays
          store.writeQuery({
            query: gql`${HOLIDAYS}`,
            variables: {
              from: startDateOfYear,
              to: endDateOfYear
            },
            data
          })
        }
      }).then(data => {
        resolve(data)
        commit('setHolidaysState', data.data.setHolidays)
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, SET_HOLIDAYS, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
