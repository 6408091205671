export const ROOM_TYPE_LIST = `
query($facilityId: Int!) {
  roomTypeList(facilityId: $facilityId) {
    id name
  }
}
`
export const ROOM_LIST = `
query($roomTypeId: Int!) {
  roomList(roomTypeId: $roomTypeId) {
    id name
  }
}
`
export const CREATE_BOOKING_V4 = `
mutation(
  $roomTypeId: Int!
  $checkInDate: DateString!
  $days: [BookingDayInput!]!
  $bookingTypeId: Int
  $blockRoomId: Int
  $bookingBarNote: String
) {
  createBookingV4(
  roomTypeId: $roomTypeId
  checkInDate: $checkInDate
  days: $days
  bookingTypeId: $bookingTypeId
  blockRoomId: $blockRoomId
  bookingBarNote: $bookingBarNote
  ) {
    id
    checkInDate
    checkOutDate
    bookingGroupId
    bookingTypeId
  }
  }
`
