export const CREATE_FACILITY_HOTEL_TAX = `
  mutation ($data: CreateFacilityHotelTaxInput!) {
    createFacilityHotelTax(data: $data) {
      id
      facility {
        id
        name
      }
      effectiveFrom
    }
  }
`

export const UPDATE_FACILITY_HOTEL_TAX = `
  mutation ($id: Float!, $data: UpdateFacilityHotelTaxInput!) {
    updateFacilityHotelTax(id: $id, data: $data) {
      id
      facility {
        id
        name
      }
      effectiveFrom
    }
  }
`

export const DELETE_FACILITY_HOTEL_TAX = `
  mutation ($id: Float!) {
    deleteFacilityHotelTax(id: $id) {
      id
      facility {
        id
        name
      }
      effectiveFrom
    }
  }
`
