import gql from 'graphql-tag'

export const FACILITY = `query($id: Int!) {
          facility(id: $id){
            id
            type
            name
            postalCode
            address1
            tel
            fax
            bedShareRule
            bedShareMaxAge
            floorPlanPdf
            order
            rakutsuHotelCode
          }
        }`

export const UPDATE_FACILITY = `mutation ($id: Int!, $data: UpdateFacilityInput!){
  updateFacility(id: $id, data: $data) {
    name
    order
    postalCode
    address1
    tel
    fax
    bedShareRule
    floorPlanPdf
  }
}`

export const DELETE_FACILITY = `mutation ($id: Int!){
  deleteFacility(id: $id){
    name
  }
}`

export const GENERATE_UPLOAD_URL = gql`mutation (
  $contentType: String!
  $contentLength: Int!
) {
  generateUploadUrl(
    type: "facility/floor-plan"
    contentType: $contentType
    contentLength: $contentLength
  ) {
    uploadUrl
    fileUrl
  }
}`
