// import config from '../constants'

export default {
  token: state => state.user.token,
  shortToken: state => state.user.shortToken,
  currentUser: state => state.user.current,
  roleAdminUserCurrent: state => state.user.roleAdminUser,
  permissionUserCurrent: state => state.user.permissionUser,
  alertSuccess: state => state.alert.success.type,
  alertError: state => state.alert.error.type,
  errorDialog: state => state.alert.errorDialog.type,
  errorDialogMessages: state => state.alert.errorDialog.messages,
  facilityList: state => state.facilitySettings.facilityList,
  loadingOverlay: state => state.alert.loading,
  currentSaleItem: state => (
    state.facilitySaleItem.saleItem
  ),
  rentalItemDef: state => (
    state.rentalItemDef.rentalItemDef
  ),
  rentalItemFac: state => (
    state.rentalItemFac.rentalItemFac
  ),
  facilityItemParking: state => (
    state.facilityItemParking.facilityItemParking
  ),
  facilityBookingRemark: state => (
    state.facilityBookingRemark.facilityBookingRemark
  ),
  facilityRoom: state => (
    state.facilityRoom.facilityRoom
  ),
  facilitySettings: state => (
    state.facilitySettings.facilitySettings
  ),
  facilityRoomType: state => (
    state.facilityRoomType.facilityRoomType
  ),
  staffInfo: state => (
    state.staffInfo.staffInfo
  ),
  staffRole: state => (
    state.staffRole.staffRole
  ),
  confirmEmail: state => (
    state.confirmEmail.confirmEmail
  ),
  bookingRental: state => (
    state.bookingRental.bookingRental
  ),
  bookingParking: state => (
    state.bookingParking.bookingParking
  ),
  inventory: state => (
    state.inventory.inventory
  ),
  changeFrom: state => (
    state.changeFrom.dateFrom
  ),
  invitationTicket: state => (
    state.invitationTicket.invitationTicket
  ),
  getInvitationTicketId: state => (
    state.invitationTicket.idTicket
  ),
  bookingGuest: state => (
    state.bookingGuest.bookingGuest
  ),
  bookingCancel: state => (
    state.bookingCancel.bookingCancel
  ),
  holidays: state => state.holidays.holidays,
  currentYear: state => state.holidays.currentYear,
  bookingPlanList: state => state.bookingPlans.list,
  bookingListFilter: state => state.bookingList.listFilter,
  bookingList: state => state.bookingList.bookingList,
  dailyReportStatus: state => state.dailyReport.status,
  dailyReportStaffName: state => state.dailyReport.staffName,
  reportDayData: state => state.dailyReport.reportDayData,
  reportDayDataBookings: state => state.dailyReport.reportDayDataBookings,
  facilityBookingRemarksList: state => state.facilityBookingRemark.facilityBookingRemarksList,
  accommodationPriceImportList: state => state.csvUpdate.list,
  accommodationPriceImportTotal: state => state.csvUpdate.total
}
