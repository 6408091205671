import { i18n } from '../../plugins/i18n'

const reservationRouter = [
  {
    path: '/reservation'
    // redirect: '/reservation/reservation-list'
  },
  {
    path: '/reservation/reservation-list',
    component: () => import('../../views/dashboard/reservation/reservationList'),
    meta: {
      title: '予約'
    }
  },
  {
    path: '/reservation/send-usage-certificate',
    name: i18n.t('routeTitles.reservation.sendUsageCertificate'),
    component: () => import('../../views/dashboard/reservation/reservationSendUsageCertificate'),
    meta: {
      title: '予約'
    }
  },
  {
    path: '/reservation/cancellation-applied',
    name: i18n.t('routeTitles.reservation.cancellationApplied'),
    component: () => import('../../views/dashboard/reservation/reservationCancellationApplied'),
    meta: {
      title: '予約'
    }
  },
  {
    path: '/reservation/add-group',
    component: () => import('../../views/dashboard/contractDetail/addGroup'),
    meta: {
      isSidebar: true,
      title: '新規予約作成'
    }
  },
  {
    path: '/reservation/calendar',
    component: () => import('../../views/dashboard/reservation/ReservationCalendarPage'),
    meta: {
      isSidebar: true,
      title: '予約'
    }
  },
  {
    path: '/reservation/calendar-block',
    component: () => import('../../views/dashboard/reservation/reservationCalendarBlock/ReservationCalendarPage.vue'),
    meta: {
      isSidebar: true,
      title: '予約'
    }
  },
  {
    path: '/reservation/contract-detail',
    component: () => import('@/views/dashboard/contractDetail/contractDetailTab'),
    meta: {
      isSidebar: true,
      title: '予約_予約ID__マスター'
    }
  },
  {
    path: '/reservation/new-reservation',
    component: () => import('../../views/dashboard/reservation/NewReservation'),
    meta: {
      isSidebar: true,
      title: 'その他予約 新規予約作成'
    }
  },
  {
    path: '/reservation/summary',
    component: () => import('../../views/booking/BookingSummaryPage'),
    meta: {
      isSidebar: true,
      title: '予約概要'
    }
  }
]

export default reservationRouter
