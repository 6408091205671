import actions from './actions'

const rentalItemFac = {
  state: {
    rentalItemFac: {
      id: null,
      facilityId: null,
      rentalItemDefId: null,
      quantity: 0,
      rentalItemDefName: '',
      facilityPrice: 0,
      facilityrentalPeriod: ''
    }

  },
  mutations: {
    setRentalItemFac (state, payload) {
      state.rentalItemFac = payload.rentalItemFac
    }
  },
  actions
}

export default rentalItemFac
