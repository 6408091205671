import actions from './actions'

const frameSettingInventory = {
  state: {
  },
  mutations: {
  },
  actions
}

export default frameSettingInventory
