import gql from 'graphql-tag'

export const INVENTORY_TYPE_LIST = `query {
    inventoryTypeList {
      id
      name
      nameAbbr
      externallyReserved
      color
    }
  }`

export const INVENTORY_LIST = gql`query($dateFrom: DateString, $dateTo: DateString, $facilityId: Int, $roomTypes:[Int!]){
  inventoryList(
    dateFrom: $dateFrom
    dateTo: $dateTo
    facilityId: $facilityId
    roomTypes: $roomTypes
  ) {
    roomTypeId
    rooms
    inventoryTypeId
    stayDate
    bookings {
      room { id name }
      blockRoom
    }
  }
}`

export const UPDATE_INVENTORY = `mutation ($data: UpdateInventoryInput!) {
  updateInventory(data: $data) {
    __typename
  }
}`

export const SET_INVENTORY = `mutation ($inventoryTypeTo:Int!, $changeFrom: [ChangeFromInventoryInput!]!) {
  setInventory (
    inventoryTypeTo: $inventoryTypeTo
    changeFrom: $changeFrom
  ) {
    __typename
  }
}`

export const MW_WEEK_CALENDAR_LIST = gql`query($fromDate: DateString, $toDate: DateString, $facilityId: Int) {
  mwWeekCalendarList(
    fromDate: $fromDate
    toDate: $toDate
    facilityId: $facilityId
  ){
    facilityId
    mwDate
    weekNo
    latterHalf
  }
}`
