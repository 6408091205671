export const RENTAL_ITEM_DEF_LIST = `query {
  rentalList: rentalItemDefList {
  name
  id
  price
  rentalPeriod
  }
}`

export const CREATE_RENTAL_ITEM_DEF = `mutation ($data: CreateRentalItemDefInput!){
  createRentalItemDef(data: $data) {
    name
    price
    rentalPeriod
    id
  }
}`

export const UPDATE_RENTAL_ITEM_DEF = `mutation ($id:Int!, $data: UpdateRentalItemDefInput!){
  updateRentalItemDef(id: $id, data: $data) {
    id
    name
    price
    rentalPeriod
    }
  }`

export const DELETE_RENTAL_ITEM_DEF = `mutation ($id: Int!){
    deleteRentalItemDef(id: $id)
}`
