<template>
  <v-main>
    <SidebarLayout
      @is-sidebar-open="onSidebarStateChange"
      v-if="!isSidebarHidden"
    ></SidebarLayout>
    <div
      class="main-layout-container"
      :class="{ 'sidebar-open': isSidebarOpen }"
    >
      <slot />
    </div>
  </v-main>
</template>

<script>
import SidebarLayout from '../layouts/SidebarLayout'

export default {
  name: 'MainLayout',
  components: { SidebarLayout },
  data: () => {
    return {
      isSidebarOpen: false,
      isSidebarHidden: false
    }
  },
  mounted () {
    this.isSidebarHidden = this.$route.meta.noSideBar
  },
  methods: {
    onSidebarStateChange (value) {
      this.isSidebarOpen = value
    }
  }
}
</script>

<style scoped lang="scss">
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
.alert-default {
  position: fixed;
  width: 300px;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  &.--center {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep {
  .error-dialog {
    .v-alert__content {
      max-width: 100% !important;
      word-break: break-word !important;
    }
  }
}
</style>
