import { i18n } from '../../plugins/i18n'

const facilityRouter = [
  {
    path: '/facility'
    // redirect: '/facility/settings'
  },
  {
    path: '/facility/settings',
    component: { render: c => c('router-view') },
    children: [
      {
        path: '',
        name: i18n.t('routeTitles.facility.facilitySettings'),
        component: () => import('../../views/dashboard/facility/facilitySettings/facilitySettings'),
        meta: {
          title: '施設'
        }
      },
      {
        path: 'detail',
        component: () => import('../../views/dashboard/facility/facilitySettings/detail/facilityDetailTab'),
        meta: {
          noSideBar: true
          // title: '軽井沢'
        }
      },
      {
        path: 'room-type',
        component: { render: c => c('router-view') },
        children: [
          {
            path: '',
            component: () => import('../../views/dashboard/facility/facilitySettings/roomType/roomType'),
            meta: {
              title: '施設'
            }
          },
          {
            path: 'detail',
            component: () => import('../../views/dashboard/facility/facilitySettings/roomType/roomTypeDetail'),
            meta: {
              noSideBar: true,
              title: '施設'
            }
          },
          {
            path: 'rooms',
            component: { render: c => c('router-view') },
            children: [
              {
                path: '',
                component: () => import('../../views/dashboard/facility/facilitySettings/roomType/roomTypeSettings'),
                meta: {
                  title: '施設'
                }
              },
              {
                path: 'detail',
                component: () => import('../../views/dashboard/facility/facilitySettings/roomType/roomDetail'),
                meta: {
                  noSideBar: true,
                  title: '施設'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/facility/rental-list',
    name: i18n.t('routeTitles.facility.rentalList'),
    component: () => import('../../views/dashboard/facility/rentalList/rentalList'),
    meta: {
      title: '施設'
    }
  },
  {
    path: '/facility/products-for-sale',
    name: i18n.t('routeTitles.facility.productsForSale'),
    component: () => import('../../views/dashboard/facility/productsForSale/productsForSale'),
    meta: {
      title: '施設'
    }
  }
]

export default facilityRouter
