import gql from 'graphql-tag'

export const INVENTORY_TYPE_LIST = gql`
  query($onlyExternallyReserved: Boolean) {
    inventoryTypeList(onlyExternallyReserved: $onlyExternallyReserved) {
      id
      name
      nameAbbr
    }
  }
`

export const UPDATE_NAME_ABBR = `
  mutation($id: Int!, $nameAbbr: String!) {
    updateNameAbbr(id: $id, nameAbbr: $nameAbbr){
      id
      nameAbbr
      name
    }
  }
`
