import actions from './actions'

const rentalItemDef = {
  state: {
    rentalItemDef: {
      id: null,
      name: '',
      price: 0,
      rentalPeriod: ''
    }

  },
  mutations: {
    setRentalItemDef (state, payload) {
      state.rentalItemDef = payload.rentalItemDef
    }
  },
  actions
}

export default rentalItemDef
