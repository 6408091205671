const calendarLending = {
  state: {
    rentalLists: [],
    typeCarsRoot: [],
    infoExhibits: [],
    customerLists: []

  },

  mutations: {
    setRentalListsLending (state, payload) {
      state.rentalLists = payload.rentalLists
    },
    setTypeCarsRootLending (state, payload) {
      state.typeCarsRoot = payload.typeCarsRoot
    },
    setInfoExhibitsLending (state, payload) {
      state.infoExhibits = payload.infoExhibits
    },
    setCustomerListsLending (state, payload) {
      state.customerLists = payload.customerLists
    }
  },

  getters: {
    getRentalListsLending (state) {
      return state.rentalLists
    },
    getTypeCarsRootLending (state) {
      return state.typeCarsRoot
    },
    getInfoExhibitsLending (state) {
      return state.infoExhibits
    },
    getCustomerListsLending (state) {
      return state.customerLists
    }
  }
}

export default calendarLending
