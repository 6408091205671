import { CREATE_RENTAL_ITEM_DEF, UPDATE_RENTAL_ITEM_DEF, DELETE_RENTAL_ITEM_DEF, RENTAL_ITEM_DEF_LIST } from '@/api/graphql/rental-item-def'
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createRentalItemDef ({ state, commit, rootState }) {
    const variables = {
      data: {
        rentalPeriod: state.rentalItemDef.rentalPeriod,
        name: state.rentalItemDef.name,
        price: parseInt(state.rentalItemDef.price)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_RENTAL_ITEM_DEF}`,
        variables: variables,
        update: (store, { data: { createRentalItemDef } }) => {
          const data = store.readQuery({ query: gql`${RENTAL_ITEM_DEF_LIST}` })
          data.rentalList.push(createRentalItemDef)
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_RENTAL_ITEM_DEF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateRentalItemDef ({ state, commit, rootState }) {
    const variables = {
      id: state.rentalItemDef.id,
      data: {
        rentalPeriod: state.rentalItemDef.rentalPeriod,
        name: state.rentalItemDef.name,
        price: parseInt(state.rentalItemDef.price)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_RENTAL_ITEM_DEF}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_RENTAL_ITEM_DEF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteRentalItemDef ({ state, commit, rootState }) {
    const variables = {
      id: state.rentalItemDef.id
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_RENTAL_ITEM_DEF}`,
        variables: variables,
        update: (store) => {
          const data = store.readQuery({ query: gql`${RENTAL_ITEM_DEF_LIST}` })
          data.rentalList = data.rentalList.filter(item => item.id !== state.rentalItemDef.id)
          commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_RENTAL_ITEM_DEF, variables, error.graphQLErrors)
        commit('setAlertError', rootState.alert.error.messages.deleteError, { root: true })
        // handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
