import { removeValueInArray } from '@/constants/functions'
const billingStatement = {
  state: {
    billingStatementList: [],
    billingStatementListPayment: [],
    chargeIds: [],
    chargePrice: [],
    createBookingInvoicesCurrent: {},
    totalByTaxRate: {},
    chargeInfo: {},
  },

  mutations: {
    setBillingStatementList (state, payload) {
      state.billingStatementList = payload.billingStatementList
      state.billingStatementListPayment = payload.billingStatementListPayment
      state.totalByTaxRate = payload.totalByTaxRate
      state.chargeInfo = payload.chargeInfo
    },
    setChargeIds (state, payload) {
      const chargeId = parseInt(payload.chargeIds)
      const found = state.chargeIds.includes(chargeId)
      if (found) {
        state.chargePrice.find(row => row.chargeIds === payload.chargeIds).price = payload.price
      } else {
        state.chargeIds.push(chargeId)
        state.chargePrice.push({ chargeIds: payload.chargeIds, price: payload.price })
      }
    },
    removeChargeIds (state, payload) {
      const chargeIdsTmp = removeValueInArray(state.chargeIds, payload.chargeIds)
      state.chargeIds = chargeIdsTmp
      state.chargePrice = state.chargePrice.filter((item) => {
        return item.chargeIds !== payload.chargeIds
      })
    },
    setChargeIdsDefault (state, payload) {
      state.chargeIds = []
      state.chargePrice = []
    },
    setCreateBookingInvoicesCurrent (state, payload) {
      state.createBookingInvoicesCurrent = payload.createBookingInvoicesCurrent
    }
  },

  getters: {
    getBillingStatementList (state) {
      return state.billingStatementList
    },
    getBillingStatementListPayment (state) {
      return state.billingStatementListPayment
    },
    getTotalByTaxRate (state) {
      return state.totalByTaxRate
    },
    getChargeInfo (state) {
      return state.chargeInfo
    },
    getChargeIds (state) {
      return state.chargeIds
    },
    getChargePrice (state) {
      return state.chargePrice
    },
    getCreateBookingInvoicesCurrent (state) {
      return state.createBookingInvoicesCurrent
    }
  }
}

export default billingStatement
