import MainComponent from '../../components/MainComponent'
import facilityRouter from './facility'
import calendarRouter from './calendar'
import bookingRouter from './booking'
import feesPointsRouter from './feesPoints'
import frameSettingRoute from './frameSetting'
import formReportRouter from './formReport'
import userRouter from './users'
import reservationRouter from './reservation'
import guestRoomDailyReportRouter from './guestRoomDailyReport'
import invitationsAndCouponsRouter from '@/router/modules/invitationsAndCoupons'
import {
  PAGE_RESERVATION_CONTRACT_DETAIL,
  PAGE_RESERVATION_CALENDAR,
  PAGE_BOOKING_BLOCK_RESERVATION_DETAIL,
  PAGE_NEW_RESERVATION_DETAIL,
  PAGE_USER
} from '@/constants/enum'
import { utoa } from '@/constants/functions'

const dashboardRouter = {
  path: '',
  component: MainComponent,
  async beforeEnter (to, from, next) {
    // document.title = to.meta.title
    if (window.localStorage.getItem('token')) {
      next()
    } else {
      if ((to.path === PAGE_RESERVATION_CONTRACT_DETAIL || to.path === PAGE_BOOKING_BLOCK_RESERVATION_DETAIL || to.path === PAGE_NEW_RESERVATION_DETAIL || to.path === PAGE_USER || to.path === PAGE_RESERVATION_CALENDAR) && to.query.fromPage === 'crm') {
        let paramsNext = to.fullPath
        paramsNext = utoa(paramsNext)
        return next({
          name: 'LoginPMS',
          params: {
            strFrom: paramsNext
          }
        })
      }
      let nextPath = to.fullPath
      nextPath = utoa(nextPath)
      return next({
        name: 'LoginPMS',
        params: {
          nextPath: nextPath
        }
      })
    }
  },
  children: [
    { path: '/' },
    // {
    //   path: 'dashboard',
    //   name: i18n.t('routeTitles.dashboard'),
    //   component: () => import('../../views/dashboard/dashboard.vue')
    // },
    ...calendarRouter,
    ...bookingRouter,
    ...feesPointsRouter,
    ...frameSettingRoute,
    ...formReportRouter,
    ...facilityRouter,
    ...userRouter,
    ...reservationRouter,
    ...guestRoomDailyReportRouter,
    ...invitationsAndCouponsRouter
    // {
    //   path: '/users-config',
    //   name: i18n.t('routeTitles.users.users'),
    //   component: () => import('@/views/dashboard/users')
    // }
  ]
}

export default dashboardRouter
