import actions from './actions'

const bookingCancel = {
  state: {
    bookingCancel: {
      penaltyRates: []
    },
    status: false
  },

  mutations: {
    setBookingCancel (state, payload) {
      state.bookingCancel = payload.bookingCancel
    },
    setChangeStatus (state, payload) {
      state.status = !state.status
    }
  },

  getters: {
    getStatus (state) {
      return state.status
    }
  },

  actions
}

export default bookingCancel
