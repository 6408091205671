export const calculateDays = (bigValue, smallValue) => {
  const dateTo = new Date(bigValue)
  const dateFrom = new Date(smallValue)
  const days = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24)
  return Math.ceil(days)
}

export const getDayText = (d) => {
  return '日月火水木金土'[d]
}

export const jaShortDate = (date) => {
  date = new Date(date)
  return `${date.getMonth() + 1}月${date.getDate()}日`
}

export const jaShortDateWithDow = (date) => {
  date = new Date(date)
  return `${date.getMonth() + 1}/${date.getDate()} (${getDayText(date.getDay())})`
}

function zPad (num) {
  return `${num}`.padStart(2, '0')
}

export function delimetedDateString (
  date,
  delimeter
) {
  return `${date.getFullYear()}${delimeter}${zPad(
    date.getMonth() + 1
  )}${delimeter}${zPad(date.getDate())}`
}

export const isoDate = (date) => {
  return delimetedDateString(new Date(date), '-')
}

export const createIsoDate = (year, month, date) => {
  return isoDate(new Date(year, month - 1, date))
}

export const getNumberOfDaysBetween = (fromDate, toDate) => {
  fromDate = new Date(fromDate)
  toDate = new Date(toDate)
  const diffTime = toDate - fromDate
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const jaLongDate = (date, options = {}) => {
  date = new Date(date)
  let str = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  if (!options?.noDow) {
    str += `(${getDayText(date.getDay())})`
  }
  return str
}

export const jaLongDateSlash = (date) => {
  date = new Date(date)
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} (${getDayText(date.getDay())})`
}

export const dateString = (date, delimeter = '-') => {
  date = new Date(date)
  return delimetedDateString(date, delimeter)
}

export const jaShortDateSlash = (date) => dateString(date, '/')

export const jaLongDateRange = (fromDate, toDate) => {
  return `${jaLongDate(fromDate)} ~ ${jaLongDate(toDate)}`
}

export const jaYearMonth = (date) => {
  date = new Date(date)
  return `${date.getFullYear()}年${date.getMonth() + 1}月`
}

export const addDays = (date, n) => {
  const dateObj = new Date(date)
  dateObj.setDate(dateObj.getDate() + n)

  if (typeof date === 'string') return isoDate(dateObj)
  return dateObj
}

export function getDateRangeArray (startDate, endDate, {
  excludeLast
} = {
  excludeLast: false
}) {
  startDate = new Date(startDate)
  endDate = new Date(endDate)

  const dates = []
  const currentDate = startDate

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate < endDate || (currentDate.getTime() === endDate.getTime() && !excludeLast)) {
    dates.push(isoDate(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export function getStayDateRangeArray (checkInDate, checkOutDate) {
  return getDateRangeArray(checkInDate, checkOutDate, { excludeLast: true })
}

export function getDateTime (datetime, options = {
  withSecond: false,
  delimeter: null
}) {
  const date = new Date(datetime)
  const dateString = options?.delimeter === 'ja' ? jaLongDate(date, { noDow: true }) : delimetedDateString(date, options?.delimeter ?? '-')
  return `${dateString} ${date.toTimeString().substring(0, options?.withSecond ? 8 : 5)}`
}

export const addMonths = (date, numOfMonths) => {
  var result = new Date(date)
  result.setMonth(result.getMonth() + numOfMonths)
  return result.toISOString().substr(0, 10)
}

export function isLastDayOfMonth (date) {
  date = new Date(date)
  const month = date.getMonth()
  date.setDate(date.getDate() + 1)

  return date.getMonth() !== month
}

export function addMonthInTokyuWay (date, months) {
  date = new Date(date)
  const monthsAfter = new Date(date)
  monthsAfter.setMonth(date.getMonth() + months)

  if (date.getDate() !== monthsAfter.getDate()) {
    // this means that the same date does not exist in the specified month so get the last day of the previous month
    monthsAfter.setDate(0)
  }

  if (isLastDayOfMonth(date)) {
    // if this is the last day of month, the N month later date should also be the last day of month.
    // see uat member-164
    monthsAfter.setDate(1)
    monthsAfter.setMonth(monthsAfter.getMonth() + 1)
    monthsAfter.setDate(0)
  }

  return monthsAfter
}
