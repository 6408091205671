import {
  CONFIRM_MFA_STAFF,
  CONFIRM_RESET_PASSWORD_STAFF,
  REQUEST_MFA_STAFF,
  REQUEST_RESET_PASSWORD_STAFF,
  STAFF_PROFILE_IMG_URL
} from '../../../api/graphql/user'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import { apolloClient } from '@/plugins/apollo'
import gql from 'graphql-tag'
import router from '../../../router'

export default {
  async actionGetProfileImgUrl ({ state, commit }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${STAFF_PROFILE_IMG_URL}`,
        variables: {
          id: parseInt(localStorage.getItem('id')) || 1
        },
        fetchPolicy: 'no-cache'
      }).then(result => {
        commit('setProfileImgUrl', result.data.staff.profileImgUrl)
        resolve(result.data.staff.profileImgUrl)
      })
    })
  },

  async requestMFALogin (context, { apollo, email, commit }) {
    const variables = {
      email: email
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${REQUEST_MFA_STAFF}`,
        variables: variables
      }).then(data => {
        context.commit('setShortToken', data.data.requestMFAStaff.token)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, REQUEST_MFA_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async confirmMFALogin (context, { apollo, code, password, commit }) {
    const variables = {
      token: context.getters.shortToken,
      code: code,
      password: password
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${CONFIRM_MFA_STAFF}`,
        variables: variables
      }).then(data => {
        context.commit('setToken', data.data.confirmMFAStaff.accessToken)
        context.commit('setShortToken', null)
        const routedRolePermissions = Object.assign({}, ...data.data.confirmMFAStaff.rolePermissions.map(rolePermission => {
          return {
            [rolePermission.permission]: rolePermission.action
          }
        }))
        context.commit('setUser', {
          ...data.data.confirmMFAStaff.staff,
          role: data.data.confirmMFAStaff.role,
          permissions: routedRolePermissions
        })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CONFIRM_MFA_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async requestResetPassword (context, { apollo, email, commit }) {
    const variables = {
      email: email
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${REQUEST_RESET_PASSWORD_STAFF}`,
        variables: variables
      }).then(data => {
        context.commit('setEmail', email)
        context.commit('setShortToken', data.data.requestResetPasswordStaff.token)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, REQUEST_RESET_PASSWORD_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async confirmResetPassword (context, { apollo, code, password, commit }) {
    const variables = {
      token: context.getters.shortToken,
      code: code,
      password: password
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${CONFIRM_RESET_PASSWORD_STAFF}`,
        variables: variables
      }).then(data => {
        context.commit('setToken', data.data.confirmResetPasswordStaff.accessToken)
        context.commit('setShortToken', null)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CONFIRM_RESET_PASSWORD_STAFF, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  logOut (context) {
    apolloClient.cache.reset()
    const rememberID = window.localStorage.getItem('rememberID')
    const email = window.localStorage.getItem('email')
    localStorage.clear()
    if (rememberID) {
      localStorage.setItem('rememberID', rememberID)
      if (email) {
        localStorage.setItem('email', email)
      }
    }
    context.commit('setToken', null)
    context.commit('setUser', null)
    router.push({ name: 'LoginPMS' })
  }
}
