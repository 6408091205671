import store from '../store'
import {
  MENU_SIDEBAR_PATH
} from '@/constants/enum'

export const checkPermission = (permission, level) => {
  const user = JSON.parse(store.getters.currentUser)
  return user.permissions[permission] === level
}

export const checkPermissionUserCurrent = (routerInfo = {}) => {
  const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
  const permissionUserCurrent = store.getters.permissionUserCurrent
  if (roleAdminUserCurrent) {
    return true
  }
  if (Object.keys(permissionUserCurrent).length !== 0 && !roleAdminUserCurrent) {
    let isPath = false
    let isTab = false
    let fullPermission = false
    for (const [key, value] of Object.entries(permissionUserCurrent)) {
      if (key.toString().includes(routerInfo.path)) {
        isPath = true
        if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
          isTab = true
        }
        if (value === 'W' || value === 'w') {
          fullPermission = true
        }
      }
    }

    if (isPath && isTab && fullPermission) {
      return true
    }
    if (isPath && fullPermission) {
      return true
    }
  }
  return false
}

export const checkPermissionTab = (itemTab = []) => {
  if (itemTab.length > 0) {
    const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
    const permissionUserCurrent = store.getters.permissionUserCurrent
    if (roleAdminUserCurrent) {
      return itemTab
    }
    if (Object.keys(permissionUserCurrent).length !== 0 && !roleAdminUserCurrent) {
      const arrPath = Object.keys(permissionUserCurrent)
      itemTab.map((item) => {
        if (!arrPath.includes(item.pathRoot)) {
          const arrItemTmp = itemTab.filter((rmIte) => rmIte.pathRoot !== item.pathRoot)
          itemTab = arrItemTmp
        }
        return item
      })
      return itemTab
    }
  }
  return []
}

export const checkRouterPrior = (pathRoute = null, routerSub = [], checkPathRoot = null) => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return routerSub[0]
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0) {
      let routeTmp = ''
      for (const nameRouteSub of routerSub) {
        if (Object.keys(permissionUserCurrentPri).includes(nameRouteSub)) {
          routeTmp = nameRouteSub
          break
        }
      }
      return routeTmp
    }
  }
  return ''
}
export const checkRouterPathRoot = () => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return `/${MENU_SIDEBAR_PATH[0]}`
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0) {
      let routeTmp = ''
      for (const nameRouteSub of MENU_SIDEBAR_PATH) {
        for (const [key] of Object.entries(permissionUserCurrentPri)) {
          const arrPathPerUser = key.split('/')
          const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
          if (!checkArrPath && arrPathPerUser[1].includes(nameRouteSub)) {
            routeTmp = `/${nameRouteSub}`
            break
          }
        }
        if (routeTmp) {
          break
        }
      }
      return routeTmp
    }
  }
  return ''
}

export const checkRedirect403 = () => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return MENU_SIDEBAR_PATH.length
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0) {
      let routeTmp = 0
      for (const nameRouteSub of MENU_SIDEBAR_PATH) {
        for (const [key] of Object.entries(permissionUserCurrentPri)) {
          const arrPathPerUser = key.split('/')
          const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
          // if (!checkArrPath && arrPathPerUser[1].includes(nameRouteSub)) {
          //   routeTmp = routeTmp + 1
          // }
          if (!checkArrPath && arrPathPerUser[1] === nameRouteSub) {
            routeTmp = routeTmp + 1
          }
        }
      }
      return routeTmp
    }
    return 0
  }
}

export const matchTokenPermissions = (prev, next) => {
  if (prev.admin !== next.admin) return false
  if (Object.keys(prev.rolePermissions).some(key => prev.rolePermissions[key] !== next.rolePermissions[key]
  )) {
    return false
  }
  if (Object.keys(next.rolePermissions).some(key => next.rolePermissions[key] !== prev.rolePermissions[key]
  )) {
    return false
  }
  return true
}
