import actions from './actions'

const bookingList = {
  state: {
    bookingList: [],
    totalBookinglist: 0,
    hasMoreBookinglist: false,
    listFilter: {
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: null,
      facility: {
        id: null,
        name: '全て'
      },
      canceled: {
        key: false,
        value: 'キャンセル以外'
      },
      bookingType: {
        key: null,
        code: '全て'
      },
      salesChannel: {
        key: null,
        value: '全て'
      },
      orderBy: [
        {
          fieldName: 'booking.checkInDate',
          direction: 'DESC'
        }
      ],
      keyword: null,
      skip: 0,
      take: 100
    }
  },
  getters: {
    getTotalBookinglist (state) {
      return state.totalBookinglist
    },
    getHasMoreBookinglist (state) {
      return state.hasMoreBookinglist
    }
  },
  mutations: {
    setBookingListOrderBy (state, orderBy) {
      state.listFilter.orderBy = orderBy
    },
    setBookingListTake (state, take) {
      state.listFilter.take = take
    },
    setBookingListSkip (state, skip) {
      state.listFilter.skip = skip
    },
    setBookingList (state, arr) {
      state.bookingList = arr
    },
    setTotalBookinglist (state, total) {
      state.totalBookinglist = total
    },
    setHasMoreBookinglist (state, hasMore) {
      state.hasMoreBookinglist = hasMore
    },
    setBookingListFromDate (state, payload) {
      state.listFilter.fromDate = payload
    },
    setBookingListToDate (state, payload) {
      state.listFilter.toDate = payload
    },
    setBookingListFacility (state, payload) {
      state.listFilter.facility = payload
    },
    setBookingListCanceled (state, payload) {
      state.listFilter.canceled = payload
    },
    setBookingListBookingType (state, payload) {
      state.listFilter.bookingType = payload
    },
    setBookingListSalesChannel (state, payload) {
      state.listFilter.salesChannel = payload
    },
    setBookingListKeyword (state, payload) {
      state.listFilter.keyword = payload
    },
    setBookingListPaginateSkip (state, payload) {
      state.listFilter.skip = payload
    },
    setBookingListPaginateTake (state, payload) {
      state.listFilter.take = payload
    }
  },
  actions
}

export default bookingList
