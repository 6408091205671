const invitationsAndCouponsRouter = [
  {
    path: '/invitations-and-coupons'
    // redirect: '/invitations-and-coupons-list'
  },
  {
    path: '/invitations-and-coupons-list',
    component: () => import('../../views/dashboard/invitationsAndCoupons/invitationsAndCouponsList'),
    meta: {
      title: '招待券・利用券'
    }
  },
  {
    path: '/invitations-and-coupons-create',
    component: () => import('../../views/dashboard/invitationsAndCoupons/invitationAndCouponsCreate'),
    meta: {
      isSidebar: true,
      title: '招待券利用新規予約'
    }
  }
]

export default invitationsAndCouponsRouter
