<template>
  <tvos-thousands class="tvos-jpy" :value="value" prefix="¥" />
</template>

<script>
export default {
  props: {
    value: [Number, String]
  }
}
</script>
