import { i18n } from '../../plugins/i18n'

const frameSettingRouter = [
  {
    path: '/frame-setting'
    // redirect: '/frame-setting/calendar'
  },
  {
    path: '/frame-setting/calendar',
    name: i18n.t('routeTitles.frameSetting.frameSettingCalendar'),
    component: () => import('../../views/dashboard/frameSetting/frameSettingCalendar/frameSettingCalendar'),
    meta: {
      title: '枠設定カレンダー'
    }
  },
  {
    path: '/frame-setting/system-outer',
    name: i18n.t('routeTitles.frameSetting.systemOuterLimitSetting'),
    component: () => import('../../views/dashboard/frameSetting/systemOuterFrameSetting/systemOuterFrameSetting'),
    meta: {
      title: 'システム外枠設定'
    }
  }
]

export default frameSettingRouter
