<template>
  <v-dialog v-model="visible" persistent>
    <v-form ref="form">
      <div class="pa-5">
        <h1 class="mb-5">{{title}}</h1>
        <div class="my-5">
          <slot>
            <FormatSpan :value="message" />
          </slot>
        </div>
        <div class="d-flex justify-end">
          <v-btn color="error" @click="close" v-if="type === 'confirm'">
            <v-icon>mdi-close</v-icon>
            {{cancelText}}
          </v-btn>
          <v-btn color="primary" class="ml-4" @click="ok">
            {{ okText }}
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import FormatSpan from '../shared/FormatSpan.vue'

export default {
  components: {
    FormatSpan
  },
  computed: {
    dialog () {
      return this.$store.getters.confirmDialog
    },
    visible: {
      get () { return !!this.$store.getters.confirmDialog },
      set (v) { this.$store.commit('hideConfirmDialog', v) }
    },
    message () {
      return this.dialog?.message
    },
    title () {
      return this.dialog?.title || ''
    },
    cancelText () {
      return this.dialog?.cancelText || 'キャンセル'
    },
    okText () {
      return this.dialog?.okText || 'OK'
    },
    type () {
      return this.dialog?.type
    }
  },
  methods: {
    close () {
      this.visible = false
    },
    ok () {
      this.$store.commit('confirmDialogOkClick')
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121 !important;
  font-weight: bold;
}

::v-deep {
  .v-dialog {
    width: 380px;
    background-color: white;
    border-radius: 9px;
  }
}
</style>
