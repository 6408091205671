export const CREATE_SALE_ITEM = `
  mutation ($data: CreateSaleItemInput!) {
    createSaleItem(data: $data) {
      id
      facility {
        id
        name
      }
      price
    }
  }
`

export const UPDATE_SALE_ITEM = `
  mutation ($id: Int!, $data: UpdateSaleItemInput!) {
    updateSaleItem(id: $id, data: $data) {
      id
      facility {
        id
        name
      }
      price
    }
  }
`

export const DELETE_SALE_ITEM = `
  mutation ($id: Int!) {
    deleteSaleItem(id: $id) {
      id
      facility {
        id
        name
      }
      price
    }
  }
`
