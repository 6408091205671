import actions from './actions'

const reservationBlock = {
  state: {
    roomList: [],
    bookingList: [],
    reservationData: {}
  },

  getters: {
    getRoomListReserBlock (state) {
      return state.roomList
    },
    getBookingReserBlock (state) {
      return state.bookingList
    }
  },

  mutations: {
    setRoomListReserBlock (state, payload) {
      state.roomList = payload
    },
    setBookingReserBlock (state, payload) {
      state.bookingList = payload
    },
    setReservationData (state, payload) {
      state.reservationData = payload
    }

  },
  actions
}

export default reservationBlock
