import actions from './actions'

const facilityHotelTax = {
  state: {

  },
  mutations: {

  },
  actions
}

export default facilityHotelTax
