import { apolloClient } from '@/plugins/apollo'
import { UPDATE_NAME_ABBR } from '@/api/graphql/frameSetting/frame-setting-inventory'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

// INVENTORY_TYPE_LIST,
export default {
  async updateNameAbbr ({ commit, rootState }, item) {
    const variables = {
      id: parseInt(item.id),
      nameAbbr: item.nameAbbr
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_NAME_ABBR}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_NAME_ABBR, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }

}
