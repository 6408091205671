import { apolloClient } from '@/plugins/apollo'
import {
  ROOM_TYPE_LIST, ROOM_LIST, CREATE_BOOKING_V4
} from '@/api/graphql/block/bookingBlock'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import router from '../../../router'
import gql from 'graphql-tag'

export default {
  async roomTypeListBookingBlock ({ commit, state }) {
    const variables = {
      facilityId: parseInt(router.currentRoute.query.facilityID)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ROOM_TYPE_LIST}`,
          variables: variables
        })
        .then(data => {
          resolve(data)
          commit('setRoomTypeBlock', data.data.roomTypeList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ROOM_TYPE_LIST, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async roomListBookingBlock ({ commit, state }) {
    const variables = {
      roomTypeId: parseInt(router.currentRoute.query.roomTypeId)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ROOM_LIST}`,
          variables: variables
        })
        .then(data => {
          resolve(data)
          commit('setRoomListBooking', data.data.roomList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ROOM_LIST, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async createBookingV4BookingBlock ({ commit, state, rootState }, bookingBlockData) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_V4}`,
        variables: bookingBlockData
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        // console.log(11111, router.currentRoute.query.checkInDate, data.data.createBookingV4)
        console.log('router', router.currentRoute.query.night)
        router.push({ path: '/booking/Block-Reservation?checkInDate=' + data.data.createBookingV4.checkInDate + '&roomTypeName=' + router.currentRoute.query.roomTypeName + '&night=' + router.currentRoute.query.night + '&facility=' + router.currentRoute.query.facility + '&id=' + data.data.createBookingV4.id })
        // + '&checkOutDate=' + data.data.createBookingV4.checkOutDate
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_V4, bookingBlockData, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
