const colorCache = {}

function hexToRgb (hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function getColorAndBackground (color, isBackground) {
  if (!color) color = '#FFFFFF'
  if (!color.startsWith('#')) {
    color = '#' + color
  }
  // most of the time we would be dealing with the same color, so make it faster second time
  if (colorCache[color]) return colorCache[color]
  const { r, g, b } = hexToRgb(color)
  let backgroundColor
  if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
    backgroundColor = '#000000'
  } else {
    backgroundColor = '#FFFFFF'
  }

  if (isBackground) {
    const temp = color
    color = backgroundColor
    backgroundColor = temp
  }

  const result = `color: ${color}; background-color: ${backgroundColor};`
  colorCache[color] = result

  return result
}
