export const FACILITY_TYPE_ENUM = {
  TVS施設: 'TVS',
  ホテルコンバージョン施設: 'CNV',
  ポイント提携施設: 'PT'
}

export const FACILITY_TYPE_IN = {
  一泊: 'Day',
  一滞在: 'Stay'
}

export const FACILITY_ENUM = {
  はい: 'Age',
  いいえ: 'School'
}

export const CANCELED_ENUM = [
  {
    key: null,
    value: '全て'
  },
  {
    key: true,
    value: 'キャンセル'
  },
  {
    key: false,
    value: '非キャンセル'
  }
]

export const CANCELED_ENUM_BOOKING_LIST = [
  {
    key: null,
    value: 'キャンセルを含む'
  },
  {
    key: true,
    value: 'キャンセルのみ'
  },
  {
    key: false,
    value: 'キャンセル以外'
  }
]

export const SALES_CHANEL_ENUM = [
  {
    key: null,
    value: '全て'
  },
  {
    key: 'MemberWeb',
    value: 'メンバーズウェブ'
  },
  {
    key: 'OTA',
    value: 'OTA'
  },
  {
    key: 'PMS',
    value: 'PMS'
  }
]
export const SALES_CHANEL_ENUM_BOOKING_LIST = [
  {
    key: null,
    value: '全て'
  },
  {
    key: 'MemberWeb',
    value: '会員Web'
  },
  {
    key: 'PMS',
    value: 'PMS'
  },
  {
    key: 'OTA',
    value: 'OTA全般'
  }
]

export const BOOKING_TYPE_ID_ENUM = [
  {
    key: null,
    value: '全て'
  },
  {
    key: 'SP',
    value: 'SP'
  },
  {
    key: 'FP',
    value: 'FP'
  },
  {
    key: 'DP',
    value: 'DP'
  },
  {
    key: 'TICKET',
    value: 'TICKET'
  },
  {
    key: 'VS',
    value: 'VS'
  },
  {
    key: 'MO',
    value: 'MO'
  },
  {
    key: 'VM',
    value: 'VM'
  },
  {
    key: 'OTA',
    value: 'OTA'
  },
  {
    key: 'PW',
    value: 'PW'
  },
  {
    key: 'MW',
    value: 'MW'
  },
  {
    key: 'OTHER',
    value: 'OTHER'
  },
  {
    key: 'POINT',
    value: 'POINT'
  },
  {
    key: 'TRIAL',
    value: 'TRIAL'
  }
]

export const MAX_INT = 2147483647

export const MENU_SIDEBAR_PATH = ['guest-room-daily-report', 'calendar', 'reservation', 'fees-points-plans', 'invitations-and-coupons', 'frame-setting', 'form-report', 'facility', 'users']

export const GUEST_ROOM_DAILY_REPORT_PATH = '/guest-room-daily-report'
export const GUEST_ROOM_DAILY_REPORT_PATH_SUB = '/guest-room-daily-report/'
export const GUEST_ROOM_DAILY_REPORT_ROUTER_SUB = [
  '/guest-room-daily-report/guest-room-panel',
  '/guest-room-daily-report/daily-report',
  '/guest-room-daily-report/daily-report-list',
  '/guest-room-daily-report/holiday-setting'
]

export const CALENDAR_PATH = '/calendar'
export const CALENDAR_PATH_SUB = '/calendar/'
export const CALENDAR_ROUTER_SUB = [
  '/calendar/room-assignment',
  '/calendar/car-park',
  '/calendar/exhibits-for-hire'
]

export const RESERVATION_PATH = '/reservation'
export const RESERVATION_PATH_SUB = '/reservation/'
export const RESERVATION_ROUTER_SUB = [
  '/reservation/reservation-list',
  '/reservation/send-usage-certificate',
  '/reservation/cancellation-applied'
]

export const FEES_POINTS_PLANS_PATH = '/fees-points-plans'
export const FEES_POINTS_PLANS_PATH_SUB = '/fees-points-plans/'
export const FEES_POINTS_PLANS_ROUTER_SUB = [
  '/fees-points-plans/fees-and-points',
  '/fees-points-plans/plans',
  '/fees-points-plans/csv-update'
]

export const INVITATIONS_AND_COUPONS_PATH = '/invitations-and-coupons'
export const INVITATIONS_AND_COUPONS_PATH_SUB = '/invitations-and-coupons/'
export const INVITATIONS_AND_COUPONS_ROUTER_SUB = [
  '/invitations-and-coupons-list'
]

export const FRAME_SETTING_PATH = '/frame-setting'
export const FRAME_SETTING_PATH_SUB = '/frame-setting/'
export const FRAME_SETTING_ROUTER_SUB = [
  '/frame-setting/calendar',
  '/frame-setting/system-outer'
]

export const FORM_REPORT_PATH = '/form-report'
export const FORM_REPORT_PATH_SUB = '/form-report/'
export const FORM_REPORT_ROUTER_SUB = [
  '/form-report/income-report',
  '/form-report/sales-management',
  '/form-report/other-csv',
  '/form-report/lodging-tax-schedule'
]

export const FACILITY_PATH = '/facility'
export const FACILITY_PATH_SUB = '/facility/'
export const FACILITY_ROUTER_SUB = [
  '/facility/settings',
  '/facility/rental-list',
  '/facility/products-for-sale'
]

export const USER_PATH = '/users'
export const USER_PATH_SUB = '/users/'
export const USER_ROUTER_SUB = [
  '/users/information',
  '/users/list',
  '/users/rights'
]

// screen redirect page crm-pms
export const PAGE_RESERVATION_CALENDAR = '/reservation/calendar'
export const PAGE_RESERVATION_CONTRACT_DETAIL = '/reservation/contract-detail'
export const PAGE_BOOKING_BLOCK_RESERVATION_DETAIL = '/booking/Block-Reservation'
export const PAGE_NEW_RESERVATION_DETAIL = '/reservation/new-reservation'
export const PAGE_USER = '/users'
