import actions from './actions'

const bookingBlock = {
  state: {
    statusCalendar: null,
    roomTypeList: [],
    roomList: [],
    facilityId: null,
    bookingBlockCreate: {}
  },
  getters: {
    getStatusCalendar (state) {
      return state.statusCalendar
    },

    getRoomTypeBlock (state) {
      return state.roomTypeList
    },
    getRoomListBooking (state) {
      return state.roomList
    },
    getFactulityIdBlock (state) {
      return state.facilityId
    }
  },
  mutations: {
    setStatusCalendar (state, payload) {
      state.statusCalendar = payload
    },

    setRoomTypeBlock (state, payload) {
      state.roomTypeList = payload
    },
    setRoomListBooking (state, payload) {
      state.roomList = payload
    },
    setFactulityIdBlock (state, payload) {
      state.roomList = payload
    },
    setBookingBlockCreate (state, payload) {
      state.bookingBlockCreate = payload.bookingBlockCreate
    }
  },
  actions
}
export default bookingBlock
