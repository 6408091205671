import Vue from 'vue'
import { isWarningInventoryType } from '../../../utils/inventory'
import actions from './actions'

const inventory = {
  state: {
    inventory: {
      dateFrom: '',
      dateTo: '',
      inventoryTypeFrom: null,
      inventoryTypeTo: null,
      daysOfWeek: [],
      rooms: null,
      roomTypes: [],
      roomTypeId: null,
      inventoryTypeId: null,
      date: ''
    },
    inventoryTypes: [],
    changeFrom: [],
    status: false,

    selectedInventory: {},
    allowBulkSelect: false,
    lastClickedInventory: null
  },

  getters: {
    getStatusUpdateInven (state) {
      return state.status
    },
    inventoryTypes (state) {
      return state.inventoryTypes
    },
    isExternalInventoryType: state => inventoryTypeId => inventoryTypeId >= 5000,
    normalInventoryTypes: state => state.inventoryTypes.filter(iv => !iv.externallyReserved),
    externalInventoryTypes: state => state.inventoryTypes.filter(iv => iv.externallyReserved),
    getInventoryWarning: state => days => {
      const messages = days.map(day => {
        if (isWarningInventoryType(day.inventoryTypeId)) {
          return state.inventoryTypes.find(iv => iv.id === day.inventoryTypeId)?.nameAbbr
        }
        return null
      }).filter(msg => !!msg)

      if (messages.length) {
        const message = [...new Set(messages)].join('・')
        return message + '枠が指定されています。予約を続行しますか？'
      } else {
        return ''
      }
    },
    inventoryCountsToArray: (state, getters) => (invCounts) => {
      const result = []
      for (const inv of getters.normalInventoryTypes) {
        result.push({
          ...inv,
          count: invCounts[inv.id]
        })
      }
      result.push({
        ...getters.externalInventoryTypes[0],
        nameAbbr: 'シ外',
        count: invCounts.ext
      })
      return result
    },
    lastClickedInventory: state => state.lastClickedInventory,
    selectedInventoryArray: state => Object.values(state.selectedInventory),
    allowBulkSelect: state => state.allowBulkSelect,
    isInventorySelected: state => ({ ind, inventory }) => {
      const key = inventoryKey(inventory, ind)
      return !!state.selectedInventory[key]
    }
  },

  mutations: {
    setInventory (state, payload) {
      state.inventory = payload.inventory
    },
    setChangeFrom (state, payload) {
      state.changeFrom = payload.changeFrom
    },
    setChangeStatus (state, payload) {
      state.status = !state.status
    },
    setInventoryTypes (state, inventoryTypes) {
      state.inventoryTypes = inventoryTypes.map(inv => ({
        ...inv,
        nameAbbr: inv.nameAbbr || inv.name
      }))
    },
    setAllowBulkSelect (state, enabled) {
      this.commit('setLastClickedInventory', null)
      state.selectedInventory = {}
      state.allowBulkSelect = enabled
    },
    setLastClickedInventory (state, payload) {
      state.lastClickedInventory = payload
    },
    toggleSelectedInventory (state, { ind, inventory }) {
      const obj = {
        ind,
        inventory
      }
      this.commit('setLastClickedInventory', obj)
      if (state.allowBulkSelect) {
        const key = inventoryKey(inventory, ind)
        if (state.selectedInventory[key]) {
          Vue.delete(state.selectedInventory, key)
        } else {
          Vue.set(state.selectedInventory, key, obj)
        }
      }
    }
  },
  actions
}

function inventoryKey (inventory, ind) {
  const { roomTypeId, inventoryTypeId, stayDate } = inventory
  return `${roomTypeId}-${stayDate}-${inventoryTypeId}-${ind}`
}

export default inventory
