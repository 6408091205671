import { apolloClient } from '@/plugins/apollo'
import {
  ADD_PARKING_RESERVATION, UPDATE_PARKING_RESERVATION_BULK
} from '@/api/graphql/bookingDetail/booking-detail-parking'
import router from '@/router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async addParkingReservation ({ commit, state, rootState }) {
    const variables = {
      bookingId: parseInt(router.currentRoute.query.id),
      parkings: {
        parkingId: parseInt(state.bookingParking.parkingId),
        fromDate: state.bookingParking.fromDate,
        toDate: state.bookingParking.toDate,
        carNumber: state.bookingParking.carNumber,
        color: state.bookingParking.color,
        height: parseInt(state.bookingParking.height)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${ADD_PARKING_RESERVATION}`,
        variables: variables
      }).then(data => {
        commit('setChangeStatus', true)
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, ADD_PARKING_RESERVATION, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateParkingReservationBulk ({ commit, state, rootState }) {
    const variables = {
      reservations: state.reservations,
      waitLists: state.waitlists
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_PARKING_RESERVATION_BULK}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        commit('setReloadDataParkingPlace', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_PARKING_RESERVATION_BULK, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
