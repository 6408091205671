import { CREATE_FACILITY_PARKING, FACILITY_PARKING_LIST, UPDATE_FACILITY_PARKING, DELETE_FACILITY_PARKING } from '@/api/graphql/facility-item-parking'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createFacilityParking ({ state, commit, rootState }) {
    const variables = {
      data: {
        facilityId: parseInt(state.facilityItemParking.facilityId),
        name: state.facilityItemParking.name,
        maxHeight: parseInt(state.facilityItemParking.maxHeight),
        quantity: parseInt(state.facilityItemParking.quantity),
        allowCancelWait: state.facilityItemParking.allowCancelWait
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_FACILITY_PARKING}`,
        variables: variables,
        update: (store, { data: { createFacilityParking } }) => {
          const data = store.readQuery({
            query: gql`${FACILITY_PARKING_LIST}`,
            variables: {
              facilityId: parseInt(router.currentRoute.query.id)
            }
          })
          data.facilityParkingList.push(createFacilityParking)
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_FACILITY_PARKING, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateFacilityParking ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(state.facilityItemParking.id),
      data: {
        name: state.facilityItemParking.name,
        maxHeight: parseInt(state.facilityItemParking.maxHeight),
        quantity: parseInt(state.facilityItemParking.quantity),
        allowCancelWait: state.facilityItemParking.allowCancelWait
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_FACILITY_PARKING}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_FACILITY_PARKING, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteFacilityParking ({ state, commit, rootState }) {
    const variables = {
      facilityId: parseInt(router.currentRoute.query.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_FACILITY_PARKING}`,
        variables: {
          id: parseInt(state.facilityItemParking.id)
        },
        update: (store) => {
          const data = store.readQuery({
            query: gql`${FACILITY_PARKING_LIST}`,
            variables: variables
          })
          data.facilityParkingList = data.facilityParkingList.filter(item => item.id !== state.facilityItemParking.id)
          store.writeQuery({
            query: gql`${FACILITY_PARKING_LIST}`,
            variables: {
              facilityId: parseInt(router.currentRoute.query.id)
            },
            data
          })
          commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_FACILITY_PARKING, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
