import {
  CREATE_FACILITY_HOTEL_TAX_AMOUNT, DELETE_FACILITY_HOTEL_TAX_AMOUNT,
  UPDATE_FACILITY_HOTEL_TAX_AMOUNT
} from '../../../api/graphql/facility-hotel-tax-amount'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createFacilityHotelTaxAmount (context, { apollo, facilityHotelTaxAmount, commit }) {
    const variables = {
      data: facilityHotelTaxAmount
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${CREATE_FACILITY_HOTEL_TAX_AMOUNT}`,
        variables: variables
      }).then(data => {
        console.log('data', data)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_FACILITY_HOTEL_TAX_AMOUNT, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateFacilityHotelTaxAmount (context, { apollo, id, facilityHotelTaxAmount, commit }) {
    const variables = {
      id,
      data: facilityHotelTaxAmount
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${UPDATE_FACILITY_HOTEL_TAX_AMOUNT}`,
        variables: variables
      }).then(data => {
        console.log('data', data)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_FACILITY_HOTEL_TAX_AMOUNT, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteFacilityHotelTaxAmount (context, { apollo, id, commit }) {
    const variables = {
      id
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: gql`${DELETE_FACILITY_HOTEL_TAX_AMOUNT}`,
        variables: variables
      }).then(data => {
        console.log('data', data)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_FACILITY_HOTEL_TAX_AMOUNT, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
