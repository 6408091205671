export const BOOKING_PLAN_LIST = `
  query($filter: BookingPlanListFilterInput!, $pagination: PaginationInput) {
    bookingPlanList(filter: $filter, pagination: $pagination) {
      items {
        id
        # Data ======================================= // for update //
        enabled
        imagePublicUrl1
        imagePublicUrl2
        webPublicFromDate
        webPublicToDate
        title
        subtitle
        targetBookingType
        remarks
        description
        roomType {
          id
          name
          facilityId
          facility {
            name
          }
        }
        createdByName
        availableFromDate
        availableToDate
        additionalChargeType
        fixedAdditionalCharge
        fixedAdditionalChargeChild
        stopSaleDay
        stopSaleTime
        bookThroughDays
        bookingFromDate
        bookingToDate
        guestCountLimit
        roomCountLimit
        minGuestCount
        maxGuestCount
        minDays
        maxDays
        # discountData ------------
        discountType
        day1Discount
        day2Discount
        day3Discount
        day4Discount
        day5Discount
        day6Discount
        day7Discount
        day8Discount
        day9Discount
        continueDiscountAfterDay9
        # -------------------------
        # penaltyData --------
        overrideCancelPolicy
        noShowPenalty
        day0Penalty
        threshold1Day
        threshold1Penalty
        threshold2Day
        threshold2Penalty
        threshold3Day
        threshold3Penalty
        threshold4Day
        threshold4Penalty
        threshold5Day
        threshold5Penalty
        threshold6Day
        threshold6Penalty
        threshold7Day
        threshold7Penalty
        # --------------------
        # availableWeekDays --------
        availableSunday
        availableMonday
        availableTuesday
        availableWednesday
        availableThursday
        availableFriday
        availableSaturday
        # --------------------------
        # question-1 ----------
        question1Text
        question1Select1
        question1Select2
        question1Select3
        question1Select4
        question1Select5
        question1Type
        question1Required
        # ---------------------
        # question-2 ----------
        question2Text
        question2Select1
        question2Select2
        question2Select3
        question2Select4
        question2Select5
        question2Type
        question2Required
        # ---------------------
        # question-3 ----------
        question3Text
        question3Select1
        question3Select2
        question3Select3
        question3Select4
        question3Select5
        question3Type
        question3Required
        # ---------------------
        # question-4 ----------
        question4Text
        question4Select1
        question4Select2
        question4Select3
        question4Select4
        question4Select5
        question4Type
        question4Required
        # ---------------------
        # question-5 ----------
        question5Text
        question5Select1
        question5Select2
        question5Select3
        question5Select4
        question5Select5
        question5Type
        question5Required
        # ---------------------
      }
      total
    }
  }
`

export const CREATE_BOOKING_PLAN = `
  mutation(
    $enabled: Boolean!
    $title: String!
    $subtitle: String!
    $targetBookingType: TargetBookingType!
    $roomTypeId: Int!
    $imagePublicUrl1: String
    $imagePublicUrl2: String
    $description: String
    $remarks: String
    $questions: [BookingPlanQuestionInput!]!
    $createdByName: String!
    $chargeType: BookingPlanAdditionalChargeType!
    $chargeAmount: Int!
    $stopSaleDay: Int!
    $stopSaleTime: String!
    $availableFromDate: DateString!
    $availableToDate: DateString!
    $bookThroughDays: Int!
    $webPublicFromDate: DateString!
    $webPublicToDate: DateString!
    $bookingFromDate: DateString!
    $bookingToDate: DateString!
    $guestCountLimit: Int!
    $roomCountLimit: Int!
    $discountType: BookingPlanDiscountType!
    $day1Discount: Int!
    $day2Discount: Int!
    $day3Discount: Int!
    $day4Discount: Int!
    $day5Discount: Int!
    $day6Discount: Int!
    $day7Discount: Int!
    $day8Discount: Int!
    $day9Discount: Int!
    $continueDiscountAfterDay9: Boolean!
    $minGuestCount: Int!
    $maxGuestCount: Int!
    $minDays: Int!
    $maxDays: Int!
    $overrideCancelPolicy: Boolean!
    $noShowPenalty: Float
    $day0Penalty: Float
    $threshold1Day: Int
    $threshold1Penalty: Float
    $threshold2Day: Int
    $threshold2Penalty: Float
    $threshold3Day: Int
    $threshold3Penalty: Float
    $threshold4Day: Int
    $threshold4Penalty: Float
    $threshold5Day: Int
    $threshold5Penalty: Float
    $threshold6Day: Int
    $threshold6Penalty: Float
    $threshold7Day: Int
    $threshold7Penalty: Float
    $availableWeekDays: CreateBookingPlanAvailableWeekDaysInput!
    $csvFileS3Key: String
  ) {
    createBookingPlan(
      data: {
        csvFileS3Key: $csvFileS3Key
        enabled: $enabled
        title: $title
        subtitle: $subtitle
        targetBookingType: $targetBookingType
        roomTypeId: $roomTypeId
        publicImagesUrlData: {
          imagePublicUrl1: $imagePublicUrl1
          imagePublicUrl2: $imagePublicUrl2
        }
        remarks: $remarks
        description: $description
        questions: $questions
        createdByName: $createdByName
        additionalChargeType: $chargeType
        fixedAdditionalCharge: $chargeAmount
        fixedAdditionalChargeChild: $chargeAmount
        stopSaleDay: $stopSaleDay
        stopSaleTime: $stopSaleTime
        availableFromDate: $availableFromDate
        availableToDate: $availableToDate
        bookThroughDays: $bookThroughDays
        webPublicFromDate: $webPublicFromDate
        webPublicToDate: $webPublicToDate
        bookingFromDate: $bookingFromDate
        bookingToDate: $bookingToDate
        guestCountLimit: $guestCountLimit
        roomCountLimit: $roomCountLimit
        discountData: {
          type: $discountType
          day1Discount: $day1Discount
          day2Discount: $day2Discount
          day3Discount: $day3Discount
          day4Discount: $day4Discount
          day5Discount: $day5Discount
          day6Discount: $day6Discount
          day7Discount: $day7Discount
          day8Discount: $day8Discount
          day9Discount: $day9Discount
          continueDiscountAfterDay9: $continueDiscountAfterDay9
        }
        minGuestCount: $minGuestCount
        maxGuestCount: $maxGuestCount
        minDays: $minDays
        maxDays: $maxDays
        penaltyData: {
          overrideCancelPolicy: $overrideCancelPolicy
          noShowPenalty: $noShowPenalty
          day0Penalty: $day0Penalty
          threshold1Day: $threshold1Day
          threshold1Penalty: $threshold1Penalty
          threshold2Day: $threshold2Day
          threshold2Penalty: $threshold2Penalty
          threshold3Day: $threshold3Day
          threshold3Penalty: $threshold3Penalty
          threshold4Day: $threshold4Day
          threshold4Penalty: $threshold4Penalty
          threshold5Day: $threshold5Day
          threshold5Penalty: $threshold5Penalty
          threshold6Day: $threshold6Day
          threshold6Penalty: $threshold6Penalty
          threshold7Day: $threshold7Day
          threshold7Penalty: $threshold7Penalty
        }
        availableWeekDays: $availableWeekDays
      }
    ) {
      id
    }
  }
`

export const DELETE_BOOKING_PLAN = `
  mutation($id: Int!) {
    deleteBookingPlan(id: $id) {
      __typename
    }
  }
`

export const UPDATE_BOOKING_PLAN = `
mutation (
  $id: Int! #ID
  $enabled: Boolean! #true: 有効
  $title: String!#名称
  $subtitle: String! #サブタイトル
  $targetBookingType: TargetBookingType! #選択肢は VS, MO, SP, FP のいずれか
  $roomTypeId: Int!#部屋タイプ
  $imagePublicUrl1: String #プラン画像URL1枚め
  $imagePublicUrl2: String #プラン画像URL2枚め
  $description: String #プランPR文
  $remarks: String #プランの注意事項
  $questions: [BookingPlanQuestionInput!]! #質問 具体的な形式は以下
  $createdByName: String!
  $chargeType: BookingPlanAdditionalChargeType! #料金設定方法、1室1滞在:RoomStay、1名1滞在:PersonStay, 1室1泊: RoomDay, 1名1泊:PersonDay の値を送る
  $chargeAmount: Int! #料金（1室1滞在、1名1滞在）、それ以外の料金設定の場合は0を入れること
  $stopSaleDay: Int! #手仕舞い設定、日数部分
  $stopSaleTime: String! #手仕舞い設定時刻部分
  $availableFromDate: DateString! #宿泊可能期間開始日
  $availableToDate: DateString! #宿泊機能期間終了日
  $bookThroughDays: Int! #予約を行う日からN日先まで表示可能のNの数字
  $webPublicFromDate: DateString! #会員ページ掲載期間開始日
  $webPublicToDate: DateString! #会員ページ掲載期間終了日
  $bookingFromDate: DateString! #予約可能期間開始日
  $bookingToDate: DateString! #予約可能期間終了日
  $guestCountLimit: Int! #売り止設定、一日の上限利用人数
  $roomCountLimit: Int! #売り止設定、１日の上限室数
  ### 宿泊料金割引設定項目。「設定しない」が選択された場合、全ての割引を0として送ること
  $discountType: BookingPlanDiscountType! #円/Pt引き：Fixed、％引き:Percent。設定しないがん選択されたらどちらでも良いが、とりあえずFixed
  $day1Discount: Int! #1泊目の割引。設定がない場合は0にする。
  $day2Discount: Int! #2泊目の割引。設定がない場合は0にする。
  $day3Discount: Int! #3泊目の割引。設定がない場合は0にする。
  $day4Discount: Int! #4泊目の割引。設定がない場合は0にする。
  $day5Discount: Int! #5泊目の割引。設定がない場合は0にする。
  $day6Discount: Int! #6泊目の割引。設定がない場合は0にする。
  $day7Discount: Int! #7泊目の割引。設定がない場合は0にする。
  $day8Discount: Int! #8泊目の割引。設定がない場合は0にする。
  $day9Discount: Int! #9泊目の割引。設定がない場合は0にする。
  $continueDiscountAfterDay9: Boolean! #9泊目以降は9泊目の割引を適用するチェック
  $minGuestCount: Int! #人数設定、最小利用人数
  $maxGuestCount: Int! #人数設定、最大利用人数
  $minDays: Int! #泊数限定、最小値
  $maxDays: Int! #泊数限定、最大値
  $overrideCancelPolicy: Boolean! #宿泊料金キャンセルポリシー、設定する:true, 設定しない:false
  $noShowPenalty: Float #連絡なしのペナルティ、0~1のFloatを送ること（例：50% ->0.5）
  $day0Penalty: Float #当日のペナルティ
  $threshold1Day: Int #N日前の設定、当日の一つ前の欄
  $threshold1Penalty: Float #当日の一つ前のペナルティ
  $threshold2Day: Int #N日前の設定、当日の2つ前の欄
  $threshold2Penalty: Float #当日の2つ前のペナルティ
  $threshold3Day: Int #N日前の設定、当日の3つ前の欄
  $threshold3Penalty: Float #当日の3つ前のペナルティ
  $threshold4Day: Int #N日前の設定、当日の4つ前の欄
  $threshold4Penalty: Float #当日の4つ前のペナルティ
  $threshold5Day: Int #N日前の設定、当日の5つ前の欄
  $threshold5Penalty: Float #当日の5つ前のペナルティ
  $threshold6Day: Int #N日前の設定、当日の6つ前の欄
  $threshold6Penalty: Float #当日の6つ前のペナルティ
  $threshold7Day: Int #N日前の設定、当日の7つ前の欄
  $threshold7Penalty: Float #当日の7つ前のペナルティ
  $availableWeekDays: CreateBookingPlanAvailableWeekDaysInput! #曜日の選択（日月火水木金土の順で利用可能曜日をTrueとし、[true, false, true, true, false, true, true]という形でArrayを送る
  ## モック上はないため、利用可能曜日というラベルと 日月火水木金土 のチェックボックスを追加して表示すること
  $csvFileS3Key: String # 上のAPIでUploadしたCSVファイルのKey
) {
  updateBookingPlan(
    id: $id
    data: {
    csvFileS3Key: $csvFileS3Key
    enabled: $enabled
    title: $title
    subtitle: $subtitle
    targetBookingType: $targetBookingType
    roomTypeId: $roomTypeId
    publicImagesUrlData:{
      imagePublicUrl1: $imagePublicUrl1
      imagePublicUrl2: $imagePublicUrl2
    }
    remarks: $remarks
    description: $description
    questions: $questions
    createdByName: $createdByName
    additionalChargeType: $chargeType
    fixedAdditionalCharge: $chargeAmount
    fixedAdditionalChargeChild: $chargeAmount
    stopSaleDay: $stopSaleDay
    stopSaleTime: $stopSaleTime
    availableFromDate: $availableFromDate
    availableToDate: $availableToDate
    bookThroughDays: $bookThroughDays
    webPublicFromDate: $webPublicFromDate
    webPublicToDate: $webPublicToDate
    bookingFromDate: $bookingFromDate
    bookingToDate:$bookingToDate
    guestCountLimit: $guestCountLimit
    roomCountLimit: $roomCountLimit
    discountData: {
      type: $discountType
      day1Discount: $day1Discount
      day2Discount: $day2Discount
      day3Discount: $day3Discount
      day4Discount: $day4Discount
      day5Discount: $day5Discount
      day6Discount: $day6Discount
      day7Discount: $day7Discount
      day8Discount: $day8Discount
      day9Discount: $day9Discount
      continueDiscountAfterDay9: $continueDiscountAfterDay9
    }
    
    minGuestCount: $minGuestCount
    maxGuestCount: $maxGuestCount
    minDays: $minDays
    maxDays: $maxDays
    penaltyData: {
      overrideCancelPolicy: $overrideCancelPolicy
      noShowPenalty: $noShowPenalty
      day0Penalty: $day0Penalty
      threshold1Day: $threshold1Day
      threshold1Penalty: $threshold1Penalty
      threshold2Day: $threshold2Day
      threshold2Penalty: $threshold2Penalty
      threshold3Day: $threshold3Day
      threshold3Penalty: $threshold3Penalty
      threshold4Day: $threshold4Day
      threshold4Penalty: $threshold4Penalty
      threshold5Day: $threshold5Day
      threshold5Penalty: $threshold5Penalty
      threshold6Day: $threshold6Day
      threshold6Penalty: $threshold6Penalty
      threshold7Day: $threshold7Day
      threshold7Penalty: $threshold7Penalty
    }
    availableWeekDays: $availableWeekDays
    
  }) {
    __typename
  }
}
`
