import actions from './actions'

const facilityRoom = {
  state: {
    facilityRoom: {
      id: null,
      name: '',
      roomTypeId: null,
      order: null
    }

  },
  mutations: {
    setFacilityRoom (state, payload) {
      state.facilityRoom = payload.facilityRoom
    }
  },
  actions
}

export default facilityRoom
