import { apolloClient } from '@/plugins/apollo'
import {
  BOOKING_BASIC_UPDATE
} from '@/api/graphql/bookingDetail/booking-detail-basic'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async bookingBasicUpdate ({ commit, state, rootState }) {
    const {
      representative, ...rest
    } = state.bookingBasicInfomation
    const {
      passportPhotoUrl, // we must remove this value!
      ...rep
    } = representative
    rest.representative = rep
    const variables = {
      basicUpdateInput: rest
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${BOOKING_BASIC_UPDATE}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, BOOKING_BASIC_UPDATE, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
