<template>
  <div class="full-screen-container" :style="{height: maxHeightStyle, maxHeight: maxHeightStyle}">
    <slot :height="maxHeight - 10" /><!-- 10px of margin just in case -->
  </div>
</template>

<script>
export default {
  data () {
    return { maxHeight: null }
  },
  computed: {
    maxHeightStyle () {
      return `${this.maxHeight - 5}px`
    }
  },
  mounted () {
    // determine the the height to fill the container
    const { top } = this.$el.getBoundingClientRect()
    this.maxHeight = window.innerHeight - top
  }
}
</script>

<style lang="scss" scoped>
.full-screen-container {
  overflow: auto;
}
</style>
