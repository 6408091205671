import gql from 'graphql-tag'

export const CREATE_BOOKING_V4 = `
  mutation(
    $bookingTypeId: Int
    $days: [BookingDayInput!]!
    $ticketId: String
    $roomTypeId: Int!
    $clientId: Int

    $checkInDate: DateString!
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfChildrenWithBedShare: Int

    $bookingBarNote: String
    $representativeName: String
    $representativeKana: String
    $representativeTel: String
    $representativeEmail: String
    $representativeCountry: String
    $representativePostalCode: String
    $representativeAddress1: String

    $bookingGroupId: Int
    $bookingPlanId: Int
    $force: Boolean
    $contractId: Int
    $priorityBooking: Boolean
    $createdClientId: Int
    $createdSubMemberId: Int
    $representativeClientId: Int
    $representativeSubMemberId: Int
    $bookingPrePayment: Int
    $parkings: [AddReservationFacilityParkingInput!]
  ) {
    createBookingV4(
      bookingTypeId: $bookingTypeId
      days: $days
      ticketId: $ticketId
      roomTypeId: $roomTypeId
      clientId: $clientId
      
      

      checkInDate: $checkInDate
      numberOfAdults: $numberOfAdults
      numberOfChildren: $numberOfChildren
      numberOfChildrenWithBedShare: $numberOfChildrenWithBedShare

      bookingBarNote: $bookingBarNote
      representativeName: $representativeName
      representativeKana: $representativeKana
      representativeTel: $representativeTel
      representativeEmail: $representativeEmail
      representativeCountry: $representativeCountry
      representativePostalCode: $representativePostalCode
      representativeAddress1: $representativeAddress1
      
      bookingGroupId: $bookingGroupId
      bookingPlanId: $bookingPlanId
      force: $force
      contractId: $contractId
      priorityBooking: $priorityBooking
      createdClientId: $createdClientId
      createdSubMemberId: $createdSubMemberId
      representativeClientId: $representativeClientId
      representativeSubMemberId: $representativeSubMemberId
      bookingPrePayment: $bookingPrePayment
      parkings: $parkings
    ) {
      id
      bookingGroupId
      bookingTypeId
    }
  }
`

export const FACLILITY_LIST = gql`
query {
  facilityList (loadRoomType: true) {
    id
    name
    roomTypes {
      id
      name
    }
  }
 }
`

export const CREATE_BOOKING_NO_CUSTOMER_ID = `
  mutation(
    $days: [BookingDayInput!]!
    $bookingTypeId: Int

    $priorityBooking: Boolean
    $roomTypeId: Int!
    $checkInDate: DateString!
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfChildrenWithBedShare: Int

    $bookingBarNote: String
    $representativeName: String
    $representativeKana: String
    $representativeTel: String
    $representativeEmail: String
    $representativeAddress1: String
  ) {
    createBookingV4(
      days: $days
      bookingTypeId: $bookingTypeId

      priorityBooking: $priorityBooking
      roomTypeId: $roomTypeId
      checkInDate: $checkInDate
      numberOfAdults: $numberOfAdults
      numberOfChildren: $numberOfChildren
      numberOfChildrenWithBedShare: $numberOfChildrenWithBedShare

      bookingBarNote: $bookingBarNote
      representativeName: $representativeName 
      representativeKana: $representativeKana
      representativeTel: $representativeTel
      representativeEmail: $representativeEmail
      representativeAddress1: $representativeAddress1
    ) {
      id
      bookingTypeId
      bookingGroupId
    }
  }
`
