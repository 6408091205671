export const ROOM_TYPE_LIST = `
  query($facilityId: Int!) {
    roomTypeList(facilityId: $facilityId) {
      id
      name
      backgroundColor
    }
  }
`

export const CREATE_ROOM_TYPE = `
  mutation ($data: CreateRoomTypeInput! ){
    createRoomType(data: $data) {
      name
      id
      order
      maxOccupancy
      bedShareMaxOccupancy
    }
  }
`

export const UPDATE_ROOM_TYPE = `mutation ($id: Int!, $data: UpdateRoomTypeInput!){
  updateRoomType(id: $id, data:$data) {
    id name order backgroundColor maxOccupancy bedShareMaxOccupancy rakutsuId facilityId
  }
}`

export const DELETE_ROOM_TYPE = `mutation ($id: Int!){
  deleteRoomType(id: $id) {
    name
  }
}`
