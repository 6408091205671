import { ROOM_LIST, CREATE_ROOM, UPDATE_ROOM, DELETE_ROOM } from '@/api/graphql/facility-room'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createRoom ({ state, commit, rootState }) {
    const variables = {
      data: {
        name: state.facilityRoom.name,
        roomTypeId: parseInt(state.facilityRoom.roomTypeId)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_ROOM}`,
        variables: variables,
        update: (store, { data: { createRoom } }) => {
          const data = store.readQuery({
            query: gql`${ROOM_LIST}`,
            variables: {
              roomTypeId: parseInt(router.currentRoute.query.id)
            }
          })
          data.roomList.unshift(createRoom)
          store.writeQuery({
            query: gql`${ROOM_LIST}`,
            variables: {
              roomTypeId: parseInt(router.currentRoute.query.id)
            },
            data
          })
        }
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        const routeData = '/facility/settings/room-type/rooms/detail?id=' + data.data.createRoom.id
        window.open(routeData, '_blank')
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_ROOM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateRoom ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id),
      data: {
        name: state.facilityRoom.name,
        order: parseInt(state.facilityRoom.order)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_ROOM}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        setTimeout(() => {
          window.close()
        }, 500)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_ROOM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },
  async updateRoomHold ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id),
      data: {
        name: state.facilityRoom.name,
        order: parseInt(state.facilityRoom.order)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_ROOM}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_ROOM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },
  async deleteRoom ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_ROOM}`,
        variables: variables,
        update: (store) => {
          const data = store.readQuery({
            query: gql`${ROOM_LIST}`,
            variables: {
              roomTypeId: parseInt(state.facilityRoom.roomTypeId)
            }
          })
          data.roomList = data.roomList.filter(item => item.id !== state.facilityRoom.id)
          commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        }
      }).then(data => {
        window.close()
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_ROOM, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
