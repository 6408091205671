export const CREATE_FACILITY_HOTEL_TAX_AMOUNT = `
  mutation ($data: CreateFacilityHotelTaxAmountInput!) {
    createFacilityHotelTaxAmount(data: $data) {
      id
      facilityHotelTax {
        effectiveFrom
        id
        facility {
          id
          name
        }
      }
      priceFrom
      taxAmount
    }
  }
`

export const UPDATE_FACILITY_HOTEL_TAX_AMOUNT = `
  mutation ($id: Float!, $data: UpdateFacilityHotelTaxAmountInput!) {
    updateFacilityHotelTaxAmount(id: $id, data: $data) {
      id
      facilityHotelTax {
        effectiveFrom
        id
        facility {
          id
          name
        }
      }
      priceFrom
      taxAmount
    }
  }
`

export const DELETE_FACILITY_HOTEL_TAX_AMOUNT = `
  mutation ($id: Float!) {
    deleteFacilityHotelTaxAmount(id: $id) {
      id
      facilityHotelTax {
        effectiveFrom
        id
        facility {
          id
          name
        }
      }
      priceFrom
      taxAmount
    }
  }
`
