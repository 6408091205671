import actions from './actions'

const staffRole = {
  state: {
    staffRole: {
      id: null,
      name: '',
      readPermissionKeys: [],
      writePermissionKeys: []
    }

  },
  mutations: {
    setStaffRole (state, payload) {
      state.staffRole = payload.staffRole
    }
  },
  actions
}

export default staffRole
