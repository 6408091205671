export function fileLimit ({ size, type, multiple } = {}) {
  const sizeText = size !== null && typeof size !== 'undefined' ? `${size}まで` : '規制なし'
  const typeText = type ? `${type}` : '規制なし'
  let base = `ファイルのサイズ: ${sizeText}、ファイルの種類: ${typeText}`
  if (multiple) {
    let maxNumber = '規制なし'
    if (typeof multiple === 'number') {
      maxNumber = `${multiple}まで`
    }
    base += `、ファイルの数: ${maxNumber}`
  }

  return base
}
