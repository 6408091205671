import actions from './actions'

const facilityBookingRemark = {
  state: {
    facilityBookingRemark: {
      bookingType: {
        id: null,
        code: ''
      },
      remarks: ''
    },
    facilityBookingRemarksList: []
  },
  mutations: {
    setBookingRemark (state, payload) {
      state.facilityBookingRemark = payload.facilityBookingRemark
    },
    setFacilityBookingRemarksList (state, payload) {
      state.facilityBookingRemarksList = payload
    }
  },
  actions
}

export default facilityBookingRemark
