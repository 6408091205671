import actions from './actions'

const bookingRental = {
  state: {
    bookingRental: {
      fromDate: '',
      quantity: null,
      rentalItemId: null,
      toDate: ''
    },
    updateBookingRental: {
      rentalItemReservationId: null,
      newFromDate: '',
      newToDate: '',
      newQuantity: null
    },
    deleteBookingRental: {
      rentalItemReservationId: null
    },
    createBookingRental: {
      status: false
    },
    statusUpdate: true
  },
  getters: {
    getStatusCreate (state) {
      return state.createBookingRental
    },
    getStatusUpdate (state) {
      return state.statusUpdate
    }
  },

  mutations: {
    setBookingRental (state, payload) {
      state.bookingRental = payload.bookingRental
    },
    setStatusUpdate (state, payload) {
      state.statusUpdate = payload
    },
    setUpdateBookingRental (state, payload) {
      state.updateBookingRental = payload.updateBookingRental
    },
    setDeleteBookingRental (state, payload) {
      state.deleteBookingRental = payload.deleteBookingRental
    },
    setCreateBookingRental (state, payload) {
      state.createBookingRental = !state.createBookingRental
    }
  },

  actions
}

export default bookingRental
