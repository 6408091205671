<template>
  <main-layout v-if="!isSidebar">
    <router-view></router-view>
    <global-notifications />
    <confirm-dialog />
  </main-layout>
  <v-main v-else>
    <router-view></router-view>
    <global-notifications />
    <confirm-dialog />
  </v-main>
</template>

<script>
import MainLayout from '../layouts/MainLayout'
import { mapGetters, mapMutations } from 'vuex'
import GlobalNotifications from './GlobalNotifications.vue'
import ConfirmDialog from '../components/dialog/ConfirmDialog.vue'

export default {
  components: { MainLayout, GlobalNotifications, ConfirmDialog },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay']),
    isSidebar () {
      return this.$route.meta.isSidebar
    },
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },
  data: () => {
    return {
      zIndex: 99999999
    }
  },
  methods: {
    ...mapMutations(['setAlertSuccess', 'setAlertError', 'setErrorDialog']),
    onSidebarStateChange (value) {
      this.isSidebarOpen = value
    }
  }
}
</script>

<style scoped lang="scss">
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
.alert-default {
  position: fixed;
  width: 300px;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  &.--center {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep {
  .error-dialog {
    .v-alert__content {
      max-width: 100% !important;
      word-break: break-word !important;
    }
  }
}
</style>
