import gql from 'graphql-tag'

export const BOOKING = gql`query($id: Int!) {
    booking(id: $id) {
      checkInDate
      checkOutDate
      permanentBlock
      guests {
        clientId
        id
        representative
        name
        child
        bedShared
        address1
        occupation
        age
        sex
        email
        tel
        nationality
        passportPhoto
        passportPhotoUrl
        passportNumber
      }
      
      guestSchedules {
        stayDate
        guestId
        stay
        bathing
      }

      room {
        roomType {
          name 
          maxOccupancy
          facility{
            showBathingSchedule
          }
        }
      }
    }
  }`

export const CLIENT_MEMBER_INFO = gql`
query ($id: Int!) {
  clientMemberInfo(id: $id) {
    id
    name
    nameKana
    mainContact {
      tel
      email
      prefecture
      locality
      address1
      address2
    }
    subMembers{
      id
      subMemberTypeId
      name
      nameKana
      tel
      email
      prefecture
      locality
      address1
      address2
    }
  }
}
`

export const BOOKING_GUEST_UPDATE = `
mutation (
  $bookingId: Int!
  $guestWithSchedule: [BookingGuestWithScheduleInput!]!
  $repSchedules: [BookingGuestScheduleInput!]
) {
  bookingGuestUpdate(bookingId: $bookingId, data: $guestWithSchedule, representativeSchedule: $repSchedules) {
    __typename
    needsTvpAdjustment
  }
}
`
