import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import * as filters from './utils/filters'
import { i18n } from './plugins/i18n'
import { apolloClient, createProvider } from './plugins/apollo'
import VueToast from 'vue-toast-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import './directives'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import * as rules from './plugins/rules'
import * as messages from './plugins/messages'
import showGqlError from './plugins/show-gql-error'
import TvosJpy from './components/DataItem/TvosJpy'
import ToThousands from './components/DataItem/ToThousands'
import IntInput from './components/Input/IntInput'
import { goToRoute } from './utils/routeHelper'
import { getColorAndBackground } from './plugins/styleHelper'
import FillScreen from './components/Helpers/FillScreen'
import gql from 'graphql-tag'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { isoDate } from './utils/dateUtil'

Vue.config.productionTip = false
Vue.use(VueSweetalert2)

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use({
  install (Vue) {
    Vue.prototype.$rules = rules
    Vue.prototype.$messages = messages
  }
})

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000
})

async function gqlFunc (query, variables, options) {
  let fetchPolicy;
  if (query.query) { // support for gql({query, variables})
    variables = query.variables
    fetchPolicy = query.fetchPolicy
    query = query.query
  } else {
    fetchPolicy = options?.fetchPolicy
  }
  try {
    const gqlQuery = typeof query === 'string' ? gql`${query}` : query
    if (gqlQuery.definitions[0].operation === 'query') {
      const result = await apolloClient.query({
        query: gqlQuery,
        variables,
        fetchPolicy
      })
      return result
    } else if (gqlQuery.definitions[0].operation === 'mutation') {
      const result = await apolloClient.mutate({
        mutation: gqlQuery,
        variables
      })
      return result
    } else {
      throw new Error('unsupported query')
    }
  } catch (error) {
    const originalQuery = typeof query === 'string' ? query : (query.loc?.source.body)
    console.log(error, error.graphQLErrors)
    store.commit('setCopyErrorText', getCopyErrorTextView(originalQuery, variables, error.graphQLErrors))
    const errorTmp = await handlErrorView(error.graphQLErrors, (...args) => store.commit('setPermissionUser', ...args), (...args) => store.commit('setRoleAdminUser', ...args))
    console.log(errorTmp)
    if (errorTmp) {
      store.commit('setAlertError', errorTmp)
    }
    throw error
  }
}

Vue.use({
  install (Vue) {
    Vue.prototype.$dow = function (date) {
      return new Date(date).getDay()
    }
    Vue.prototype.$month = function (date) {
      return new Date(date).getMonth()
    }
    Vue.prototype.$date = function (date) {
      return new Date(date).getDate()
    }
    Vue.prototype.$today = function () {
      return isoDate(new Date())
    }
    Vue.prototype.$goToRoute = goToRoute
    Vue.prototype.$getColorAndBackground = getColorAndBackground
    Vue.prototype.$gql = gqlFunc
    Vue.prototype.$loading = async function loadingWrapper (promise) {
      store.commit('setLoadingOverlayShow')
      try {
        const result = await typeof promise === 'function' ? promise() : promise
        return result
      } finally {
        store.commit('setLoadingOverlayHide')
      }
    }
    Vue.prototype.$gqlLoading = async function gqlLoading (query, variables) {
      store.commit('setLoadingOverlayShow')
      try {
        const result = await gqlFunc(query, variables)
        return result
      } finally {
        store.commit('setLoadingOverlayHide')
      }
    }
    Vue.prototype.$env = {
      isProd: process.env.NODE_ENV === 'production',
      isStg: process.env.NODE_ENV === 'staging',
      isDev: process.env.NODE_ENV === 'development'
    }
    Vue.prototype.$confirm = payload => store.dispatch('confirmDialog', { ...payload, type: 'confirm' })
    Vue.prototype.$alert = payload => store.dispatch('confirmDialog', { ...payload, type: 'alert' })
    Vue.prototype.$withDelay = async function (callback, ms) {
      store.commit('setLoadingOverlayShow')
      try {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve(callback())
          }, ms ?? 200)
        })
      } finally {
        store.commit('setLoadingOverlayHide')
      }
    }
  }
})
Vue.use(showGqlError)
Vue.component('tvos-int-input', IntInput)
Vue.component('tvos-thousands', ToThousands)
Vue.component('tvos-jpy', TvosJpy)
Vue.component('fill-screen', FillScreen)

const createVueApp = async () => {
  const tokenLogin = localStorage.getItem('token') || ''
  if (tokenLogin) {
    const decodeTokenUser = jwt_decode(tokenLogin)
    store.commit('setRoleAdminUser', decodeTokenUser.admin)
    if (Object.keys(decodeTokenUser).length !== 0) {
      store.commit('setPermissionUser', decodeTokenUser.rolePermissions)
    } else {
      store.commit('setPermissionUser', {})
    }
  }
  new Vue({
    router,
    store,
    apolloProvider: createProvider(),
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

createVueApp()
