import { ACCOMMODATION_PRICE_IMPORT_LIST } from '@/api/graphql/feesPointsPlans/accommodation-price-import-list'
import { apolloClient } from '@/plugins/apollo'
import gql from 'graphql-tag'

export default {
  async fetchAccommodationPriceImportList ({ commit, state }) {
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.query({
      query: gql`${ACCOMMODATION_PRICE_IMPORT_LIST}`,
      variables: {
        take: state.take,
        skip: state.skip
      }
    }).then(data => {
      commit('setAccommodationPriceImportListTotal', data.data.accommodationPriceImportList.total)
      commit('setAccommodationPriceImportList', data.data.accommodationPriceImportList.items)
      commit('setLoadingOverlayHide', { root: true })
    }).catch(error => {
      commit('setLoadingOverlayHide', { root: true })
      console.error(error)
    })
  }
}
