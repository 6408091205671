import actions from './actions'

const facilityItemParking = {
  state: {
    facilityItemParking: {
      facilityId: null,
      id: null,
      name: '',
      maxHeight: 0,
      quantity: 0,
      allowCancelWait: false
    }
  },
  mutations: {
    setFacilityItemParking (state, payload) {
      state.facilityItemParking = payload.facilityItemParking
    }
  },
  actions
}

export default facilityItemParking
