import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import facilityHotelTax from './modules/facilityHotelTax'
import facilityHotelTaxAmount from './modules/facilityHotelTaxAmount'
import saleItem from './modules/saleItem'
import facilitySaleItem from './modules/facilitySaleItem'
import facilityRoomType from './modules/facilityRoomType'
import rentalItemDef from './modules/rentalItemDef'
import rentalItemFac from './modules/rentalItemFacility'
import alert from './modules/alert'
import facilityItemParking from './modules/facilityItemParking'
import facilityBookingRemark from './modules/facilityBookingRemark'
import facilityRoom from './modules/facilityRoom'
import facilitySettings from './modules/facilitySettings'
import staffInfo from './modules/staffInfo'
import staffRole from './modules/staffRole'
import confirmEmail from './modules/confirmEmail'
import frameSettingInventory from './modules/frameSettingInventory'
import accommodationPrice from './modules/accommodationPrice'
import inventory from './modules/inventory'
import holidays from './modules/holidays'
import invitationTicket from './modules/invitationTicket'
import bookingPlans from './modules/bookingPlans'
import bookingList from './modules/bookingList'
import bookingGuest from './modules/bookingGuest'
import bookingCancel from './modules/bookingCancel'
import bookingRental from './modules/bookingRental'
import bookingParking from './modules/bookingParking'
import calendarParking from './modules/calendarParking'
import billingStatement from './modules/billingStatement'
import calendarLending from './modules/calendarLending'
import dailyReport from './modules/dailyReport'
import bookingBasicInfomation from './modules/bookingBasicInfomation'
import reversion from './modules/reversion'
import bookingBlock from './modules/block'
import reservationBlock from './modules/reservationBlock'
import saleManagement from './modules/saleManagement'
import csvUpdate from './modules/csvUpdate'
import app from './modules/app'
import confirmDialog from './modules/confirmDialog'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    facilityHotelTax,
    facilityHotelTaxAmount,
    saleItem,
    facilitySaleItem,
    facilityRoomType,
    rentalItemDef,
    rentalItemFac,
    alert,
    facilityItemParking,
    facilityBookingRemark,
    facilityRoom,
    facilitySettings,
    staffInfo,
    staffRole,
    confirmEmail,
    frameSettingInventory,
    accommodationPrice,
    inventory,
    holidays,
    invitationTicket,
    bookingPlans,
    bookingList,
    bookingGuest,
    bookingCancel,
    bookingRental,
    bookingParking,
    calendarParking,
    calendarLending,
    billingStatement,
    dailyReport,
    bookingBasicInfomation,
    reversion,
    bookingBlock,
    saleManagement,
    reservationBlock,
    csvUpdate,
    confirmDialog
  },
  getters: getters
})

export default store
