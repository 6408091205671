import { CREATE_FACILITY, FACILITY_LIST } from '@/api/graphql/facility-settings'
import { UPDATE_FACILITY, DELETE_FACILITY, FACILITY } from '@/api/graphql/facility-setting-detail'
import { apolloClient } from '@/plugins/apollo'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createFacility ({ state, commit, rootState }) {
    const variables = {
      data: {
        name: state.facilitySettings.name,
        type: state.facilitySettings.type
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_FACILITY}`,
        variables: variables,
        update: (store, { data: { createFacility } }) => {
          const data = store.readQuery({ query: gql`${FACILITY_LIST}` })
          data.facilityList.push(createFacility)
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        }
      }).then(data => {
        window.open('/facility/settings/detail?id=' + data.data.createFacility.id)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_FACILITY, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateFacility ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id),
      data: {
        name: state.facilitySettings.name,
        order: parseInt(state.facilitySettings.order),
        postalCode: state.facilitySettings.postalCode || null,
        address1: state.facilitySettings.address1,
        tel: state.facilitySettings.tel,
        fax: state.facilitySettings.fax,
        bedShareRule: state.facilitySettings.bedShareRule,
        floorPlanPdf: state.facilitySettings.floorPlanPdf,
        rakutsuHotelCode: state.facilitySettings.rakutsuHotelCode
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_FACILITY}`,
        variables: variables,
        update: (store, { data: { updateFacility } }) => {
          const data = store.readQuery({
            query: gql`${FACILITY}`,
            variables: {
              id: parseInt(state.facilitySettings.id)
            }
          })
          data.facility = updateFacility
        }
      }).then(async data => {
        await commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        commit('setStatusCloseTab', true)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_FACILITY, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteFacility ({ state, commit, rootState }) {
    const variables = {
      id: parseInt(router.currentRoute.query.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_FACILITY}`,
        variables: variables
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
        setTimeout(() => {
          window.close()
        }, 500)
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_FACILITY, variables, error.graphQLErrors)
        reject(error)
        handlError(commit, error.graphQLErrors)
      })
    })
  },

  async fetchFacilityList ({ commit }) {
    await apolloClient.query({
      query: gql`${FACILITY_LIST}`
    }).then((data) => {
      commit('setFacilityList', data.data.facilityList)
    }).catch((error) => {
      setCopyErrorTextCommit(commit, FACILITY_LIST, null, error.graphQLErrors)
      handlError(commit, error.graphQLErrors)
    })
  },

  updateSelectedFacility ({ commit }, facilityName) {
    commit('setSelectedFacility', facilityName)
  }
}
