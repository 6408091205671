import actions from './actions'

const bookingParking = {
  state: {
    statusUpdate: false,
    reservations: {
      carNumber: '',
      facilityParkingId: null,
      facilityParkingReservationId: null,
      fromDate: new Date().toISOString().substr(0, 10),
      height: null,
      toDate: new Date().toISOString().substr(0, 10),
      color: ''
    },

    waitlists: {
      facilityParkingWaitlistId: null,
      data: {
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),
        facilityParkingId: null
      }
    },

    bookingParking: {
      parkingId: null,
      fromDate: '',
      toDate: '',
      carNumber: '',
      color: '',
      height: null
    },
    status: null
  },

  mutations: {
    setReloadDataParkingPlace (state, payload) {
      state.statusUpdate = !state.statusUpdate
    },
    setParkingReservationBulk (state, payload) {
      state.reservations = payload.parkingReservationBulk.reservations
      state.waitlists = payload.parkingReservationBulk.waitlists
    },

    setBookingParking (state, payload) {
      state.bookingParking = payload.bookingParking
    },
    setChangeStatus (state, payload) {
      state.status = !state.status
    }
  },

  getters: {
    getReloadDataParkingPlace (state) {
      return state.statusUpdate
    },
    getStatusParking (state) {
      return state.status
    }
  },

  actions
}

export default bookingParking
