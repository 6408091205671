export const FACILITY_LIST = `
  query {
    facilityList(loadRoomType: true) {
      id
      name
      shortName
      type
      roomTypes {
        id
        name
        backgroundColor
      }
    }
  }
`

export const CREATE_FACILITY = `
  mutation ($data: CreateFacilityInput!) {
    createFacility(data: $data) {
      id
      name
    }
  }
`

export const FACILITY_LIST_FOR_SELECT = `
  query {
    facilityList {
      id
      name
    }
  }
`
