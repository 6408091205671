import moment from 'moment'

export const getRangeDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const nextOrPrevDay = (dayIn, nextOrPrev) => {
  if (dayIn !== '' && dayIn !== null) {
    const currentDay = new Date(dayIn)
    const dayResult = currentDay.setDate(
      new Date(currentDay).getDate() + nextOrPrev
    )
    const dayResultString = new Date(dayResult).toISOString().substr(0, 10)
    return dayResultString
  } else return ''
}

export const nextOrPrevDayAuto = (dayIn, direct = 'sub', period = 3) => {
  let dayResultString = ''
  if (direct === 'add') {
    switch (period) {
      case 1:
        dayResultString = new Date(
          new Date(dayIn).setFullYear(new Date(dayIn).getFullYear() + 1)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 2:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() + 3)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 3:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() + 1)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 4:
        dayResultString = new Date(
          new Date(dayIn).setDate(new Date(dayIn).getDate() + 14)
        )
          .toISOString()
          .substr(0, 10)
        break
      default:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() + 1)
        )
          .toISOString()
          .substr(0, 10)
        break
    }
  } else {
    switch (period) {
      case 1:
        dayResultString = new Date(
          new Date(dayIn).setFullYear(new Date(dayIn).getFullYear() - 1)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 2:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() - 3)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 3:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() - 1)
        )
          .toISOString()
          .substr(0, 10)
        break
      case 4:
        dayResultString = new Date(
          new Date(dayIn).setDate(new Date(dayIn).getDate() - 14)
        )
          .toISOString()
          .substr(0, 10)
        break
      default:
        dayResultString = new Date(
          new Date(dayIn).setMonth(new Date(dayIn).getMonth() + 1)
        )
          .toISOString()
          .substr(0, 10)
        break
    }
  }
  return dayResultString
}

export const calculateDays = (bigValue, smallValue) => {
  const dateTo = new Date(bigValue)
  const dateFrom = new Date(smallValue)
  const days = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24)
  return Math.ceil(days)
}

export const getDateOfYearString = (yearVal, monthVal, dayVal) => {
  const dateVal = new Date(yearVal, monthVal, dayVal)
  const dateString = new Date(
    dateVal.getTime() - dateVal.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10)
  return dateString
}

export const getDaysArray = (startDate, endDate) => {
  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  while (currentDate <= endDate) {
    dates.push(moment(currentDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    currentDate = addDays.call(currentDate, 1)
  }
  return dates
}

export const addMonths = (date, numOfMonths) => {
  var result = new Date(date)
  result.setMonth(result.getMonth() + numOfMonths)
  return result.toISOString().substr(0, 10)
}

export const getDate = (value) => {
  var d = moment(value).format('dddd')
  switch (d) {
    case 'Sunday':
      d = '日'
      break
    case 'Monday':
      d = '月'
      break
    case 'Tuesday':
      d = '火'
      break
    case 'Wednesday':
      d = '水'
      break
    case 'Thursday':
      d = '木'
      break
    case 'Friday':
      d = '金'
      break
    case 'Saturday':
      d = '土'
      break
  }
  return moment(value).format('DD (' + d + ')')
}

/**
 *
 */
export const getDiff2Days = (day1, day2, type = 'days') => {
  const dateFrom = moment(day1)
  const dateTo = moment(day2)
  return dateTo.diff(dateFrom, type)
}

/**
 *
 * @param {*} date
 * @returns
 */
export const getDayInMonth = (date) => {
  return moment(date).date()
}

/**
 *
 * @param {*} month
 * @returns
 */
export const getTotalDaysInMonth = (month) => {
  return moment(month).daysInMonth()
}

/**
 *
 * @param startDate
 * @param endDate
 * @returns
 */
export const getAllDateInBetween2Dates = (startDate, endDate) => {
  const dates = []

  const startOfMonth = moment(startDate)
  const lastDate = moment(endDate)

  dates.push(startOfMonth.clone().format('YYYY-MM-DD'))

  if (
    startOfMonth.clone().format('YYYY-MM-DD') !==
    lastDate.clone().format('YYYY-MM-DD')
  ) {
    while (startOfMonth.clone().add(1, 'days').diff(lastDate) < 0) {
      const date = startOfMonth.add(1, 'days')
      dates.push(date.format('YYYY-MM-DD'))
    }

    dates.push(lastDate.format('YYYY-MM-DD'))
  }

  return dates
}

/**
 *
 * @param {*} date
 * @returns
 */
export const getLastDateOfMonth = (date) => {
  return moment(date).endOf('month').format('YYYY-MM-DD')
}
