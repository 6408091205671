import gql from 'graphql-tag'

export const ROOM_LIST = `query ($roomTypeId: Int!){
  roomList (roomTypeId: $roomTypeId) {
    id name order roomTypeId
  }
}`

export const ROOM_TYPE = `query ($roomTypeId: Int!){
  roomType (id: $roomTypeId) {
    id name facility { id name }
  }
}`

export const ROOM = gql`
query($id: Int!) {
  room (id:$id){
    id
    name
    order
    roomTypeId
    roomType { name facility { name }}
  }
}
`

export const CREATE_ROOM = `mutation ($data: CreateRoomInput!){
  createRoom(data: $data) {
    id name order
  }
}`

export const UPDATE_ROOM = `mutation ($id: Int!, $data: UpdateRoomInput!){
  updateRoom(id: $id, data: $data) {
    name order id
  }
}`

export const DELETE_ROOM = `mutation ($id: Int!){
  deleteRoom(id: $id) {
    name
  }
}`
