import { apolloClient } from '@/plugins/apollo'
import {
  ADD_RENTAL_ITEM_RESERVATION, UPDATE_RESERVATION_RENTAL_ITEM_INPUT
} from '@/api/graphql/bookingDetail/booking-rental-list'
import router from '../../../router'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async addRentalItemReservation ({ commit, state, rootState }) {
    const variables = {
      bookingId: parseInt(router.currentRoute.query.id),
      rentalItems: {
        rentalItemId: parseInt(state.bookingRental.rentalItemId),
        fromDate: state.bookingRental.fromDate,
        toDate: state.bookingRental.toDate,
        quantity: parseInt(state.bookingRental.quantity)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${ADD_RENTAL_ITEM_RESERVATION}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setCreateBookingRental', true)
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, ADD_RENTAL_ITEM_RESERVATION, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateRentalItemReservation ({ commit, state, rootState }) {
    const variables = {
      data: {
        rentalItemReservationId: parseInt(state.updateBookingRental.rentalItemReservationId),
        newFromDate: state.updateBookingRental.newFromDate,
        newToDate: state.updateBookingRental.newToDate,
        newQuantity: parseInt(state.updateBookingRental.newQuantity)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_RESERVATION_RENTAL_ITEM_INPUT}`,
        variables: variables
      }).then(data => {
        commit('setStatusUpdate', true)
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_RESERVATION_RENTAL_ITEM_INPUT, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteRentalItemReservation ({ commit, state, rootState }) {
    const variables = {
      data: {
        rentalItemReservationId: parseInt(state.deleteBookingRental.rentalItemReservationId),
        newQuantity: 0
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_RESERVATION_RENTAL_ITEM_INPUT}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setCreateBookingRental', 1)
        commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_RESERVATION_RENTAL_ITEM_INPUT, variables, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
