import actions from './actions'

const facilityRoomType = {
  state: {
    facilityRoomType: {
      id: null,
      name: '',
      order: null,
      backgroundColor: '',
      maxOccupancy: null,
      bedShareMaxOccupancy: null,
      rakutsuId: '',
      selectedRoomType: null
    }
  },
  mutations: {
    setFacilityRoomType (state, payload) {
      state.facilityRoomType = payload.facilityRoomType
    },
    setSelectedRoomType (state, roomType) {
      state.selectedRoomType = roomType
    }
  },
  actions
}

export default facilityRoomType
