import { apolloClient } from '@/plugins/apollo'
import {
  ROOM_LIST, BOOKING_BASIC_UPDATE
} from '@/api/graphql/block/reservationBlock'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
// import router from '../../../router'
import gql from 'graphql-tag'

export default {
  async roomListReservation ({ commit, state }, roomTypeId) {
    const variables = {
      roomTypeId: roomTypeId
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ROOM_LIST}`,
          variables: variables
        })
        .then(data => {
          resolve(data)
          commit('setRoomListReserBlock', data.data.roomList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ROOM_LIST, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async updateReservationBlock ({ commit, state, rootState }, reservationData) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${BOOKING_BASIC_UPDATE}`,
        variables: reservationData
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, BOOKING_BASIC_UPDATE, reservationData, error.graphQLErrors)
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  }
}
