<template>
  <div>
    <v-alert
      class="alert-default"
      transition="scale-transition"
      type="success"
      :value="alertSuccess !== null"
      >{{ alertSuccess }}</v-alert
    >
    <v-alert
      class="alert-default"
      transition="scale-transition"
      type="error"
      v-model="showAlertError"
      dismissible
      >{{ alertError }}<v-btn @click="copyErrorToClipboard" color="error" style="border: thin solid #FFFFFF !important">エラー詳細をコピー</v-btn></v-alert
    >
    <v-dialog :value="errorDialog !== null" @click:outside="setErrorDialog(null)">
      <v-alert
        class="alert-default --center text-h5 pa-10 error-dialog"
        type="error"
        icon="mdi-alert-circle-outline"
        width="320"
        prominent
      >
        {{ errorDialog }}
        <v-btn
          absolute
          top
          right
          fab
          small
          color="white"
          @click="setErrorDialog(null)"
        >
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-alert>
    </v-dialog>
    <v-overlay
      :value="loadingOverlay"
      :z-index="zIndex"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'GlobalNotifications',
  data: () => {
    return {
      zIndex: 99999999
    }
  },
  watch: {
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay', 'getCopyErrorText']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    },
    showAlertError: {
      get () {
        return !!this.alertError
      },
      set (v) {
        if (!v) { this.setAlertError(null) }
      }
    }
  },
  methods: {
    ...mapMutations(['setAlertSuccess', 'setAlertError', 'setErrorDialog', 'setCopyErrorText']),
    copyErrorToClipboard () {
      navigator.clipboard.writeText(this.getCopyErrorText)
      this.setCopyErrorText(null)
      this.setAlertError(null)
    }
  }
}
</script>

<style scoped lang="scss">
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
.alert-default {
  position: fixed;
  width: 300px;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  &.--center {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
::v-deep {
  .error-dialog {
    .v-alert__content {
      max-width: 100% !important;
      word-break: break-word !important;
    }
  }
}
</style>
