<template>
  <v-app>
    <environment-banner />
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import EnvironmentBanner from '@/components/Alert/EnvironmentBanner'

export default {
  name: 'App',
  components: { EnvironmentBanner },
  mounted () {
    try {
      this.setPermissionUser({})
      this.setRoleAdminUser(false)
      const tokenLogin = localStorage.getItem('token') || ''
      if (tokenLogin) {
        const decodeTokenUser = jwt_decode(tokenLogin)
        this.setRoleAdminUser(decodeTokenUser.admin)
        if (Object.keys(decodeTokenUser).length !== 0) {
          this.setPermissionUser(decodeTokenUser.rolePermissions)
        } else {
          this.setPermissionUser({})
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = to.meta.title || 'PMS'
      }
    }
  },
  methods: {
    ...mapMutations([
      'setPermissionUser',
      'setRoleAdminUser'
    ])
  }
}
</script>

<style lang="scss">
  // Import main style
  @import "assets/styles/index.scss";
</style>
