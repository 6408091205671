import { apolloClient } from '@/plugins/apollo'
import {
  BOOKING_PLAN_LIST,
  CREATE_BOOKING_PLAN,
  DELETE_BOOKING_PLAN,
  UPDATE_BOOKING_PLAN
} from '@/api/graphql/feesPointsPlans/booking-plans'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async fetchBookingPlanList ({ commit, state }, { take, skip }) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      filter: {
        facilityId: null
      },
      pagination: {
        take,
        skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_PLAN_LIST}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          const listReverse = data.data.bookingPlanList.items.sort((a, b) => a.id - b.id)
          commit('setBookingPlanList', listReverse)
          commit('setLoadingOverlayHide', { root: true })
          resolve(data.data.bookingPlanList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_PLAN_LIST, variables, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },

  async createBookingPlan ({ state, commit }, id) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      id
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_PLAN}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setLoadingOverlayHide', { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_PLAN, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async deleteBookingPlan ({ commit, rootState }, id) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      id
    }
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${DELETE_BOOKING_PLAN}`,
        variables: variables
      }).then(data => {
        resolve(data)
        commit('setLoadingOverlayHide', { root: true })
        commit('setAlertSuccess', rootState.alert.success.messages.deleteSuccess, { root: true })
      }).catch(error => {
        setCopyErrorTextCommit(commit, DELETE_BOOKING_PLAN, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async updateBookingPlan ({ commit, rootState }, obj) {
    commit('setLoadingOverlayHide', { root: true })
    const variables = {
      id: obj.item.id,
      csvFileS3Key: obj.csvFileS3Key,
      enabled: obj.item.enabled,
      title: obj.item.title,
      subtitle: obj.item.subtitle,
      targetBookingType: obj.item.targetBookingType,
      roomTypeId: parseInt(obj.item.roomType.id),
      imagePublicUrl1: obj.item.imagePublicUrl1,
      imagePublicUrl2: obj.item.imagePublicUrl2,
      remarks: obj.item.remarks,
      description: obj.item.description,
      questions: obj.questions,
      createdByName: obj.item.createdByName,
      chargeType: obj.item.additionalChargeType,
      chargeAmount: parseInt(obj.item.fixedAdditionalCharge),
      stopSaleDay: parseInt(obj.item.stopSaleDay),
      stopSaleTime: obj.item.stopSaleTime,
      availableFromDate: obj.item.availableFromDate,
      availableToDate: obj.item.availableToDate,
      bookThroughDays: parseInt(obj.item.bookThroughDays),
      webPublicFromDate: obj.item.webPublicFromDate,
      webPublicToDate: obj.item.webPublicToDate,
      bookingFromDate: obj.item.bookingFromDate,
      bookingToDate: obj.item.bookingToDate,
      guestCountLimit: parseInt(obj.item.guestCountLimit),
      roomCountLimit: parseInt(obj.item.roomCountLimit),
      discountType: obj.item.discountType,
      day1Discount: parseInt(obj.item.day1Discount),
      day2Discount: parseInt(obj.item.day2Discount),
      day3Discount: parseInt(obj.item.day3Discount),
      day4Discount: parseInt(obj.item.day4Discount),
      day5Discount: parseInt(obj.item.day5Discount),
      day6Discount: parseInt(obj.item.day6Discount),
      day7Discount: parseInt(obj.item.day7Discount),
      day8Discount: parseInt(obj.item.day8Discount),
      day9Discount: parseInt(obj.item.day9Discount),
      continueDiscountAfterDay9: obj.item.continueDiscountAfterDay9,
      minGuestCount: parseInt(obj.item.minGuestCount),
      maxGuestCount: parseInt(obj.item.maxGuestCount),
      minDays: parseInt(obj.item.minDays),
      maxDays: parseInt(obj.item.maxDays),
      overrideCancelPolicy: obj.item.overrideCancelPolicy,
      noShowPenalty: parseFloat(obj.item.noShowPenalty),
      day0Penalty: parseFloat(obj.item.day0Penalty),
      threshold1Day: parseInt(obj.item.threshold1Day),
      threshold1Penalty: parseFloat(obj.item.threshold1Penalty),
      threshold2Day: parseInt(obj.item.threshold2Day),
      threshold2Penalty: parseFloat(obj.item.threshold2Penalty),
      threshold3Day: parseInt(obj.item.threshold3Day),
      threshold3Penalty: parseFloat(obj.item.threshold3Penalty),
      threshold4Day: parseInt(obj.item.threshold4Day),
      threshold4Penalty: parseFloat(obj.item.threshold4Penalty),
      threshold5Day: parseInt(obj.item.threshold5Day),
      threshold5Penalty: parseFloat(obj.item.threshold5Penalty),
      threshold6Day: parseInt(obj.item.threshold6Day),
      threshold6Penalty: parseFloat(obj.item.threshold6Penalty),
      threshold7Day: parseInt(obj.item.threshold7Day),
      threshold7Penalty: parseFloat(obj.item.threshold7Penalty),
      availableWeekDays: obj.availableWeekDays
    }
    await apolloClient.mutate({
      mutation: gql`${UPDATE_BOOKING_PLAN}`,
      variables: variables
    })
      .then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
      })
      .catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_BOOKING_PLAN, variables, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        throw error
      })
  }
}
