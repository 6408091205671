import moment from 'moment'
import ERROR_MESSAGES from '@/constants/errorMapping'
import ERROR_CODES from '@/constants/errorCodeMapping'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

import { REFRESH_STAFF_TOKEN } from '@/api/login'
import { apolloClient } from '@/plugins/apollo'
import gql from 'graphql-tag'
import store from '@/store'

export const formatOnlyDate = (value) => {
  return moment(value).format('yyyy年MM月DD日')
}

export const formatDate = (value) => {
  if (value !== '' && value !== null) {
    let d = moment(value).format('dddd')
    switch (d) {
      case 'Sunday':
        d = '日'
        break
      case 'Monday':
        d = '月'
        break
      case 'Tuesday':
        d = '火'
        break
      case 'Wednesday':
        d = '水'
        break
      case 'Thursday':
        d = '木'
        break
      case 'Friday':
        d = '金'
        break
      case 'Saturday':
        d = '土'
        break
    }
    return moment(value).format('yyyy年MM月DD日 (' + d + ')')
  } else return ''
}
export const formatDateSlash = (value) => {
  if (value) {
    return moment(value).format('YYYY/M/D')
  }
  return ''
}

export const formatMonth = (value) => {
  return moment(value).format('yyyy年MM月')
}

export const formatCurrency = (value) => {
  if (value) return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
}

export const rangeNumber = (start, end, step) => {
  return Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), x => start + x * step)
}

export const setCopyErrorTextCommit = (commit, query, variables, response) => {
  commit('setCopyErrorText',
    '1. GraphQLのQuery: ' + query +
      '\n2. Variables: \n' + JSON.stringify(variables, null, '\t') +
      '\n3. Response: \n{"errors":' + JSON.stringify(response, null, '\t') + ',"data":null}' +
      '\n4. Request date time: \n' + new Date())
}

export const getCopyErrorTextView = (query, variables, response) => {
  return '1. GraphQLのQuery: ' + query +
            '\n2. Variables: \n' + JSON.stringify(variables, null, '\t') +
            '\n3. Response: \n{"errors":' + JSON.stringify(response, null, '\t') + ',"data":null}' +
            '\n4. Request date time: \n' + new Date()
}

export const execRefreshToken = async () => {
  const result = await apolloClient
    .mutate({
      mutation: REFRESH_STAFF_TOKEN,
      variables: {
        refreshToken: localStorage.getItem('refreshToken')
      }
    })
  return {
    accessToken: result.data.refreshStaffToken.accessToken,
    refreshToken: result.data.refreshStaffToken.refreshToken
  }
}

function translateErrorMessage (error) {
  const translated = ERROR_MESSAGES[error.message]
  if (translated) {
    if (error.data) {
      let withData = translated
      for (const key of Object.keys(error.data)) {
        withData = withData.replace(new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`), `${error.data[key]}`)
      }
      return withData
    } else {
      return translated
    }
  }
  const codeTranslated = ERROR_CODES[error.code]
  if (codeTranslated) return codeTranslated

  return error.message
}

export const handlError = async (commit, errors) => {
  if (errors.some(e => e.code === 'UNAUTHORIZED_ERROR')) {
    await execRefreshToken()
      .then(data => {
        // commit('SET_TOKEN', data.data.refreshStaffToken.accessToken)
        // commit('SET_RFTOKEN', data.data.refreshStaffToken.refreshToken)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        // begin set permission,role user current
        const tokenLogin = data.accessToken
        if (tokenLogin) {
          const decodeTokenUser = jwt_decode(tokenLogin)
          commit('setRoleAdminUser', decodeTokenUser.admin)
          if (Object.keys(decodeTokenUser).length !== 0) {
            commit('setPermissionUser', decodeTokenUser.rolePermissions)
          } else {
            commit('setPermissionUser', {})
          }
        }
        // end set permission,role user current
        location.reload()
      })
      .catch(error => {
        console.log(error)
        store.dispatch('logOut')
      })
    return ''
  } else {
    const translated = translateErrorMessage(errors[0])
    commit('setAlertError', translated, { root: true })
  }
}

export const handlErrorView = async (errors, setPermissionUser = null, setRoleAdminUser = null) => {
  if (errors.some(e => e.code === 'UNAUTHORIZED_ERROR')) {
    await apolloClient
      .mutate({
        mutation: gql`${REFRESH_STAFF_TOKEN}`,
        variables: {
          refreshToken: localStorage.getItem('refreshToken')
        }
      })
      .then(data => {
        window.localStorage.setItem('token', data.data.refreshStaffToken.accessToken)
        window.localStorage.setItem('refreshToken', data.data.refreshStaffToken.refreshToken)
        // begin set permission,role user current
        const tokenLogin = data.data.refreshStaffToken.accessToken
        if (tokenLogin) {
          const decodeTokenUser = jwt_decode(tokenLogin)
          if (setRoleAdminUser !== null) {
            setRoleAdminUser(decodeTokenUser.admin)
          }
          if (setPermissionUser !== null) {
            if (Object.keys(decodeTokenUser).length !== 0) {
              setPermissionUser(decodeTokenUser.rolePermissions)
            } else {
              setPermissionUser({})
            }
          }
        }
        // end set permission,role user current
        location.reload()
      })
      .catch(error => {
        console.log(error)
      })
    return ''
  } else {
    const translated = translateErrorMessage(errors[0])
    return translated
  }
}

export const loadingOverlay = (commit, status = false) => {
  if (status) {
    commit('setLoadingOverlayShow')
  } else {
    commit('setLoadingOverlayHide')
  }
}

export const removeValueInArray = (arr, value) => {
  return arr.filter(function (ele) {
    return ele !== value
  })
}

// UCS-2 string to Base64 encoded ASCII
export const utoa = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)))
}
// Base64 encoded ASCII to UCS-2 string
export const atou = (str) => {
  return decodeURIComponent(escape(window.atob(str)))
}
