import { i18n } from '../../plugins/i18n'

const formReportRouter = [
  {
    path: '/form-report'
    // redirect: '/form-report/income-report'
  },
  {
    path: '/form-report/income-report',
    name: i18n.t('routeTitles.formReport.forIncomeReport'),
    component: () => import('../../views/dashboard/formReport/forIncomeReport'),
    meta: {
      title: '帳票レポート'
    }
  },
  {
    path: '/form-report/sales-management',
    name: i18n.t('routeTitles.formReport.salesManagementTable'),
    component: () => import('../../views/dashboard/formReport/salesManagementTable'),
    meta: {
      title: '帳票レポート'
    }
  },
  {
    path: '/form-report/other-csv',
    name: i18n.t('routeTitles.formReport.otherCSV'),
    component: () => import('../../views/dashboard/formReport/otherCSV'),
    meta: {
      title: '帳票レポート'
    }
  },
  {
    path: '/form-report/lodging-tax-schedule',
    name: i18n.t('routeTitles.formReport.monthlyLodgingTaxSchedule'),
    component: () => import('../../views/dashboard/formReport/monthlyLodgingTaxSchedule'),
    meta: {
      title: '帳票レポート'
    }
  }
]

export default formReportRouter
