import { apolloClient } from '@/plugins/apollo'
import {
  UPDATE_SALE_ITEM
} from '@/api/graphql/facility/facility-sale-item'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async updateFacilitySaleItem ({ state, commit, rootState }, status) {
    const forSale = !(status === 'delete')
    const variables = {
      id: state.saleItem.id,
      data: {
        price: parseInt(state.saleItem.price),
        allowPriceOverwrite: state.saleItem.allowPriceOverwrite,
        forSale
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_SALE_ITEM}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setStatusUpdateFacilitySaleItem', true)
          commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_SALE_ITEM, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }
}
