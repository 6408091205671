export const KnownInventoryTypeId = {
  MemberPublic: 1,
  OTAPublic: 2,
  AllPublic: 3,
  MWExchange: 4,
  PhoneReserved: 5,
  VMSale: 6,
  External1: 5001,
  External2: 5002,
  External3: 5003,
  External4: 5004,
  External5: 5005,
  External6: 5006,
  External7: 5007,
  External8: 5008,
  External9: 5009,
  External10: 5010,
  External11: 5011,
  External12: 5012,
  External13: 5013,
  External14: 5014,
  External15: 5015,
  External16: 5016,
  External17: 5017,
  External18: 5018,
  External19: 5019,
  External20: 5020,
  External21: 5021,
  External22: 5022,
  External23: 5023,
  External24: 5024,
  External25: 5025,
  External26: 5026,
  External27: 5027,
  External28: 5028,
  External29: 5029,
  External30: 5030,
  External31: 5031,
  External32: 5032,
  External33: 5033,
  External34: 5034,
  External35: 5035,
  External36: 5036,
  External37: 5037,
  External38: 5038,
  External39: 5039,
  External40: 5040,
  External41: 5041,
  External42: 5042,
  External43: 5043,
  External44: 5044,
  External45: 5045,
  External46: 5046,
  External47: 5047,
  External48: 5048,
  External49: 5049,
  External50: 5050
}

export function isWarningInventoryType (inventoryTypeId) {
  return inventoryTypeId === KnownInventoryTypeId.OTAPublic ||
    inventoryTypeId >= 5000
}

export function isForbiddenInventoryType (inventoryTypeId) {
  return [
    KnownInventoryTypeId.MWExchange,
    KnownInventoryTypeId.VMSale
  ].includes(inventoryTypeId)
}

export function forbiddenInventoryTypeAlert () {
  return {
    title: '枠属性エラー',
    message: '指定の枠は通常予約では利用できません。'
  }
}
