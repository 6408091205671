import gql from 'graphql-tag'

export const STAFF_ROLE_LIST = gql`query {
  staffRoleList {
    name
    id
    readPermissions {
      id
      key
    }
    writePermissions {
      id
      key
    }
  }
}`

export const CREATE_STAFF_ROLE = `mutation ($data: CreateStaffRoleInput!){
  createStaffRole(data: $data)  {
    id name
  }
}`

export const UPDATE_STAFF_ROLE = `mutation ($id:Int!, $data: UpdateStaffRoleInput!){
  updateStaffRole (id: $id, data: $data)
}`
