import gql from 'graphql-tag'

export const REFRESH_STAFF_TOKEN = gql`
mutation refreshAccessToken($refreshToken: String!) {
  refreshStaffToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
`
