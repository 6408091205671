import actions from './actions'

const csvUpdate = {
  state: {
    list: [],
    take: 10,
    skip: 0,
    total: 0
  },
  mutations: {
    setAccommodationPriceImportListTake (state, payload) {
      state.take = payload
    },
    setAccommodationPriceImportListSkip (state, payload) {
      state.skip = payload
    },
    setAccommodationPriceImportList (state, payload) {
      state.list = payload
    },
    setAccommodationPriceImportListTotal (state, payload) {
      state.total = payload
    }
  },
  actions
}

export default csvUpdate
