import { i18n } from '../../plugins/i18n'

const calendarRouter = [
  {
    path: '/calendar'
    // redirect: '/calendar/room-assignment'
  },
  {
    path: '/calendar/room-assignment',
    name: i18n.t('routeTitles.calendar.roomAllocationCalendar'),
    component: () => import('../../views/dashboard/calendar/roomAssignment/roomAssignment'),
    meta: {
      title: 'カレンダー'
    }
  },
  {
    path: '/calendar/car-park',
    name: i18n.t('routeTitles.calendar.carParkCalendar'),
    component: () => import('../../views/dashboard/calendar/carPark/carPark'),
    meta: {
      title: 'カレンダー'
    }
  },
  {
    path: '/calendar/exhibits-for-hire',
    name: i18n.t('routeTitles.calendar.rentalCalendar'),
    component: () => import('../../views/dashboard/calendar/exhibitsForHire/exhibitsForHire'),
    meta: {
      title: 'カレンダー'
    }
  }
]

export default calendarRouter
