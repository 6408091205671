const saleManagement = {
  state: {
    occupancyRates: [],
    paymentAmounts: [],
    chargeSummaries: []
  },

  mutations: {
    setOccupancyRates (state, data) {
      state.occupancyRates = data
    },
    setPaymentAmounts (state, data) {
      state.paymentAmounts = data
    },
    setChargeSummaries (state, data) {
      state.chargeSummaries = data
    }
  },

  getters: {
    getOccupancyRates (state) {
      return state.occupancyRates
    },
    getPaymentAmounts (state) {
      return state.paymentAmounts
    },
    getChargeSummaries (state) {
      return state.chargeSummaries
    }
  }
}

export default saleManagement
