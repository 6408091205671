import { apolloClient } from '@/plugins/apollo'
import { ACCOMMODATION_PRICE_LIST } from '@/api/graphql/accommodation-price'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async fetchAccommodationPriceList ({ state, commit }, payload) {
    commit('setLoadingOverlayShow', { root: true })
    const variables = {
      facilityId: parseInt(payload.facilityId),
      accommodationFromDate: payload.fromDate,
      accommodationToDate: payload.toDate,
      nonProductFromDate: payload.fromDate,
      nonProductToDate: payload.toDate,
      mwWeekCalendarFromDate: payload.fromDate,
      mwWeekCalendarToDate: payload.toDate,
      bookingPlanFilter: {
        availableFromDate: payload.fromDate,
        availableToDate: payload.toDate,
        facilityId: parseInt(payload.facilityId)
      },
      includeNonProduct: true,
      includeMwCalendar: true
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ACCOMMODATION_PRICE_LIST}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          resolve(data)
          commit('setLoadingOverlayHide', { root: true })
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ACCOMMODATION_PRICE_LIST, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }
}
